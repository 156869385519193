import wasmencrypt from '../../public/encryption.js';
import wasmencryptModule from '../../public/encryption.wasm';

import pbkdf2Hmac from 'pbkdf2-hmac'
import {keysStorage} from './indexeddb';
import forge from 'node-forge';

var toBuffer = require('typedarray-to-buffer');

// if(navigator.userAgent.indexOf('MSIE')!==-1
//     || navigator.appVersion.indexOf('Trident/') > -1){
//   var RSAKey = null;
// }else {
//   var RSAKey = require('rsa-key');
// }

let crypto = undefined
if(typeof window !== 'undefined')
  crypto = window.crypto || window.msCrypto;
else
  crypto = self.crypto || self.msCrypto;

var Module = null;

if(!crypto || typeof window !== 'undefined'){//We need it when there no crypto libaries (Microsoft EDGE) or during login
  try{
    // Module = wasmencrypt({
    //   locateFile(path) {
    //     if(path.endsWith('.wasm')) {
    //       var url = window.location.protocol + '//' + window.location.host + '/lib/encryption.wasm';
    //       return url;
    //       if(!crypto && typeof window !== 'undefined'){
    //         var url = window.location.protocol + '//' + window.location.host + '/lib/encryption.wasm';
    //         return url;
    //         // eslint-disable-next-line no-restricted-globals
    //       }else if(!crypto && typeof self !== 'undefined'){
    //         // eslint-disable-next-line no-restricted-globals
    //         var url = self.location.protocol + '//' + self.location.host + '/lib/encryption.wasm';
    //         return url;
    //       }
    //       return wasmencryptModule;
    //     }
    //     return path;
    //   }
    // });
    // Module.onRuntimeInitialized = () => {
    // };
  }catch(e){

  }
}

export var defaultRSAAlgorithm256Method = {
  name: "RSA-OAEP",
  modulusLength: 2048,
  publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
  hash: {
    name: "SHA-256"
  }
};
export var defaultRSASign256Method = {
  name: "RSASSA-PKCS1-v1_5",
  hash: {
    name: "SHA-256"
  }
};

export var defaultRSAAlgorithmMethod = {
  name: "RSA-OAEP",
  modulusLength: 2048,
  publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
  hash: {
    name: "SHA-512"
  }
};
export var defaultRSASignMethod = {
  name: "RSASSA-PKCS1-v1_5",
  hash: {
    name: "SHA-512"
  }
};

export function IsCrypto(){
  if(crypto) return true;
  return false;
}

export async function pbkdf2SHA512Hash(buff, psalt) {
  const derivedKey = await pbkdf2Hmac(buff, psalt, 10000, 32, "SHA-512")
  return new Uint8Array(derivedKey);
  /*
  var salt = toBuffer(psalt)
  let emBuffer;
  let emSalt;
  var returnBytes = new Uint8Array(32);

  try{
//console.log("Module",Module)
    emBuffer = Module._malloc(32);
    // Get data byte size, allocate memory on Emscripten heap, and get pointer
    var nDataBytes = salt.length * salt.BYTES_PER_ELEMENT;
    emSalt = Module._malloc(nDataBytes);
    // Copy data to Emscripten heap (directly accessed from Module.HEAPU8)
    var dataHeap = new Uint8Array(Module.HEAPU8.buffer, emSalt, nDataBytes);
    dataHeap.set(new Uint8Array(salt.buffer));

    Module.ccall('pbkdf2SHA512Hash',
        null, // return type
        ['string','number','number', 'number'],
        [buff, dataHeap.byteOffset, salt.byteLength, emBuffer]);
    for(var i=0;i<32;i++) {
      var value = Module.getValue(emBuffer+i,'i8');
      returnBytes[i] = value;
    }
  }catch(e){
    if(e.includes("Assertion failed: you need to wait for the runtime to be ready")){
      try{
        Module = wasmencrypt({
          locateFile(path) {
            if(path.endsWith('.wasm')) {
              if(!crypto && typeof window !== 'undefined'){
                var url = window.location.protocol + '//' + window.location.host + '/lib/encryption.wasm';
                return url;
                // eslint-disable-next-line no-restricted-globals
              }else if(!crypto && typeof self !== 'undefined'){
                // eslint-disable-next-line no-restricted-globals
                var url = self.location.protocol + '//' + self.location.host + '/lib/encryption.wasm';
                return url;
              }
              return wasmencryptModule;
            }
            return path;
          }
        });
        Module.onRuntimeInitialized = () => {
        };
      }catch(e){

      }
    }
  } finally {
    Module._free(emBuffer.byteOffset);
    Module._free(emSalt.byteOffset);
  }

  return returnBytes;
  */
}

export function arrayBufferToBase64String(arrayBuffer) {
  var byteArray = new Uint8Array(arrayBuffer)
  var byteString = '';
  for (var i=0; i<byteArray.byteLength; i++) {
    byteString += String.fromCharCode(byteArray[i]);
  }
  return btoa(byteString);
}

export function base64StringToArrayBuffer(b64str) {
  var byteStr = atob(b64str);
  var bytes = new Uint8Array(byteStr.length);
  for (var i = 0; i < byteStr.length; i++) {
    bytes[i] = byteStr.charCodeAt(i);
  }
  return bytes.buffer;
}

export function textToArrayBuffer(str) {
  var buf = unescape(encodeURIComponent(str)); // 2 bytes for each char
  var bufView = new Uint8Array(buf.length);
  for (var i=0; i < buf.length; i++) {
    bufView[i] = buf.charCodeAt(i);
  }
  return bufView;
}

export function arrayBufferToText(buffer) {
  var byteArray = new Uint8Array(buffer);
  var byteString = '';
  for (var i = 0; i < byteArray.byteLength; i++) {
    byteString += String.fromCodePoint(byteArray[i]);
  }
  return byteString;
  /*if(global.TextDecoder === undefined){
    var encoding = require('text-encoding');
    global.TextDecoder = global.TextDecoder || encoding.TextDecoder;
  }

  return new TextDecoder().decode(buff);*/
}

export function arrayBufferToHex(buffer) {
  return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
}

export function convertPemToBinary(pem) {
  if(pem.indexOf('----') !== -1){
    if(pem.indexOf('-BEGIN PRIVATE KEY-') > 0 || pem.indexOf('-BEGIN PUBLIC KEY-') > 0){
    }else{
      //var key = new RSAKey(pem);
      //pem = key.exportKey();
      var pki = forge.pki
      var privateKey = pki.privateKeyFromPem(pem);
      // convert a Forge private key to an ASN.1 RSAPrivateKey
      var rsaPrivateKey = pki.privateKeyToAsn1(privateKey);

      // wrap an RSAPrivateKey ASN.1 object in a PKCS#8 ASN.1 PrivateKeyInfo
      var privateKeyInfo = pki.wrapRsaPrivateKey(rsaPrivateKey);

      // convert a PKCS#8 ASN.1 PrivateKeyInfo to PEM
      pem = pki.privateKeyInfoToPem(privateKeyInfo);
    }
  }

  var lines = pem.split('\n');
  var encoded = '';

  for(var i = 0;i < lines.length;i++){
    if (lines[i].trim().length > 0 &&
        lines[i].indexOf('-BEGIN RSA PRIVATE KEY-') < 0 &&
        lines[i].indexOf('-BEGIN RSA PUBLIC KEY-') < 0 &&
        lines[i].indexOf('-BEGIN PUBLIC KEY-') < 0 &&
        lines[i].indexOf('-BEGIN PRIVATE KEY-') < 0 &&
        lines[i].indexOf('-END PUBLIC KEY-') < 0 &&
        lines[i].indexOf('-END RSA PRIVATE KEY-') < 0 &&
        lines[i].indexOf('-END PRIVATE KEY-') < 0 &&
        lines[i].indexOf('-END RSA PUBLIC KEY-') < 0) {
      encoded += lines[i].trim();
    }
  }
  return base64StringToArrayBuffer(encoded);
}

export function convertBinaryToPem(binaryData, label) {
  var base64Cert = arrayBufferToBase64String(binaryData)
  var pemCert = "-----BEGIN " + label + "-----\r\n"
  var nextIndex = 0
  var lineLength
  while (nextIndex < base64Cert.length) {
    if (nextIndex + 64 <= base64Cert.length) {
      pemCert += base64Cert.substr(nextIndex, 64) + "\r\n"
    } else {
      pemCert += base64Cert.substr(nextIndex) + "\r\n"
    }
    nextIndex += 64
  }
  pemCert += "-----END " + label + "-----\r\n"
  return pemCert;
}

function spkiToPEM(keydata){
    var keydataS = arrayBufferToString(keydata);
    var keydataB64 = null
    try {
      keydataB64 = window.btoa(keydataS);
    }catch(e){
      /* eslint-disable-next-line no-restricted-globals */
      keydataB64 = self.btoa(keydataS);
    }
    var keydataB64Pem = formatAsPem(keydataB64);
    return keydataB64Pem;
}

function arrayBufferToString( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return binary;
}


function formatAsPem(str) {
    var finalString = '-----BEGIN PUBLIC KEY-----\n';

    while(str.length > 0) {
        finalString += str.substring(0, 64) + '\n';
        str = str.substring(64);
    }

    finalString = finalString + "-----END PUBLIC KEY-----";

    return finalString;
}

//-----------------------------------------------------------
export function GenerateRandom(length) {
  var IV = new Uint8Array(length);
  if (crypto === undefined || crypto === null){
    function random (low, high) {
      return Math.floor(Math.random() * (high - low) + low);
    }
    for (var i = 0; i < length; i++) {
      IV[i] = random(0,256);
    }
  }else{
    return crypto.getRandomValues(IV);
  }

  return IV;
}

//-----------------------------------------------------------
export function importPublicKey(pemKey, encryptAlgorithm, format = "spki") {
  return new Promise(function(resolve, reject) {
    var importer = crypto.subtle.importKey(format, convertPemToBinary(pemKey), encryptAlgorithm, true, ["encrypt"]);
    importer.then(function(key) {
      resolve(key);
    })
    .catch(function(err){
        reject(err);
    });
  });
}

export function importPublicKeySign(pemKey, encryptAlgorithm) {
  return new Promise(function(resolve, reject) {
    var importer = crypto.subtle.importKey("spki", convertPemToBinary(pemKey), encryptAlgorithm, true, ["verify"]);
    importer.then(function(key) {
      resolve(key);
    })
    .catch(function(err){
        reject(err);
    });
  });
}


export function importPrivateKey(pemKey, encryptAlgorithm, extract = true) {
  return new Promise(function(resolve, reject) {
    var importer = crypto.subtle.importKey("pkcs8", convertPemToBinary(pemKey), encryptAlgorithm, extract, ["decrypt"]);
    importer.then(function(key) {
      resolve(key);
    })
    .catch(function(err){
      reject(err);
    });
  });
}

export function importPrivateKeySign(pemKey, encryptAlgorithm, extract = true) {
  return new Promise(function(resolve, reject) {
    var importer = crypto.subtle.importKey("pkcs8", convertPemToBinary(pemKey), encryptAlgorithm, extract, ["sign"]);
    importer.then(function(key) {
      resolve(key);
    })
    .catch(function(err){
      reject(err);
    });
  });
}

export function exportPublicKeyToPEM(keys) {
  return new Promise(function(resolve, reject) {
    crypto.subtle.exportKey('spki', keys)
    .then(function(spki) {
      resolve(spkiToPEM(spki))
    })
    .catch(function(err){
      console.log('Qerr',err);
      reject(err);
    })
  })
}

export function exportPrivateKeyToPEM(keys) {
  return new Promise(function(resolve, reject) {
    crypto.subtle.exportKey('pkcs8', keys).then(function(pkcs8) {
      resolve(convertBinaryToPem(pkcs8, "PRIVATE KEY"))
    })
    .catch(function(err){
      console.log('Eerr',err);
      reject(err);
    })
  })
}

/*export function exportPemKeysToPEM(keys) {
  return new Promise(function(resolve) {
    exportPublicKey(keys).then(function(pubKey) {
      exportPrivateKey(keys).then(function(privKey) {
        resolve({publicKey: pubKey, privateKey: privKey})
      })
    })
  })
}*/

//-----------------------------------------------------------
export function GenerateRSAKey(encryptAlgorithm){
  return new Promise(function(resolve, reject) {
    crypto.subtle.generateKey(
      encryptAlgorithm,
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ["encrypt", "decrypt"] //must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
    )
    .then(function(key){
        //returns a keypair object
        resolve(key);
    })
    .catch(function(err){
        reject(err);
    });
  });
}

export function RSAEncryptWASM(publicKey, data){
  //return new Promise(function(resolve, reject) {
    const BUFSIZE = 4096;
    var returnBtyes = null;

    //let emBuffer;
    let chBuffer;
    try {
      var length = 0;
      var EncryptedBytes = new Uint8Array(data.length + BUFSIZE);

      //emBuffer = Module._malloc(publicKey.length);
      chBuffer = Module._malloc(data.byteLength);

    //  console.log("l",publicKey.length,publicKey[0],publicKey[1]);
    //  for(var i=0;i<publicKey.length;i++) {
    //    Module.setValue(emBuffer+i,publicKey[i],'i8');
    //  }

      for(var i=0;i<data.byteLength;i++) {
        Module.setValue(chBuffer+i,data[i],'i8');
      }
console.log('data.byteLength',data.byteLength);
      Module.ccall('RSA_Encrypt',
        null, // return type
        ['string','string','number'],
        [publicKey, chBuffer, data.byteLength]); // arguments

      /*var val_last = Module.getValue(sizebuff,'i32');
      for(var i=0;i<val_last;i++) {
        var value = Module.getValue(chBuffer+i,'i8');
        EncryptedBytes[length++] = value;
      }*/

      //returnBtyes = EncryptedBytes.slice(0,length);
    } catch (e) {
      console.log("Error",e);
    } finally {
      // To avoid memory leaks we need to always clear out the allocated heap data
      // This needs to happen in the finally block, otherwise thrown errors will stop code execution before this happens
    //  Module._free(emBuffer.byteOffset);
      Module._free(chBuffer.byteOffset);
    }

    return returnBtyes;
    //resolve(returnBtyes);
  //});
}

export function RSAEncrypt(encryptAlgorithm, publicKey, data){
  return new Promise(function(resolve, reject) {
    crypto.subtle.encrypt(
        encryptAlgorithm,
        publicKey, //from generateKey or importKey above
        data //ArrayBuffer of data you want to encrypt
    )
    .then(function(encrypted){
        //returns an ArrayBuffer containing the encrypted data
        resolve(encrypted);//new Uint8Array(
    })
    .catch(function(err){
        reject(err);
    });
  });
}

export function RSADecrypt(encryptAlgorithm, privateKey, data){
  return new Promise(function(resolve, reject) {
    crypto.subtle.decrypt(
        encryptAlgorithm,
        privateKey, //from generateKey or importKey above
        data //ArrayBuffer of the data
    )
    .then(function(decrypted){
        //returns an ArrayBuffer containing the decrypted data
        resolve(decrypted);
//        resolve(new Uint8Array(decrypted));
    })
    .catch(function(err){
        reject(err);
    });
  });
}

export function RSAsignData(key, signAlgorithm, data) {
  return crypto.subtle.sign(signAlgorithm, key, data)
}

export function RSAtestVerifySig(pub, signAlgorithm, sig, data) {
  return crypto.subtle.verify(signAlgorithm, pub, sig, data)
}

//--------------------------------------------------------------------
var GenerateIV = function(){
  var IV = new Uint8Array(16);
  if (crypto === undefined || crypto === null){
    function random (low, high) {
      return Math.floor(Math.random() * (high - low) + low);
    }
    for (var i = 0; i < 16; i++) {
      IV[i] = random(0,256);
    }
  }else{
    IV = GenerateRandom(16);
  }

  return IV;
}

export function AESWASMDecrypt(ukeyBuf, udata) {
  const BUFSIZE = 4096;
  var returnBtyes = null;
  let emBuffer;
  let sizebuff;
  let chBuffer;
  try {
    var length = 0;
    var data = new Uint8Array(udata);
    var keyBuf = new Uint8Array(ukeyBuf);
    var EncryptedBytes = new Uint8Array(data.byteLength + BUFSIZE);

    emBuffer = Module._malloc(BUFSIZE);
    chBuffer = Module._malloc(BUFSIZE * 2);
    sizebuff = Module._malloc(1);

    //get the IV to first 16 bytes
    for(var i=0;i<16;i++) {
      Module.setValue(emBuffer+i,data[i],'i8');
    }
    //set the key
    for(var i=0;i<keyBuf.byteLength;i++) {
      Module.setValue(chBuffer+i,keyBuf[i],'i8');
    }

    Module.ccall('AES_Init',
      null, // return type
      ['bool','number','number'],
      [false,chBuffer,emBuffer]); // arguments

    var filepos = 16;
    while(filepos < data.length){
      var len = BUFSIZE;
      if((data.length - filepos) < BUFSIZE) len = data.length - filepos;

      Module.setValue(sizebuff,len,'i32');
      //copy the data to memory
      for(i=0;i<len;i++) {
        Module.setValue(emBuffer+ i,data[filepos + i],'i8');
      }

      //call encrypt
      Module.ccall('AES_Push',
        'number',
        ['number','number','number'],
        [emBuffer, sizebuff,chBuffer]);

      //push chiper into variable
      var val = Module.getValue(sizebuff,'i32');
      for(var i=0;i<val;i++) {
        var value = Module.getValue(chBuffer+i,'i8');
        EncryptedBytes[length++] = value;
      }

      filepos += len;
    }

    Module.setValue(sizebuff,0,'i32');
    Module.ccall('AES_Finish',
      null,
      ['number','number'],
      [sizebuff, chBuffer]);
    var val_last = Module.getValue(sizebuff,'i32');
    for(var i=0;i<val_last;i++) {
      var value = Module.getValue(chBuffer+i,'i8');
      EncryptedBytes[length++] = value;
    }
    returnBtyes = EncryptedBytes.slice(0,length);
  } catch (e) {
    console.log("Error",e);
  } finally {
    // To avoid memory leaks we need to always clear out the allocated heap data
    // This needs to happen in the finally block, otherwise thrown errors will stop code execution before this happens
    Module._free(emBuffer.byteOffset);
    Module._free(sizebuff.byteOffset);
    Module._free(chBuffer.byteOffset);
  }
  return returnBtyes;
}

export function AESWASMEncrypt(keyBuf, data) {
  const BUFSIZE = 4096;

  var returnBtyes = null;

  let emBuffer;
  let sizebuff;
  let chBuffer;
  try {
    var length = 0;
    var EncryptedBytes = new Uint8Array(data.length + BUFSIZE);

    emBuffer = Module._malloc(BUFSIZE);
    chBuffer = Module._malloc(BUFSIZE * 2);
    sizebuff = Module._malloc(1);

    //copy the IV to first 16 bytes
    const IV = GenerateIV();
    for(var i=0;i<16;i++) {
      EncryptedBytes[length++] = IV[i];
      Module.setValue(emBuffer+i,IV[i],'i8');
    }
    //set the key
    for(var i=0;i<keyBuf.byteLength;i++) {
      Module.setValue(chBuffer+i,keyBuf[i],'i8');
    }

    Module.ccall('AES_Init',
      null, // return type
      ['bool','number','number'],
      [true,chBuffer,emBuffer]); // arguments

    var filepos = 0;
    while(filepos < data.length){
      var len = BUFSIZE;
      if((data.length - filepos) < BUFSIZE) len = data.length - filepos;

      Module.setValue(sizebuff,len,'i32');
      //copy the data to memory
      for(i=0;i<len;i++) {
        Module.setValue(emBuffer+ i,data[filepos + i],'i8');
      }

      //call encrypt
      Module.ccall('AES_Push',
        'number',
        ['number','number','number'],
        [emBuffer, sizebuff,chBuffer]);

      //push chiper into variable
      var val = Module.getValue(sizebuff,'i32');
      for(var i=0;i<val;i++) {
        var value = Module.getValue(chBuffer+i,'i8');
        EncryptedBytes[length++] = value;
      }

      filepos += len;
    }

    Module.setValue(sizebuff,0,'i32');
    Module.ccall('AES_Finish',
      null,
      ['number','number'],
      [sizebuff, chBuffer]);
    var val_last = Module.getValue(sizebuff,'i32');
    for(var i=0;i<val_last;i++) {
      var value = Module.getValue(chBuffer+i,'i8');
      EncryptedBytes[length++] = value;
    }

    returnBtyes = EncryptedBytes.slice(0,length);
  } catch (e) {
    console.log("Error",e);
  } finally {
    // To avoid memory leaks we need to always clear out the allocated heap data
    // This needs to happen in the finally block, otherwise thrown errors will stop code execution before this happens
    Module._free(emBuffer.byteOffset);
    Module._free(sizebuff.byteOffset);
    Module._free(chBuffer.byteOffset);
  }

  return returnBtyes;
}

export function AESDecrypt(keyBuf, data) {
  return new Promise(function(resolve, reject) {
    if(!crypto){
      if(Module === null || Module === undefined) return reject("WASM Failed to load");
      resolve(AESWASMDecrypt(keyBuf, data));
    }else{
      var _iv = new Uint8Array(16);
      var buff = new Uint8Array(data);
      for(var i=0;i<16;i++) {
        _iv[i] = buff[i];
      }

      var encytdata = new Uint8Array(buff.length - 16);
      for(var i=16;i<(buff.length);i++) {
        encytdata[i-16] = buff[i];
      }
      crypto.subtle.importKey(
        "raw",
        keyBuf,
        { name: "AES-CBC" },
        true,
        ["encrypt", "decrypt"]
      )
      .then(function(key){
        //returns the symmetric key
        crypto.subtle.decrypt(
          { name: "AES-CBC", iv: _iv },
          key, //from generateKey or importKey above
          encytdata //ArrayBuffer of data you want to encrypt
        )
        .then(function(decrypted){
          //returns an ArrayBuffer containing the encrypted data
          resolve(decrypted);
        })
         // error handling
        .catch(function(err){
            reject("aesdecrypt "+ err);
        });
      })
      .catch(function(err){
        reject("aesdecrypt key "+ err);
      });
    }
  });
}

export function AESEncrypt(keyBuf, data) {
  return new Promise(function(resolve, reject) {
    if(!crypto){
      if(Module === null || Module === undefined) return reject("WASM Failed to load");
      resolve(AESWASMEncrypt(keyBuf, data));
    }else{
      crypto.subtle.importKey(
        "raw",
        keyBuf,
        { name: "AES-CBC" },
        true,
        ["encrypt", "decrypt"]
      )
      .then(function(key){
        //returns the symmetric key
        var ivbuf = crypto.getRandomValues(new Uint8Array(16));

        crypto.subtle.encrypt(
          {
            name: "AES-CBC",
            iv: ivbuf,
          },
          key, //from generateKey or importKey above
          data //ArrayBuffer of data you want to encrypt
        )
        .then(function(encryptedData){
          //returns an ArrayBuffer containing the encrypted data
          var ndata = new Uint8Array(16 + encryptedData.byteLength);
          ndata.set(ivbuf,0);
          ndata.set(new Uint8Array(encryptedData),16);
          resolve(ndata);
        })
         // error handling
        .catch(function(err){
            reject("aesencrypt "+ err);
        });
      })
      .catch(function(err){
        reject("aesencrypt key "+ err);
      });
    }
  });
}
//--------------------------------------------------------------------
export function SHA256(string) {
  return new Promise(function(resolve, reject) {
    var buff = textToArrayBuffer(string);
    crypto.subtle.digest(
      {
          name: "SHA-256",
      },
      buff //The data you want to hash as an ArrayBuffer
    )
    .then(function(hash){
        //returns the hash as an ArrayBuffer
        resolve(arrayBufferToHex(new Uint8Array(hash)));
    })
    .catch(function(error){
      reject("sha "+error);
    });
  });
}

export function MD5(message){
  var md5 = require('js-md5');
  return md5(message);
}
//--------------------------------------------------------------------
class RSACrypto
{
  constructor() {
    this.privateKey = null;
    this.privateKeySign = null;
    this.publicKey = null;
    this.privateKeyPem = "";
    this.publicKeyPem = "";
    this.storename = '';
    this.defaultAlgorithmType = 2
  }

  LoadPemKeys(Private, Public){
    this.privateKeyPem = Private;
    this.publicKeyPem = Public;
    return new Promise((resolve, reject) => {
      // some async operation here
      importPrivateKey(this.privateKeyPem, defaultRSAAlgorithmMethod, false)
      .then((privatekey) => {
        this.privateKey = privatekey;
        importPrivateKeySign(this.privateKeyPem, defaultRSASignMethod, false)
        .then((privatekeySign) => {
          this.privateKeySign = privatekeySign;
          importPublicKey(this.publicKeyPem, defaultRSAAlgorithmMethod)
          .then((publickey) => {
            this.publicKey = publickey;
            resolve({private: privatekey, public: publickey});
          })
          .catch((error) => {
            reject("importpublickey "+error);
          });
        })
        .catch(function(error) {
          reject("importprivatekeySign "+error);
        });
      })
      .catch(function(error) {
        reject("importrsakey "+error);
      });
    });
  }

  LoadPublicKey(Public, type = 2){
    this.publicKeyPem = Public;
    return new Promise((resolve, reject) => {
      // some async operation here
      this.defaultAlgorithmType = type !== undefined && type === 1? 1: 2
      const algorithm = this.defaultAlgorithmType === 1? defaultRSAAlgorithm256Method : defaultRSAAlgorithmMethod

      importPublicKey(this.publicKeyPem, algorithm)
      .then((publickey) => {
        this.publicKey = publickey;
        resolve({public: publickey});
      })
      .catch((error) => {
        console.log('LoadPublicKey err',error);
        reject("importpublickey "+error);
      });
    });
  }

  SaveKeystoDb(type = undefined){
    return new Promise(async(resolve, reject) => {
      try{
        await keysStorage.Put({id: this.storename+'KPublic', key: this.publicKey}) //platform key
        await keysStorage.Put({id: this.storename+'KPrivate', key: this.privateKey}) //device keys
        await keysStorage.Put({id: this.storename+'KPrivateSign', key: this.privateKeySign}) //device keys
        if(type !== undefined){
          await keysStorage.Put({id: this.storename+'KPublicType', key: type}) //platform key
        }
        resolve();
      }catch(e){
        reject("failed to save")
      }
    });
  }

  LoadKeysfromDb(username){
    if(username === "") return;
    this.privateKey = null;
    this.publicKey = null;
    this.privateKeySign = null;
    this.privateKeyPem = "";
    this.publicKeyPem = "";
    this.storename = username;

    return new Promise(async(resolve, reject) => {
      try{
        const privResult = await keysStorage.Get(this.storename+'KPrivate')
        const pubResult = await keysStorage.Get(this.storename+'KPublic')
        const privResultSign = await keysStorage.Get(this.storename+'KPrivateSign')
        try{
          const type = await keysStorage.Get(this.storename+'KPublicType')
          if(type.key !== null)
            this.defaultAlgorithmType = type.key
        }catch(e){
          reject("failed to load")
        }
        if(pubResult.key !== null)
          this.publicKey = pubResult.key;
        if(privResult.key !== null)
          this.privateKey = privResult.key;
        if(privResultSign.key !== null)
          this.privateKeySign = privResultSign.key;
        resolve({KPrivate:this.privateKey, KPublic: this.publicKey});
      }catch(e){
console.log("LoadKeysfromDbe",e)
        reject("failed to load")
      }
    });
  }

  ResetKeys(username){
    this.privateKey = null;
    this.publicKey = null;
    this.privateKeySign = null;
    this.privateKeyPem = "";
    this.publicKeyPem = "";
    this.storename = username;
    var _this = this;

    keysStorage.Delete({id: _this.storename+'KPrivate'});
    keysStorage.Delete({id: _this.storename+'KPrivateSign'});
    keysStorage.Delete({id: _this.storename+'KPublic'});
  }

  ClearAllKeys(){
    this.privateKey = null;
    this.publicKey = null;
    this.privateKeySign = null;
    this.privateKeyPem = "";
    this.publicKeyPem = "";
    this.storename = "";
    var _this = this;

    keysStorage.ClearAll();
  }

  hasKeys(){
    if(this.privateKey !== null && this.publicKey !== null && this.privateKeySign !== null) return true;
    return false;
  }

  setStoreName(username){
    this.storename = username
  }

  GeneratePrivateWithPublicKeyPem(){
    return new Promise((resolve, reject) => {
      GenerateRSAKey(defaultRSAAlgorithmMethod)
      .then((keys) => {
        //extract and then load private key and store as non extractable
        exportPrivateKeyToPEM(keys.privateKey)
        .then((pemkeypriv) => {
          importPrivateKey(pemkeypriv, defaultRSAAlgorithmMethod, false)
          .then((privatekey) => {
            importPrivateKeySign(pemkeypriv, defaultRSASignMethod, false)
            .then((privatekeySign) => {
              this.privateKeySign = privatekeySign;
              this.privateKey = privatekey;
              exportPublicKeyToPEM(keys.publicKey)
              .then((pemkey) => {
                resolve(pemkey);
              })
              .catch((error) => {
                reject("rsaextkey "+error);
              });
            })
            .catch((error) => {
              reject("importkeySign "+error);
            });
          })
          .catch((error) => {
            reject("importkey "+error);
          });
        })
        .catch((error) => {
          reject("rsaextkey "+error);
        });
      })
      .catch((error) => {
        reject("rsagenkey "+error);
      });
    });
  }

  Encrypt(data){
    return new Promise((resolve, reject) => {
      RSAEncrypt(this.defaultAlgorithmType === 1? defaultRSAAlgorithm256Method : defaultRSAAlgorithmMethod, this.publicKey, data)
      .then((encryptedData) => {
        resolve(encryptedData);
      })
      .catch((error) => {
        reject("rsaencrypt "+error);
      });
    });
  }

  Decrypt(data){
    return new Promise((resolve, reject) => {
      RSADecrypt(defaultRSAAlgorithmMethod, this.privateKey, data)
      .then((decryptedData) => {
        resolve(decryptedData);
      })
      .catch((error) => {
        reject("rsadecypt "+error);
      });
    });
  }

  SignPem(key, data){
    return new Promise((resolve, reject) => {
      // some async operation here
      var importer = crypto.subtle.importKey("pkcs8", convertPemToBinary(key), defaultRSASignMethod, true, ["sign"]);
      importer.then((ikey) => {
        RSAsignData(ikey, defaultRSASignMethod, data)
        .then((encryptedData) => {
          resolve(encryptedData);
        })
        .catch((error) => {
          reject("rsasign "+error);
        });
      })
      .catch((error) => {
        reject("importrsakey "+error);
      });
    });
  }

  Sign(key, data){
    return new Promise((resolve, reject) => {
      // some async operation here
      RSAsignData(key, defaultRSASignMethod, data)
      .then((encryptedData) => {
        resolve(encryptedData);
      })
      .catch((error) => {
        reject("rsasign "+error);
      });
    });
  }

  SignWithDevice(data){
    return new Promise((resolve, reject) => {
      RSAsignData(this.privateKeySign, defaultRSASignMethod, data)
      .then((encryptedData) => {
        resolve(encryptedData);
      })
      .catch((error) => {
        reject("rsasign "+error);
      });
    });
  }
}

export default (new RSACrypto);
