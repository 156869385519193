export const adminPermissionsConstants = {
    COPY_BOARD_PERMISSIONS: 'COPY_BOARD_PERMISSIONS',
    SET_NEW_BOARD_PERMISSIONS: 'SET_NEW_BOARD_PERMISSIONS',
    SET_PERMISSIONS: 'SET_PERMISSIONS',
    ADD_PERMISSION: 'ADD_PERMISSION',
    REMOVE_PERMISSION: 'REMOVE_PERMISSION',
    UPDATE_PERMISSION: 'UPDATE_PERMISSION',
    UPDATE_PERMISSION_BY_USERID: 'UPDATE_PERMISSION_BY_USERID',
    GIVE_OWNERSHIP: 'GIVE_OWNERSHIP',
    SHOULD_DELETE_PERMISSION: 'SHOULD_DELETE_PERMISSION',
}