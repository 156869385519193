import { alertConstants as baseConstants } from '../alert.constants'

const constants = {
    ERROR_NOTIFICATION: 'ALERT_ERROR_NOTIFICATION',
    CLEAR_NOTIFICATION: 'ALERT_CLEAR_NOTIFICATION',
    CLEAR_MULTIPLE_NOTIFICATION: 'CLEAR_MULTIPLE_NOTIFICATION',

    PUSH_NOTIFICATION_REQUEST: 'PUSH_NOTIFICATION_REQUEST',
    PUSH_NOTIFICATION_SUCCESS: 'PUSH_NOTIFICATION_SUCCESS',
    PUSH_NOTIFICATION_FAILURE: 'PUSH_NOTIFICATION_FAILURE',

    LOADING_INDICATOR_START: 'LOADING_INDICATOR_START',
    LOADING_INDICATOR_SUCCESS: 'LOADING_INDICATOR_SUCCESS',
    LOADING_INDICATOR_PROGRESS: 'LOADING_INDICATOR_PROGRESS',

    CLEARALL: 'ALERT_CLEARALL',
};

export const alertConstants = Object.assign(constants, baseConstants)
