export const customerConstants = {
  GET_CUST_SETTINGS_REQUEST: 'GET_CUST_SETTINGS_REQUEST',
  GET_CUST_SETTINGS_SUCCESS: 'GET_CUST_SETTINGS_SUCCESS',
  GET_CUST_SETTINGS_FAILURE: 'GET_CUST_SETTINGS_FAILURE',

  SET_CUST_SETTINGS_REQUEST: 'SET_CUST_SETTINGS_REQUEST',
  SET_CUST_SETTINGS_SUCCESS: 'SET_CUST_SETTINGS_SUCCESS',
  SET_CUST_SETTINGS_FAILURE: 'SET_CUST_SETTINGS_FAILURE',

  UPDATE_CUST_SETTINGS_SUCCESS: 'UPDATE_CUST_SETTINGS_SUCCESS',

  GET_NOTFIC_SETTINGS_REQUEST: 'GET_NOTFIC_SETTINGS_REQUEST',
  GET_NOTFIC_SETTINGS_SUCCESS: 'GET_NOTFIC_SETTINGS_SUCCESS',
  GET_NOTFIC_SETTINGS_FAILURE: 'GET_NOTFIC_SETTINGS_FAILURE',

  GET_ALL_LOCKS_REQUEST: 'GET_ALL_LOCKS_REQUEST',
  GET_ALL_LOCKS_SUCCESS: 'GET_ALL_LOCKS_SUCCESS',
  GET_ALL_LOCKS_FAILURE: 'GET_ALL_LOCKS_FAILURE',

  GET_VIEW_LOCKS_REQUEST: 'GET_VIEW_LOCKS_REQUEST',
  GET_VIEW_LOCKS_SUCCESS: 'GET_VIEW_LOCKS_SUCCESS',
  GET_VIEW_LOCKS_FAILURE: 'GET_VIEW_LOCKS_FAILURE',

  DELETE_LOCKS_REQUEST: 'DELETE_LOCKS_REQUEST',
  DELETE_LOCKS_SUCCESS: 'DELETE_LOCKS_SUCCESS',
  DELETE_LOCKS_FAILURE: 'DELETE_LOCKS_FAILURE',

  SET_LOCKS_REQUEST: 'SET_LOCKS_REQUEST',
  SET_LOCKS_SUCCESS: 'SET_LOCKS_SUCCESS',
  SET_LOCKS_FAILURE: 'SET_LOCKS_FAILURE',

  SEND_UPGRADE_REQUEST: 'SEND_UPGRADE_REQUEST',
  SEND_UPGRADE_SUCCESS: 'SEND_UPGRADE_SUCCESS',
  SEND_UPGRADE_FAILURE: 'SEND_UPGRADE_FAILURE',

  SWITCH_CUSTOMER: 'SWITCH_CUSTOMER',

  SET_SETTINGS_STATUS_REQUEST: 'SET_SETTINGS_STATUS_REQUEST',
  SET_SETTINGS_STATUS_SUCCESS: 'SET_SETTINGS_STATUS_SUCCESS',
  SET_SETTINGS_STATUS_FAILURE: 'SET_SETTINGS_STATUS_FAILURE',

  GET_SETTINGS_STATUS_REQUEST: 'GET_SETTINGS_STATUS_REQUEST',
  GET_SETTINGS_STATUS_SUCCESS: 'GET_SETTINGS_STATUS_SUCCESS',
  GET_SETTINGS_STATUS_FAILURE: 'GET_SETTINGS_STATUS_FAILURE',

  UPDATE_CUST_COMPANY_NAME_SUCCESS: 'UPDATE_CUST_COMPANY_NAME_SUCCESS',
};
