export const queueConstants = {
  REMOVE_FILE_REQUEST: 'REMOVE_FILE_REQUEST',

  UPDATE_QUEUE_REQUEST: 'UPDATE_QUEUE_REQUEST',

  SWAP_QUEUE_REQUEST: 'SWAP_QUEUE_REQUEST',

  CHECK_STATE_REQUEST: 'CHECK_STATE_REQUEST',

  ADD_QUEUE_BINDER_REQUEST: 'ADD_QUEUE_BINDER_REQUEST',
  ADD_QUEUE_TEMPLATE_REQUEST: 'ADD_QUEUE_TEMPLATE_REQUEST',

  ADD_QUEUE_DOCUMENT_REQUEST: 'ADD_QUEUE_DOCUMENT_REQUEST',
  ADD_QUEUE_FILE_REQUEST: 'ADD_QUEUE_FILE_REQUEST',
  ADD_QUEUE_FILES_REQUEST: 'ADD_QUEUE_FILES_REQUEST',

  ADD_QUEUE_MINUTES_REQUEST: 'ADD_QUEUE_MINUTES_REQUEST',

  ADD_QUEUE_CHECKDOC_REQUEST: 'ADD_QUEUE_CHECKDOC_REQUEST',

  ADD_QUEUE_CONVERT_REQUEST: 'ADD_QUEUE_CONVERT_REQUEST',

  ADD_QUEUE_PDF_REQUEST: 'ADD_QUEUE_PDF_REQUEST',

  MERGE_QUEUE_PDF_REQUEST: 'MERGE_QUEUE_PDF_REQUEST',

  DOWNLOAD_QUEUE_FILE_REQUEST: 'DOWNLOAD_QUEUE_FILE_REQUEST',

  DOWNLOAD_QUEUE_DOCUMENT_REQUEST: 'DOWNLOAD_QUEUE_DOCUMENT_REQUEST',

  CANCEL_QUEUE_ITEM_REQUEST: 'CANCEL_QUEUE_ITEM_REQUEST',
  CANCEL_QUEUEERROR_ITEM_REQUEST: 'CANCEL_QUEUEERROR_ITEM_REQUEST',

  COMPLETE_QUEUE_ITEM_REQUEST: 'COMPLETE_QUEUE_ITEM_REQUEST',
  COMPLETE_QUEUE_NODELETE_REQUEST: 'COMPLETE_QUEUE_NODELETE_REQUEST',

  COMPLETE_CONVERT_ITEM_REQUEST: 'COMPLETE_CONVERT_ITEM_REQUEST',

  PROGRESS_QUEUE_ITEM_REQUEST: 'PROGRESS_QUEUE_ITEM_REQUEST',

  START_QUEUE_ITEM_REQUEST: 'START_QUEUE_ITEM_REQUEST',
  PAUSE_QUEUE_ITEM_REQUEST: 'PAUSE_QUEUE_ITEM_REQUEST',
  RESUME_QUEUE_ITEM_REQUEST: 'RESUME_QUEUE_ITEM_REQUEST',
  RETRY_QUEUE_ITEM_REQUEST: 'RETRY_QUEUE_ITEM_REQUEST',

  ERROR_QUEUE_ITEM_REQUEST: 'ERROR_QUEUE_ITEM_REQUEST',
};
