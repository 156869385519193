export const webConstants = {
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  DOWNLOAD_DOCUMENT: 'DOWNLOAD_DOCUMENT',
  DOWNLOAD_POPULATE: 'DOWNLOAD_POPULATE',
  DOWNLOAD_NEWTAB: 'DOWNLOAD_NEWTAB',
  DOWNLOAD_STORE: 'DOWNLOAD_STORE',

  WORKER_PAUSE: 'WORKER_PAUSE',
  WORKER_RESUME: 'WORKER_RESUME',

  VERIFY_START: 'VERIFY_START',

  UPDATE_UPLD: 'UPDATE_UPLD',
  ERROR_WORKER: 'ERROR_WORKER',
  COMPLETE_WORKER: 'COMPLETE_WORKER',
  WATCHDOG_WORKER: 'WATCHDOG_WORKER',
  MESSAGE: 'MESSAGE',
  COMPLETE_RESOLUTION: 'COMPLETE_RESOLUTION',

  RSA_ENCRYPT: 'RSA_ENCRYPT',
  RSA_DECRYPT: 'RSA_DECRYPT',
  AES_ENCRYPT: 'AES_ENCRYPT',
  AES_DECRYPT: 'AES_DECRYPT',
};
