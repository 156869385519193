export const deviceConstants = {
  GET_DEVICE_REQUEST: 'GET_DEVICE_REQUEST',
  GET_DEVICE_SUCCESS: 'GET_DEVICE_SUCCESS',
  GET_DEVICE_FAILURE: 'GET_DEVICE_FAILURE',

  SET_DEVICE_STATUS_REQUEST: 'SET_DEVICE_STATUS_REQUEST',
  SET_DEVICE_STATUS_SUCCESS: 'SET_DEVICE_STATUS_SUCCESS',
  SET_DEVICE_STATUS_FAILURE: 'SET_DEVICE_STATUS_FAILURE',

  SET_NEW_DEVICE_REQUEST: 'SET_NEW_DEVICE_REQUEST',
  SET_NEW_DEVICE_SUCCESS: 'SET_NEW_DEVICE_SUCCESS',
  SET_NEW_DEVICE_FAILURE: 'SET_NEW_DEVICE_FAILURE',
};
