import { customerConstants } from "../../constants/admin";

export const resolutionsConstants = {
    ADD_RESOLUTIONS: 'ADD_RESOLUTIONS',
    UPDATE_RESOLUTION: 'UPDATE_RESOLUTION',
    DELETE_RESOLUTION: 'DELETE_RESOLUTION',
    SET_RESOLUTION: 'SET_RESOLUTION',
    UPDATE_LOCK: 'UPDATE_LOCK'
}

var INITIAL_STATE = {
    resolutions: {},
    locks: {},
}

function deleteResolution(state, resolutionId, boardId) {
    let newState = { ...state };
    try {
        delete newState.resolutions[boardId][resolutionId];
        newState.resolutions[boardId] = { ...newState.resolutions[boardId] };
    } catch { }
    return newState;
}

function addResolutions(state, resolutions) {
    let newState = { ...state };
    resolutions.forEach(r => {
        if (!newState.resolutions[r.boardId]) {
            newState.resolutions[r.boardId] = {};
        } else {
            newState.resolutions[r.boardId] = { ...newState.resolutions[r.boardId] };
        }
        newState.resolutions[r.boardId][r.id] = r;
    })

    return newState;
}

function setResolution(state, resolution) {
    let newState = { ...state };
    
    if (!newState.resolutions[resolution.boardId]) {
        newState.resolutions[resolution.boardId] = {};
    }

    try {
        newState.resolutions[resolution.boardId][resolution.id] = JSON.parse(JSON.stringify(resolution));
    } catch {}

    return newState;
}

function updateResolution(state, resolutionId, boardId, properties = {}) {
    let newState = { ...state };

    if (newState.resolutions[boardId] && newState.resolutions[boardId][resolutionId]) {
        Object.keys(properties).forEach(key => {
            newState.resolutions[resolutionId][key] = properties[key];
        });
    }

    return newState;
}

function updateLocks(state, payload) {
    var newLocks = { ...state.locks };
    if (Array.isArray(payload)) {
        var locks = payload.filter(l => l.objectType == 'CircularResolution');
        for (var l of locks) {
            newLocks[l.objectId] = l;
        }
    } else {
        if (!payload.userId) {
            try {
                delete newLocks[payload.objectId];
            } catch { }
        } else {
            newLocks[payload.objectId] = payload;
        }
    }
    state.locks = newLocks;
    return state;
}

export function resolutions(state = INITIAL_STATE, action) {
    switch (action.type) {
        case resolutionsConstants.DELETE_RESOLUTION:
            return deleteResolution(state, action.payload.id, action.payload.boardId);
        case resolutionsConstants.UPDATE_RESOLUTION:
            return updateResolution(state, action.payload.id, action.payload.boardId, action.payload.properties);
        case resolutionsConstants.ADD_RESOLUTIONS:
            return addResolutions(state, action.payload);
        case resolutionsConstants.SET_RESOLUTION:
            return setResolution(state, action.payload);
        case customerConstants.GET_ALL_LOCKS_SUCCESS:
        case resolutionsConstants.UPDATE_LOCK:
            return updateLocks(state, action.payload);
        default:
            return state;
    }
}
