export const userConstants = {
  INTERNET_LOST: 'INTERNET_LOST',

  CLEAR_ERROR_MSG: 'CLEAR_ERROR_MSG',

  REGISTER_USERDEVICE_REQUEST: 'USERDEVICE_REGISTER_REQUEST',
  REGISTER_USERDEVICE_SUCCESS: 'USERDEVICE_REGISTER_SUCCESS',
  REGISTER_USERDEVICE_FAILURE: 'USERDEVICE_REGISTER_FAILURE',

  SET_USERNAME: 'SET_USERNAME',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGIN_WAITING_APPROVAL: 'LOGIN_WAITING_APPROVAL',

  LOGIN_DEVICE_FAILURE: 'LOGIN_DEVICE_FAILURE',

  LOGIN_AUTHZERO_REQUEST: 'LOGIN_AUTHZERO_REQUEST',
  LOGIN_AUTHZERO_SUCCESS: 'LOGIN_AUTHZERO_SUCCESS',
  LOGIN_AUTHZERO_FAILURE: 'LOGIN_AUTHZERO_FAILURE',

  REGISTER_USERANDDEVICE_AUTHZERO: 'REGISTER_USERANDDEVICE_AUTHZERO',
  REGISTER_USERANDDEVICE_NEWZERO: 'REGISTER_USERANDDEVICE_NEWZERO',

  LOGOUT: 'USERS_LOGOUT',
  LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',

  HAS_DEVICE_NOTFY_REQUEST: 'HAS_DEVICE_NOTFY_REQUEST',
  HAS_DEVICE_NOTFY_SUCCESS: 'HAS_DEVICE_NOTFY_SUCCESS',
  HAS_DEVICE_NOTFY_FAILURE: 'HAS_DEVICE_NOTFY_FAILURE',

  HAS_DEVICE_CHECKED_SUCCESS: 'HAS_DEVICE_CHECKED_SUCCESS',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  CHANGE_PASSWORD_INTIALISE: 'CHANGE_PASSWORD_INTIALISE',
  CHANGE_PASSWORD_CLEAR: 'CHANGE_PASSWORD_CLEAR',

  SET_KEYS: 'SET_KEYS',

  SESSION_REPLACEMENT: 'SESSION_REPLACEMENT',

  AUTO_INVITE_REQUEST: 'AUTO_INVITE_REQUEST',
  AUTO_INVITE_UPDATE: 'AUTO_INVITE_UPDATE',
  AUTO_INVITE_SUCCESS: 'AUTO_INVITE_SUCCESS',
  AUTO_INVITE_FAILURE: 'AUTO_INVITE_FAILURE',

  DIRECT_SIGNUP_REQUEST: 'DIRECT_SIGNUP_REQUEST',
  DIRECT_SIGNUP_SUCCESS: 'DIRECT_SIGNUP_SUCCESS',
  DIRECT_SIGNUP_FAILURE: 'DIRECT_SIGNUP_FAILURE',

  TRAIL_CHECKEMAIL_REQUEST: 'TRAIL_CHECKEMAIL_REQUEST',
  TRAIL_CHECKEMAIL_SUCCESS: 'TRAIL_CHECKEMAIL_SUCCESS',
  TRAIL_CHECKEMAIL_FAILURE: 'TRAIL_CHECKEMAIL_FAILURE',
  TRAIL_CHECKEMAIL_DELETE: 'TRAIL_CHECKEMAIL_DELETE',

  TRAIL_COSECSAVE_REQUEST: 'TRAIL_COSECSAVE_REQUEST',
  TRAIL_COSECSAVE_SUCCESS: 'TRAIL_COSECSAVE_SUCCESS',
  TRAIL_COSECSAVE_FAILURE: 'TRAIL_COSECSAVE_FAILURE',

  TRAIL_SIGNUP_REQUEST: 'TRAIL_SIGNUP_REQUEST',
  TRAIL_SIGNUP_SUCCESS: 'TRAIL_SIGNUP_SUCCESS',
  TRAIL_SIGNUP_FAILURE: 'TRAIL_SIGNUP_FAILURE',

  TRAIL_COMPLETE_REQUEST: 'TRAIL_COMPLETE_REQUEST',
  TRAIL_COMPLETE_SUCCESS: 'TRAIL_COMPLETE_SUCCESS',
  TRAIL_COMPLETE_FAILURE: 'TRAIL_COMPLETE_FAILURE',

  CLEAR_CONFIRM_INVITE_SUCESS: 'CLEAR_CONFIRM_INVITE_SUCESS',
};
