import * as CrytpoLib from './cryptojs';

if(typeof window !== 'undefined')
  var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
else{
  /* eslint-disable-next-line no-restricted-globals */
  var indexedDB = self.indexedDB || self.mozIndexedDB || self.webkitIndexedDB || self.msIndexedDB || self.shimIndexedDB;
}
var dbVersion = 1;

class dbStorage
{
  constructor(dbName, storageName) {
    this.setDB(dbName, storageName)
  }

  setDB(dbName, storageName){
    this.dbName = dbName;
    this.storageName = storageName;
    this.open = false
    // Open (or create) the database
    if(indexedDB === undefined){
      //localStorage.setItem(storageName, {});
    }else{
      this.request = indexedDB.open(dbName, dbVersion);
      // Create the schema
    	this.request.onupgradeneeded = () => {
  	    var db = this.request.result;
        db.createObjectStore(this.storageName, {keyPath: "id"});
    	};
       this.request.onsuccess = (event) => {
        this.open = true
      //   this.request = event.target.result;
       };
    }
  }

  Get(key){
    // Start a new transaction
    //console.log("GetItem A",key);
    return new Promise((resolve, reject) => {
      //console.log("GetItem state",key,this.request.readyState);
      if(this.request.readyState === 'pending') return reject('pending');

      if(indexedDB === undefined){
        //console.log("GetItem localStorage",key);
        var s = localStorage.getItem(this.storageName+key);
        try{
          var j = JSON.parse(s);
          return resolve(j);
        }catch(err){
          //console.log("GetItem err",key,err);
        }
        //console.log("GetItem reject",key);
        return reject();
      }

      //console.log("GetItem B",key,this.request.result);
      var db = this.request.result;
      //console.log("GetItem C",key);
  		try{
        //console.log("GetItem D",key,this.storageName);
  			var tx = db.transaction(this.storageName, "readwrite");
      	var store = tx.objectStore(this.storageName);

        var request = store.get(key);
        request.onsuccess = () => {
          //console.log("GetItem found",key);
          if(request.result !== undefined){
            resolve(request.result);
          }else reject()
        };
        request.onerror = (error) => {
          //console.log('GetItem error',key,error);
          reject();
          return;
        }
  			// Close the db when the transaction is done
  	    tx.oncomplete = () => {
          //console.log("GetItem close db",key);
  	    //    db.close();
  	    };
  		}catch(err){
        if(err.code === 11){
          this.setDB(this.dbName, this.storageName);
        }
        //console.log("GetItem err",key,err);
        reject();
      }
    });
  }

  Set(key, data){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        localStorage.setItem(this.storageName+key,JSON.stringify(data));
        return resolve();
      }

      //console.log("Set",key, data,this.storageName);
      var db = this.request.result;
      try{
        var tx = db.transaction(this.storageName, "readwrite");
        var store = tx.objectStore(this.storageName);
        store.put({id: key, data: data});
        //console.log("Set all done");
        resolve();
  		}catch(err){
        reject();
      }
    });
  }

  Put(data){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        var key = data.id;
        localStorage.setItem(this.storageName+key,JSON.stringify(data));
        return resolve();
      }
      var db = this.request.result;
      //console.log("Set",data,this.storageName);
      try{
        var tx = db.transaction(this.storageName, "readwrite");
        var store = tx.objectStore(this.storageName);
        store.put(data);
        //console.log("Put all done");
        resolve();
  		}catch(err){
        reject(err);
      }
    });
  }

  Delete(key){
    if(indexedDB === undefined){
      localStorage.removeItem(key);
      return;
    }
    //Remove value
    var db = this.request.result;
    try{
      var tx = db.transaction(this.storageName, "readwrite");
      var store = tx.objectStore(this.storageName);

      store.delete(key);
    }catch(err){

    }
  }

  ClearAll(){
    if(indexedDB === undefined){
      localStorage.clear();
      return;
    }
    //Remove all values
    var db = this.request.result;
    try{
      var tx = db.transaction(this.storageName, "readwrite");
      var store = tx.objectStore(this.storageName);

      var objectStoreRequest = store.clear();
    }catch(err){

    }
  }
}

class KeyStorage extends dbStorage
{
  constructor() {
    super("Athena",'KeyStore');
  }

  Get(key){
    if(indexedDB !== undefined)
      return super.Get(key);
    return new Promise((resolve, reject) => {
      var s = localStorage.getItem(this.storageName+key);
      try{
        var json = JSON.parse(s);
        if(json.id.includes('KPrivate')){
          CrytpoLib.importPrivateKey(json.key, CrytpoLib.defaultRSAAlgorithmMethod)
          .then((cryptoKey) => {
            var j = {
              id: json.id,
              key: cryptoKey,
            }
            resolve(j);
          })
          .catch((err)=>{
            //console.log('err',err)
            reject();
          })
        }else if(json.id.includes('KPublic')){
          CrytpoLib.importPublicKey(json.key, CrytpoLib.defaultRSAAlgorithmMethod)
          .then((cryptoKey) => {
            var j = {
              id: json.id,
              key: cryptoKey,
            }
            resolve(j);
          })
          .catch((err)=>{
            //console.log('err',err)
            reject();
          })
        }else{
          return resolve(json);
        }
      }catch(err){
        //console.log('err',err);
        reject();
      }
    });
  }

  Set(key, data){
    if(indexedDB !== undefined)
      return super.Set(key, data);

    return new Promise((resolve, reject) => {
      if(key.includes('KPrivate')){
        CrytpoLib.exportPrivateKeyToPEM(data)
        .then((pem) => {
          var j = {
            id: key,
            key: pem,
          }

          localStorage.setItem(this.storageName+key,JSON.stringify(j));
          resolve();
        })
        .catch(()=>{reject()})
      }else if(data.id.includes('KPublic')){
        CrytpoLib.exportPublicKeyToPEM(data)
        .then((pem) => {
          var j = {
            id: key,
            key: pem,
          }

          localStorage.setItem(this.storageName+key,JSON.stringify(j));
          resolve();
        })
        .catch(()=>{reject()})
      }else{
        var j = {
          id: key,
          key: data,
        }
        localStorage.setItem(this.storageName+key,JSON.stringify(j));
        resolve();
      }
    });
  }

  Put(data){
    if(indexedDB !== undefined){
      return super.Put(data);
    }

    return new Promise((resolve, reject) => {
      if(data.id.includes('KPrivate')){
        var j = {
          id: data.id,
          key: data.pem,
        }

        //localStorage.setItem(this.storageName+data.id,JSON.stringify(j));
        //resolve();
        if(data.key === null) return resolve();
        CrytpoLib.exportPrivateKeyToPEM(data.key)
        .then((pem) => {
          var j = {
            id: data.id,
            key: pem,
          }

          localStorage.setItem(this.storageName+data.id,JSON.stringify(j));
          resolve();
        })
        .catch((err)=>{
          //console.log('KeyStorage KPrivate Err',err);
          reject();
        });
      }else if(data.id.includes('KPublic')){
        var j = {
          id: data.id,
          key: data.pem,
        }
        //localStorage.setItem(this.storageName+data.id,JSON.stringify(j));
        //resolve();
        if(data.key === null) return resolve();
        CrytpoLib.exportPublicKeyToPEM(data.key)
        .then((pem) => {
          var j = {
            id: data.id,
            key: pem,
          }

          localStorage.setItem(this.storageName+data.id,JSON.stringify(j));
          resolve();
        })
        .catch((err)=>{
          //console.log('KeyStorage KPublic Err',err);
          reject();
        });
      }else{
        localStorage.setItem(this.storageName+data.id,JSON.stringify(data));
        resolve();
      }
    });
  }
}

class SettingsStorage extends dbStorage
{
  constructor() {
    super("AthenaSettings",'Setting');
  }
}

/*class NameStorage extends dbStorage
{
  constructor() {
    super("AthenaNames",'Names');
  }
}*/

class FilesStorage extends dbStorage
{
  constructor() {
    super('AthenaFiles',"fileStore");
  }

  Get(key){
    //console.log("GetItem",key);
    // Start a new transaction
    var _setDB = super.setDB;
    //console.log("FilesStorage Get A");
    return new Promise((resolve, reject) => {
      const perform = () => {
        try{
    //      console.log("indexedDB",indexedDB);
          if(indexedDB === undefined){
    //        console.log("abort");
            return reject();
          }
    //      console.log("GetItem B",this.request.result);
          var db = this.request.result;
    //      console.log("GetItem C");

    //      console.log("GetItem D",this.storageName);
    			var tx = db.transaction(this.storageName, "readwrite");
        	var store = tx.objectStore(this.storageName);

          var request = store.get(key);
          request.onsuccess = () => {
    //        console.log("GetItem found");
            if(request.result !== undefined){
              var item = request.result;
    //          console.log('item',item);
              var myDate = new Date();
              myDate.setDate(myDate.getDate() + 14);
              var c = ++item.count;
              if(c > 255) c = 255;
              store.put({id: key, expire: myDate, count: c, data: item.data});
              resolve(item.data);
            }
            else reject()
            return;
          };
          request.onerror = (error) => {
    //        console.log('error',error);
            reject();
            return;
          }
    			// Close the db when the transaction is done
    	    tx.oncomplete = () => {
    //        console.log("GetItem close db");
    	      //db.close();
    	    };
    		}catch(err){
    //      console.log("err",err);
          if(err.code === 11){
            _setDB(this.dbName, this.storageName);
          }
          reject();
        }
      }
      if(!this.open){
        //DB not open yet just wait
        setTimeout(()=>{
          if(!this.open){
            reject();
            return
          }

          perform()
        },200)
        return
      }

      perform()
    });
  }

  Set(key, data){
    //console.log("File Set",key);
    if(indexedDB === undefined){
      return;
    }
    // Start a new transaction
    var db = this.request.result;
    try{
      //check data space size left and if too small flush
      let checkSpace = new Promise((resolve, reject) => {
        if('storage' in navigator){
          navigator.storage.estimate().
          then((estimate)=>{
            resolve(estimate);
          })
          .catch(()=>{
            resolve(null);
          })
        }else resolve(null);
      });

      checkSpace.then((estimate)=>{
        //console.log("estimate",estimate);
        var length = -1;
        if (data.constructor === Uint8Array) length = data.byteLength;
        if (data.constructor === Blob) length = data.size;
        if (typeof data === 'string') length = data.length;

        if(estimate !== null){
          if((estimate.quota - estimate.usage) <= length && length !== -1 ){
            //console.log("Flush",(estimate.quota - estimate.usage),data);
            this.Flush(10);
          }
        }

        var myDate = new Date();
        myDate.setDate(myDate.getDate() + 14);
        super.Put({id: key, expire: myDate, count: 0, data: data}).then(()=>{});
        //console.log("put");
      })
      .catch((e)=>{
      //  console.log("err1",e);
      })
		}catch(err){
    //  console.log("err2",err);
    }
  }

  SetWait(key, data){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return resolve(null);
      }
      // Start a new transaction
      var db = this.request.result;
      try{
        if('storage' in navigator){
          navigator.storage.estimate().
          then((estimate)=>{
            //console.log("estimate",estimate);
            var length = -1;
            if (data.constructor === Uint8Array) length = data.byteLength;
            if (data.constructor === Blob) length = data.size;
            if (typeof data === 'string') length = data.length;

            if(estimate !== null){
              if((estimate.quota - estimate.usage) <= length && length !== -1 ){
                //console.log("Flush",(estimate.quota - estimate.usage),data);
                this.Flush(10);
              }
            }

            var myDate = new Date();
            myDate.setDate(myDate.getDate() + 14);

            var db = this.request.result;
            var tx = db.transaction(this.storageName, "readwrite");
            var store = tx.objectStore(this.storageName);
            store.put({id: key, expire: myDate, count: 0, data: data});
            this.request.onsuccess = () => {
              resolve();
            };
            this.request.onerror = (error) => {
              resolve();
              return;
            }
      	    tx.oncomplete = () => {
              resolve();
      	    };
          })
          .catch(()=>{
            resolve(null);
          })
        }else{
          var myDate = new Date();
          myDate.setDate(myDate.getDate() + 14);

          var db = this.request.result;
          var tx = db.transaction(this.storageName, "readwrite");
          var store = tx.objectStore(this.storageName);
          store.put({id: key, expire: myDate, count: 0, data: data});
          this.request.onsuccess = () => {
            resolve();
          };
          this.request.onerror = (error) => {
            resolve();
            return;
          }
          tx.oncomplete = () => {
            resolve();
          };
        }
      }catch(err){
        resolve(null);
      }
    });
  }

  Flush(usage = -1){
    if(indexedDB === undefined){
      var s = localStorage.getItem(this.storageName);

      return;
    }
    //flush anything thats old
    try{
      var db = this.request.result;
      var tx = db.transaction(this.storageName, "readwrite");
      var store = tx.objectStore(this.storageName);

      var cursorRequest = store.openCursor();
      var myDate = new Date();
      cursorRequest.onsuccess = function (event){
        if (event.target.result){
          if(event.target.result.value.count <= usage ||
              event.target.result.value.expire <= myDate){
            store.delete(event.target.result.key);
          }
          event.target.result['continue']();
        }
      };
    }catch(err){
    }
  }
}

class dbBase
{
  QueryStore(boardId, userId, storename){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return resolve(false);
      }
      var db = this.request.result;
      try{
        var tx = db.transaction(storename, "readonly");
        var store = tx.objectStore(storename);

        var results = [];
        //var myIndex = store.index('binderId');
        store.openCursor().onsuccess = (event) => {
          var cursor = event.target.result;
          if(cursor) {
            if(cursor.value.boardId === boardId &&
                cursor.value.userId === userId){
              var d = {
                binderId: cursor.value.binderId,
                binderName: cursor.value.binderName,
                boardId: cursor.value.boardId,
                itemCount: cursor.value.itemCount,
                newdate: cursor.value.newdate,
              }

              results.push(d);
            }

            cursor.continue();
          }else {
            resolve(results);
          }
        };
        store.onerror = (error) => {
          resolve(false);
        }
        tx.oncomplete = () => {
        };
      }catch(err){
        resolve(false);
      }
    });
  }

  CheckStore(key, storename){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return resolve(false);
      }
      var db = this.request.result;
  		try{
  			var tx = db.transaction(storename, "readonly");
      	var store = tx.objectStore(storename);
        var request = store.count(key);
        request.onsuccess = () => {
          if(request.result === 1){
            resolve(true);
          }else resolve(false);
        };
        request.onerror = (error) => {
          resolve(false);
        }
  	    tx.oncomplete = () => {
  	    };
  		}catch(err){
        resolve(false);
      }
    });
  }

  GetStore(kUser, key, storename){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject();
      }
      var db = this.request.result;
  		try{
  			var tx = db.transaction(storename, "readonly");
      	var store = tx.objectStore(storename);

        var request = store.get(key);
        request.onsuccess = () => {
          if(request.result !== undefined){
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, kUser, request.result.key)
            .then((KTransportkey) => {
              var item = request.result.data;
              CrytpoLib.AESDecrypt(KTransportkey, item)
              .then((DataBytes) => {
                var Data = JSON.parse(CrytpoLib.arrayBufferToText(DataBytes));
                resolve(Data);
              })
              .catch((error) => {
                reject('Failed to Encrypt');
              })
            })
            .catch((error) => {
              reject("importPublicKey" + error);
            });
          }else reject()
        };
        request.onerror = (error) => {
          reject();
        }
  	    tx.oncomplete = () => {
  	    };
  		}catch(err){
        reject();
      }
    });
  }

  GetStoreNoDecrypt(key, storename){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject();
      }
  		try{
        var db = this.request.result;
  			var tx = db.transaction(storename, "readonly");
      	var store = tx.objectStore(storename);

        var request = store.get(key);
        request.onsuccess = () => {
          //TODO EDGE. unable to get data from indexed
          if(request.result !== undefined){
            resolve(request.result);
          }else reject()
        };
        request.onerror = (error) => {
//console.log("GBERROR",error);
          reject();
        }
  	    tx.oncomplete = () => {
  	    };
  		}catch(err){
//console.log("GB77",err);
        reject();
      }
    });
  }

  DeleteStoreOnlyMain(key, storename){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject();
      }
      this.GetStoreNoDecrypt(key, storename)
      .then((data)=>{
        var db = this.request.result;
        try{
          var tx = db.transaction(storename, "readwrite");
          var store = tx.objectStore(storename);

          store.delete(key);
          resolve();
        }catch(err){
//console.log("GB7",err);
          reject();
        }
      })
      .catch((e)=>{
//console.log("GB8",e);
        reject();
      })
    });
  }

  DeleteStore(key, storename){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject();
      }
      this.GetStoreNoDecrypt(key, storename)
      .then((data)=>{
        if(data.bufferIds !== undefined)
          data.bufferIds.forEach((obj) => {
            if(obj !== ""){
              this.DeleteBuffer(obj);
            }
          });
        var db = this.request.result;
        try{
          var tx = db.transaction(storename, "readwrite");
          var store = tx.objectStore(storename);
          store.delete(key);
          resolve();
        }catch(err){
          //console.log("indexeddb DeleteBinder e",err);
          reject();
        }
      })
      .catch((err)=>{
        //console.log("indexeddb DeleteBinder Catch",err);
        reject();
      })
    });
  }

}

class CachesStorage extends dbBase
{
  constructor() {
    super()
    this.dbName = 'AthenaEncrypt';
    if(indexedDB === undefined){
    }else{
      this.request = indexedDB.open(this.dbName, dbVersion);
      // Create the schema
    	this.request.onupgradeneeded = () => {
  	    var db = this.request.result;
        db.createObjectStore('minuteStore', {keyPath: "minuteId"});
        db.createObjectStore('binderStore', {keyPath: "binderId"});
        db.createObjectStore('fileStore', {keyPath: "id"});
    	};
    }
  }

  QueryBinders(boardId, userId){
    return this.QueryStore(boardId, userId, 'binderStore')
  }

  CheckBinder(key){
    return this.CheckStore(key, 'binderStore')
  }

  GetBinder(kUser, key){
    return this.GetStore(kUser, key, 'binderStore')
  }

  GetBinderNoDecrypt(key){
    return this.GetStoreNoDecrypt(key, 'binderStore');
  }

  SetBinderNoDecrypt(key, data){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject('No indexedDB');
      }
      var db = this.request.result;
      try{
        //check data space size left and if too small flush
        let checkSpace = new Promise((resolveEst, rejectEst) => {
          if('storage' in navigator){
            navigator.storage.estimate().
            then((estimate)=>{
              resolveEst(estimate);
            })
            .catch(()=>{
              rejectEst(null);
            })
          }else rejectEst(null);
        });

        checkSpace.then((estimate)=>{
          var db = this.request.result;
          try{
            var StringData = CrytpoLib.textToArrayBuffer(JSON.stringify(data));

            if(estimate !== null){
              if((estimate.quota - estimate.usage) <= StringData.length && StringData.length !== -1 ){
                //console.log("Flush",(estimate.quota - estimate.usage),data);
                reject('No Space');
              }
            }

            //Get all image and item cache IDs
            var tx = db.transaction('binderStore', "readwrite");
            var store = tx.objectStore('binderStore');

            var myDate = new Date();
            myDate.setDate(myDate.getDate() + 14);

            var addToStore = store.put({
              binderId:key,
              binderName: data.binderName,
              boardId: data.boardId,
              bufferIds: data.bufferIds,
              data: data.data,
              expire: myDate,
              itemCount: data.itemCount,
              key: data.key,
              newdate: data.newdate,
              userId: data.userId,
            });

            addToStore.onsuccess = () => {
              resolve();
            }

            addToStore.onerror = (error) => {
//console.log("ee1",error)
              reject();
            }
      		}catch(error){
//console.log("ee2",error)
            reject('Failed to Save');
          }
        })
        .catch((error)=>{
//console.log("ee3",error)
          reject('Failed to Save');
        //  console.log("err1",e);
        })
  		}catch(error){
//console.log("ee4",error)
        reject('Failed to Save');
      //  console.log("err2",err);
      }
    });
  }

  SetBinder(userKey, key, data){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject('No indexedDB');
      }
      var db = this.request.result;
      try{
        //check data space size left and if too small flush
        let checkSpace = new Promise((resolveEst, rejectEst) => {
          if('storage' in navigator){
            navigator.storage.estimate().
            then((estimate)=>{
              resolveEst(estimate);
            })
            .catch((e)=>{
              rejectEst(null); //Edge has problem opening drafts
            })
          }else rejectEst(null);
        });
        checkSpace.then((estimate)=>{
          var db = this.request.result;
          try{
            var KTransportkey = CrytpoLib.GenerateRandom(32);

            var StringData = CrytpoLib.textToArrayBuffer(JSON.stringify(data));

            if(estimate !== null){
              if((estimate.quota - estimate.usage) <= StringData.length && StringData.length !== -1 ){
                //console.log("Flush",(estimate.quota - estimate.usage),data);
                reject('No Space');
              }
            }

            //Get all image and item cache IDs
            var BufferIds = [];
            var ListItem = data.ListItem;
            ListItem.forEach((o) => {
              if(o.itemdataId !== ""){
                BufferIds.push(o.itemdataId);
              }
              if(o.thumbImageId !== ""){
                BufferIds.push(o.thumbImageId);
              }
            });

            CrytpoLib.importPublicKey(userKey, CrytpoLib.defaultRSAAlgorithmMethod)
            .then((iUserKey) => {
              CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, KTransportkey)
              .then((KAESEncpyt) => {
                CrytpoLib.AESEncrypt(KTransportkey, StringData)
                .then((DataEnc) => {
                  var tx = db.transaction('binderStore', "readwrite");
                  var store = tx.objectStore('binderStore');

                  var myDate = new Date();
                  myDate.setDate(myDate.getDate() + 14);

                  var addToStore = store.put({
                    binderId:key,
                    data:DataEnc,
                    boardId: data.boardId,
                    binderName: data.binderName,
                    newdate: data.showNewDate?data.newdate:null,
                    itemCount: data.itemIds.length,
                    expire: myDate,
                    bufferIds: BufferIds,
                    userId: data.userId,
                    key: KAESEncpyt,
                  });

                  addToStore.onsuccess = () => {
                    resolve();
                  }

                  addToStore.onerror = (error) => {
                    console.log("SetBinder Error 1", error);
                    reject();
                  }
                })
                .catch((error) => {
                  console.log("SetBinder Error 2", error);
                  reject('Failed to Encrypt');
                })
              })
              .catch((error) => {
                console.log("SetBinder Error 3", error);
                reject("RSAEncrypt" + error);
              })
            })
            .catch((error) => {
              console.log("SetBinder Error 4", error);
              reject("importPublicKey" + error);
            });
      		}catch(err){
            console.log("SetBinder Error 5", err);
            reject('Failed to Save');
          }
        })
        .catch((e)=>{
          console.log("SetBinder Error 6", e);
          reject('Failed to Save');
        //  console.log("err1",e);
        })
  		}catch(err){
        console.log("SetBinder Error 7", err);
        reject('Failed to Save');
      //  console.log("err2",err);
      }
    });
  }

  DeleteBinderOnlyMain(key){
    return this.DeleteStoreOnlyMain(key, 'binderStore');
  }

  DeleteBinder(key){
    return this.DeleteStore(key, 'binderStore');
  }

  DeleteBuffer(key){
    //Remove value
    var db = this.request.result;
    try{
      var tx = db.transaction('fileStore', "readwrite");
      var store = tx.objectStore('fileStore');

      store.delete(key);
    }catch(err){

    }
  }

  GetBuffer(kUser, key, returnArray = false){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject();
      }
      var db = this.request.result;
      try{
        var tx = db.transaction('fileStore', "readonly");
        var store = tx.objectStore('fileStore');

        var request = store.get(key);
        request.onsuccess = () => {
          if(request.result !== undefined){
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, kUser, request.result.key)
            .then((KTransportkey) => {
              var item = request.result.data;
              CrytpoLib.AESDecrypt(KTransportkey, item)
              .then((DataBytes) => {
                if(!returnArray){
                  var blob = new Blob([DataBytes]);
                  resolve(blob);
                }else{
                  resolve(DataBytes);
                }
              })
              .catch((error) => {
                reject('Failed to Encrypt');
              })
            })
            .catch((error) => {
              reject("importPublicKey" + error);
            });
          }else reject()
        };
        request.onerror = (error) => {
          reject();
        }
        tx.oncomplete = () => {
        };
      }catch(err){
        reject();
      }
    });
  }

  SetBuffer(userKey, key, data){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject('No indexedDB');
      }
      var db = this.request.result;
      try{
        //check data space size left and if too small flush
        let checkSpace = new Promise((resolveEst, rejectEst) => {
          if('storage' in navigator){
            navigator.storage.estimate().
            then((estimate)=>{
              resolveEst(estimate);
            })
            .catch(()=>{
              rejectEst(null);//Edge has problem opening drafts
            })
          }else rejectEst(null);
        });

        checkSpace.then((estimate)=>{
          var db = this.request.result;
          try{
            var KTransportkey = CrytpoLib.GenerateRandom(32);

            if(estimate !== null){
              if((estimate.quota - estimate.usage) <= data.length ){
                //console.log("Flush",(estimate.quota - estimate.usage),data);
                reject('No Space');
              }
            }

            CrytpoLib.importPublicKey(userKey, CrytpoLib.defaultRSAAlgorithmMethod)
            .then((iUserKey) => {
              CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, KTransportkey)
              .then((KAESEncpyt) => {
                CrytpoLib.AESEncrypt(KTransportkey, data)
                .then((DataEnc) => {
                  var tx = db.transaction('fileStore', "readwrite");
                  var store = tx.objectStore('fileStore');

                  var myDate = new Date();
                  myDate.setDate(myDate.getDate() + 14);

                  var addToStore = store.put({
                    id:key,
                    data:DataEnc,
                    expire: myDate,
                    key: KAESEncpyt,
                  });

                  addToStore.onsuccess = () => {
                    resolve();
                  }

                  addToStore.onerror = (error) => {
                    reject();
                  }
                })
                .catch((error) => {
                  reject('Failed to Encrypt');
                })
              })
              .catch((error) => {
                reject("RSAEncrypt" + error);
              })
            })
            .catch((error) => {
              reject("importPublicKey" + error);
            });
      		}catch(err){
            reject('Failed to Save');
          }
        })
        .catch((e)=>{
          reject('Failed to Save');
        //  console.log("err1",e);
        })
  		}catch(err){
        reject('Failed to Save');
      //  console.log("err2",err);
      }
    });
  }

  flushBuffer(){
    try{
      var db = this.request.result;
      var tx = db.transaction('fileStore', "readwrite");
      var store = tx.objectStore('fileStore');
      var cursorRequest = store.openCursor();
      var myDate = new Date();
      cursorRequest.onsuccess = function (event){
        //remove anything pass the expiry date
        if (event.target.result){
          if(event.target.result.value.expire <= myDate){
            store.delete(event.target.result.key);
          }
          event.target.result['continue']();
        }
      };
    }catch(err){

    }
  }

  flush(){
    //Remove value
    if(indexedDB === undefined){
      return;
    }
    try{
      var db = this.request.result;
      var tx = db.transaction('binderStore', "readwrite");
      var store = tx.objectStore('binderStore');

      var countRequest = store.count();
      countRequest.onsuccess = () => {
        if(countRequest.result === 0){
          //if no binder cache then just clear it all
          var tx = db.transaction('fileStore', "readwrite");
          var store = tx.objectStore('fileStore');

          store.clear();
        }else{
          var tx = db.transaction('binderStore', "readwrite");
          var store = tx.objectStore('binderStore');

          var cursorRequest = store.openCursor();
          var myDate = new Date();

          cursorRequest.onsuccess = (event) => {
            //remove anything pass the expiry date
            if (event.target.result){
              if(event.target.result.value.expire <= myDate){
                store.delete(event.target.result.key);
              }
              event.target.result['continue']();
            }else{
              // All done
              this.flushBuffer();
            }
          };
        }
      }
    }catch(err){
      //console.log("flush error",err);
    }
  }
}

class MinutesStorage extends dbBase
{
  constructor() {
    super()
    this.dbName = 'AthenaEncrypt';
    if(indexedDB === undefined){
    }else{
      this.request = indexedDB.open(this.dbName, dbVersion);
      // Create the schema
    	this.request.onupgradeneeded = () => {
  	    var db = this.request.result;
        db.createObjectStore('minuteStore', {keyPath: "minuteId"});
        db.createObjectStore('binderStore', {keyPath: "binderId"});
        db.createObjectStore('fileStore', {keyPath: "id"});
      };
    }
  }

  QueryMinutes(boardId, userId){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return resolve(false);
      }
      var db = this.request.result;
      try{
        var tx = db.transaction('minuteStore', "readonly");
        var store = tx.objectStore('minuteStore');

        var results = [];
        //var myIndex = store.index('binderId');
        store.openCursor().onsuccess = (event) => {
          var cursor = event.target.result;
          if(cursor) {
            if(cursor.value.boardId === boardId &&
                cursor.value.userId === userId){
              var d = {
                minutesId: cursor.value.minuteId,
                minuteName: cursor.value.minuteName,
                boardId: cursor.value.boardId,
                itemCount: cursor.value.itemCount,
                newdate: cursor.value.newdate,
              }

              results.push(d);
            }

            cursor.continue();
          }else {
            resolve(results);
          }
        };
        store.onerror = (error) => {
          resolve(false);
        }
        tx.oncomplete = () => {
        };
      }catch(err){
        resolve(false);
      }
    });
  }

  CheckMinute(key){
    return this.CheckStore(key, 'minuteStore')
  }

  GetMinute(kUser, key){
    return this.GetStore(kUser, key, 'minuteStore')
  }

  GetMinuteNoDecrypt(key){
    return this.GetStoreNoDecrypt(key, 'minuteStore');
  }

  SetMinuteNoDecrypt(key, data){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject('No indexedDB');
      }
      var db = this.request.result;
      try{
        //check data space size left and if too small flush
        let checkSpace = new Promise((resolveEst, rejectEst) => {
          if('storage' in navigator){
            navigator.storage.estimate().
            then((estimate)=>{
              resolveEst(estimate);
            })
            .catch(()=>{
              rejectEst(null);
            })
          }else rejectEst(null);
        });

        checkSpace.then((estimate)=>{
          var db = this.request.result;
          try{
            var StringData = CrytpoLib.textToArrayBuffer(JSON.stringify(data));

            if(estimate !== null){
              if((estimate.quota - estimate.usage) <= StringData.length && StringData.length !== -1 ){
                //console.log("Flush",(estimate.quota - estimate.usage),data);
                reject('No Space');
              }
            }

            //Get all image and item cache IDs
            var tx = db.transaction('minuteStore', "readwrite");
            var store = tx.objectStore('minuteStore');

            var myDate = new Date();
            myDate.setDate(myDate.getDate() + 14);

            var addToStore = store.put({
              minuteId:key,
              minuteName: data.minuteName,
              boardId: data.boardId,
              bufferIds: data.bufferIds,
              data: data.data,
              expire: myDate,
              itemCount: data.itemCount,
              key: data.key,
              newdate: data.newdate,
              userId: data.userId,
            });

            addToStore.onsuccess = () => {
              resolve();
            }

            addToStore.onerror = (error) => {
              reject();
            }
      		}catch(err){
            reject('Failed to Save');
          }
        })
        .catch((e)=>{
          reject('Failed to Save');
        //  console.log("err1",e);
        })
  		}catch(err){
        reject('Failed to Save');
      //  console.log("err2",err);
      }
    });
  }

  SetMinute(userKey, key, data){
    return new Promise((resolve, reject) => {
      if(indexedDB === undefined){
        return reject('No indexedDB');
      }
      var db = this.request.result;
      try{
        //check data space size left and if too small flush
        let checkSpace = new Promise((resolveEst, rejectEst) => {
          if('storage' in navigator){
            navigator.storage.estimate().
            then((estimate)=>{
              resolveEst(estimate);
            })
            .catch(()=>{
              rejectEst(null); //Edge has problem opening drafts
            })
          }else rejectEst(null);
        });
        checkSpace.then((estimate)=>{
          var db = this.request.result;
          try{
            var KTransportkey = CrytpoLib.GenerateRandom(32);

            var StringData = CrytpoLib.textToArrayBuffer(JSON.stringify(data));

            if(estimate !== null){
              if((estimate.quota - estimate.usage) <= StringData.length && StringData.length !== -1 ){
                //console.log("Flush",(estimate.quota - estimate.usage),data);
                reject('No Space');
              }
            }

            CrytpoLib.importPublicKey(userKey, CrytpoLib.defaultRSAAlgorithmMethod)
            .then((iUserKey) => {
              CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, KTransportkey)
              .then((KAESEncpyt) => {
                CrytpoLib.AESEncrypt(KTransportkey, StringData)
                .then((DataEnc) => {
                  var tx = db.transaction('minuteStore', "readwrite");
                  var store = tx.objectStore('minuteStore');

                  var myDate = new Date();
                  myDate.setDate(myDate.getDate() + 14);

                  var addToStore = store.put({
                    minuteId:key,
                    data: DataEnc,
                    boardId: data.boardId,
                    minuteName: data.minuteName,
                    newdate: data.showNewDate?data.newdate:null,
                    itemCount: data.ListItem.length,
                    expire: myDate,
                    bufferIds: [],
                    userId: data.userId,
                    key: KAESEncpyt,
                  });

                  addToStore.onsuccess = () => {
                    resolve();
                  }

                  addToStore.onerror = (error) => {
                    console.log("SetMinutes Error 1", error);
                    reject();
                  }
                })
                .catch((error) => {
                  console.log("SetMinutes Error 2", error);
                  reject('Failed to Encrypt');
                })
              })
              .catch((error) => {
                console.log("SetMinutes Error 3", error);
                reject("RSAEncrypt" + error);
              })
            })
            .catch((error) => {
              console.log("SetMinutes Error 4", error);
              reject("importPublicKey" + error);
            });
      		}catch(err){
            console.log("SetMinutes Error 5", err);
            reject('Failed to Save');
          }
        })
        .catch((e)=>{
          console.log("SetMinutes Error 6", e);
          reject('Failed to Save');
        //  console.log("err1",e);
        })
  		}catch(err){
        console.log("SetMinutes Error 7", err);
        reject('Failed to Save');
      //  console.log("err2",err);
      }
    });
  }

  DeleteMinuteOnlyMain(key){
    return this.DeleteStoreOnlyMain(key, 'minuteStore');
  }

  DeleteMinute(key){
    return this.DeleteStore(key, 'minuteStore');
  }

  flush(){
    //Remove value
    if(indexedDB === undefined){
      return;
    }
    try{
      var db = this.request.result;
      var tx = db.transaction('binderStore', "readwrite");
      var store = tx.objectStore('binderStore');

      var countRequest = store.count();
      countRequest.onsuccess = () => {
        if(countRequest.result === 0){
        }else{
          var tx = db.transaction('binderStore', "readwrite");
          var store = tx.objectStore('binderStore');

          var cursorRequest = store.openCursor();
          var myDate = new Date();

          cursorRequest.onsuccess = function (event){
            //remove anything pass the expiry date
            if (event.target.result){
              if(event.target.result.value.expire <= myDate){
                store.delete(event.target.result.key);
              }
              event.target.result['continue']();
            }
          };
        }
      }
    }catch(err){
    //  console.log("flush error",err);
    }
  }
}

export let keysStorage = new KeyStorage();
export let SettingStorage = new SettingsStorage();
export let FileStorage = new FilesStorage();
export let CacheStorage = new CachesStorage();
export let MinuteStorage = new MinutesStorage();
