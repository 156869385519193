export const binderConstants = {
  GET_BINDERCONTENT_REQUEST: 'GET_BINDERCONTENT_REQUEST',
  GET_BINDERCONTENT_SUCCESS: 'GET_BINDERCONTENT_SUCCESS',
  GET_BINDERCONTENT_FAILURE: 'GET_BINDERCONTENT_FAILURE',

  POPULATE_BINDERCONTENT_REQUEST: 'POPULATE_BINDERCONTENT_REQUEST',
  POPULATE_BINDERCONTENT_SUCCESS: 'POPULATE_BINDERCONTENT_SUCCESS',
  POPULATE_BINDERCONTENT_UPDATE: 'POPULATE_BINDERCONTENT_UPDATE',
  POPULATE_BINDERCONTENT_PROGRESS: 'POPULATE_BINDERCONTENT_PROGRESS',
  POPULATE_BINDERCONTENT_FAILURE: 'POPULATE_BINDERCONTENT_FAILURE',

  POPULATE_USERBINDERCONTENT_REQUEST: 'POPULATE_USERBINDERCONTENT_REQUEST',
  POPULATE_USERBINDERCONTENT_SUCCESS: 'POPULATE_USERBINDERCONTENT_SUCCESS',
  POPULATE_USERBINDERCONTENT_FAILURE: 'POPULATE_USERBINDERCONTENT_FAILURE',

  GET_TEMPLATECONTENT_REQUEST: 'GET_TEMPLATECONTENT_REQUEST',
  GET_TEMPLATECONTENT_SUCCESS: 'GET_TEMPLATECONTENT_SUCCESS',
  GET_TEMPLATECONTENT_FAILURE: 'GET_TEMPLATECONTENT_FAILURE',

  POPULATE_TEMPLATECONTENT_REQUEST: 'POPULATE_TEMPLATECONTENT_REQUEST',
  POPULATE_TEMPLATECONTENT_SUCCESS: 'POPULATE_TEMPLATECONTENT_SUCCESS',
  POPULATE_TEMPLATECONTENT_FAILURE: 'POPULATE_TEMPLATECONTENT_FAILURE',

  NEW_TEMPLATECONTENT_REQUEST: 'NEW_TEMPLATECONTENT_REQUEST',
  NEW_TEMPLATECONTENT_SUCCESS: 'NEW_TEMPLATECONTENT_SUCCESS',
  NEW_TEMPLATECONTENT_FAILURE: 'NEW_TEMPLATECONTENT_FAILURE',

  UPDATE_TEMPLATECONTENT_REQUEST: 'UPDATE_TEMPLATECONTENT_REQUEST',
  UPDATE_TEMPLATECONTENT_SUCCESS: 'UPDATE_TEMPLATECONTENT_SUCCESS',
  UPDATE_TEMPLATECONTENT_FAILURE: 'UPDATE_TEMPLATECONTENT_FAILURE',

  UPDATE_TEMPLATEINFOSTATUS_REQUEST: 'UPDATE_TEMPLATEINFOSTATUS_REQUEST',
  UPDATE_TEMPLATEINFOSTATUS_SUCCESS: 'UPDATE_TEMPLATEINFOSTATUS_SUCCESS',
  UPDATE_TEMPLATEINFOSTATUS_FAILURE: 'UPDATE_TEMPLATEINFOSTATUS_FAILURE',

  DELETE_TEMPLATECONTENT_REQUEST: 'DELETE_TEMPLATECONTENT_REQUEST',
  DELETE_TEMPLATECONTENT_SUCCESS: 'DELETE_TEMPLATECONTENT_SUCCESS',
  DELETE_TEMPLATECONTENT_FAILURE: 'DELETE_TEMPLATECONTENT_FAILURE',

  DELETE_BINDERCONTENT_REQUEST: 'DELETE_BINDERCONTENT_REQUEST',
  DELETE_BINDERCONTENT_SUCCESS: 'DELETE_BINDERCONTENT_SUCCESS',
  DELETE_BINDERCONTENT_FAILURE: 'DELETE_BINDERCONTENT_FAILURE',

  DELETE_ATTENDEE_SUCCESS: 'DELETE_RECIPIENT_SUCCESS',
  DELETE_RECIPIENT_SUCCESS: 'DELETE_RECIPIENT_SUCCESS',

  GET_BINDERSTATUS_REQUEST: 'GET_BINDERSTATUS_REQUEST',
  GET_BINDERSTATUS_SUCCESS: 'GET_BINDERSTATUS_SUCCESS',
  GET_BINDERSTATUS_FAILURE: 'GET_BINDERSTATUS_FAILURE',

  UPDATE_BINDERIMAGE_REQUEST: 'UPDATE_BINDERIMAGE_REQUEST',
  UPDATE_BINDERIMAGE_SUCCESS: 'UPDATE_BINDERIMAGE_SUCCESS',
  UPDATE_BINDERIMAGE_FAILURE: 'UPDATE_BINDERIMAGE_FAILURE',

  REMOVE_BINDER_IMAGE: 'REMOVE_BINDER_IMAGE',

  UPDATE_BINDERSTATUS_DETAIL_REQUEST: 'UPDATE_BINDERSTATUS_DETAIL_REQUEST',
  UPDATE_BINDERSTATUS_REQUEST: 'UPDATE_BINDERSTATUS_REQUEST',
  UPDATE_BINDERSTATUS_SUCCESS: 'UPDATE_BINDERSTATUS_SUCCESS',
  UPDATE_BINDERSTATUS_SUCCESS_DONOTHING: 'UPDATE_BINDERSTATUS_SUCCESS_DONOTHING',
  UPDATE_BINDERSTATUS_SUCCESS_MOVE: 'UPDATE_BINDERSTATUS_SUCCESS_MOVE',
  UPDATE_BINDERSTATUS_FAILURE: 'UPDATE_BINDERSTATUS_FAILURE',

  UPDATE_BINDERDASHSTATUS_REQUEST: 'UPDATE_BINDERDASHSTATUS_REQUEST',
  UPDATE_BINDERDASHSTATUS_SUCCESS: 'UPDATE_BINDERDASHSTATUS_SUCCESS',
  UPDATE_BINDERDASHSTATUS_FAILURE: 'UPDATE_BINDERDASHSTATUS_FAILURE',

  UPDATE_BINDERINFOSTATUS_REQUEST: 'UPDATE_BINDERINFOSTATUS_REQUEST',
  UPDATE_BINDERINFOSTATUS_SUCCESS: 'UPDATE_BINDERINFOSTATUS_SUCCESS',
  UPDATE_BINDERINFOSTATUS_FAILURE: 'UPDATE_BINDERINFOSTATUS_FAILURE',

  NEW_BINDERSTATUS_REQUEST: 'NEW_BINDERSTATUS_REQUEST',
  NEW_BINDERSTATUS_SUCCESS: 'NEW_BINDERSTATUS_SUCCESS',
  NEW_BINDERSTATUS_FAILURE: 'NEW_BINDERSTATUS_FAILURE',

  GET_ITEM_REQUEST: 'GET_ITEM_REQUEST',
  GET_ITEM_SUCCESS: 'GET_ITEM_SUCCESS',
  GET_ITEM_FAILURE: 'GET_ITEM_FAILURE',

  DELETE_ITEM_REQUEST: 'DELETE_ITEM_REQUEST',
  DELETE_ITEM_SUCCESS: 'DELETE_ITEM_SUCCESS',
  DELETE_ITEM_FAILURE: 'DELETE_ITEM_FAILURE',

  ADD_VOTEANSWERS_REQUEST: 'ADD_VOTEANSWERS_REQUEST',
  ADD_VOTEANSWERS_SUCCESS: 'ADD_VOTEANSWERS_SUCCESS',
  ADD_VOTEANSWERS_FAILURE: 'ADD_VOTEANSWERS_FAILURE',

  UPDATE_STATS_BINDER_REQUEST: 'UPDATE_STATS_BINDER_REQUEST',
  UPDATE_STATS_TEMPLATE_REQUEST: 'UPDATE_STATS_TEMPLATE_REQUEST',

  COMPLETE_UPLOAD_BINDER_REQUEST: 'COMPLETE_UPLOAD_BINDER_REQUEST',

  SET_DOCUMENT_ERROR_REQUEST: 'SET_DOCUMENT_ERROR_REQUEST',
  //SET_BINDER_ERROR_REQUEST: 'SET_BINDER_ERROR_REQUEST',
  COMPLETE_DOWNLOAD_DOCUMENT_REQUEST: 'COMPLETE_DOWNLOAD_DOCUMENT_REQUEST',

  WORKER_BINDER_UPDATE_REQUEST: 'WORKER_BINDER_UPDATE_REQUEST',
  WORKER_BINDER_UPDATE_SUCCESS: 'WORKER_BINDER_UPDATE_SUCCESS',
  WORKER_BINDER_UPDATE_FAILURE: 'WORKER_BINDER_UPDATE_FAILURE',

  COPY_BINDERCONTENT_REQUEST: 'COPY_BINDERCONTENT_REQUEST',
  COPY_BINDERCONTENT_SUCCESS: 'COPY_BINDERCONTENT_SUCCESS',
  COPY_BINDERCONTENT_FAILURE: 'COPY_BINDERCONTENT_FAILURE',

  GET_BINDERUSAGE_REQUEST: 'GET_BINDERUSAGE_REQUEST',
  GET_BINDERUSAGE_SUCCESS: 'GET_BINDERUSAGE_SUCCESS',
  GET_BINDERUSAGE_FAILURE: 'GET_BINDERUSAGE_FAILURE',

  GET_BINDERCACHED_SUCCESS: 'GET_BINDERCACHED_SUCCESS',
  DELETE_BINDERCACHED_SUCCESS: 'DELETE_BINDERCACHED_SUCCESS',
  SAVE_BINDERCACHED_REQUEST: 'SAVE_BINDERCACHED_REQUEST',
  SAVE_BINDERCACHED_SUCCESS: 'SAVE_BINDERCACHED_SUCCESS',
  RENAME_BINDERCACHED_SUCCESS: 'RENAME_BINDERCACHED_SUCCESS',
  FLUSH_BINDERCACHED_SUCCESS: 'FLUSH_BINDERCACHED_SUCCESS',

  SAVE_BINDERTRANSACTIONEVENT_SUCCESS: 'SAVE_BINDERTRANSACTIONEVENT_SUCCESS',
  GET_BINDERTRANSACTIONEVENT_SUCCESS: 'GET_BINDERTRANSACTIONEVENT_SUCCESS',
  DELETE_BINDERTRANSACTION_SUCCESS: 'DELETE_BINDERTRANSACTION_SUCCESS',
  SAVE_BINDERTRANSACTIONEVENT_REQUEST: 'SAVE_BINDERTRANSACTIONEVENT_REQUEST',
  SAVE_BINDERTRANSACTIONEVENT_FAILURE: 'SAVE_BINDERTRANSACTIONEVENT_FAILURE',

  CLEAR_BINDERTRANSACTIONEVENT_REQUEST: 'CLEAR_BINDERTRANSACTIONEVENT_REQUEST',
  CLEAR_BINDERTRANSACTIONEVENT_SUCCESS: 'CLEAR_BINDERTRANSACTIONEVENT_SUCCESS',
  CLEAR_BINDERTRANSACTIONEVENT_FAILURE: 'CLEAR_BINDERTRANSACTIONEVENT_FAILURE',

  RESOLUTION_DOWNLOAD_FAILURE: 'RESOLUTION_DOWNLOAD_FAILURE',
  RESOLUTION_DOWNLOAD_REQUEST: 'RESOLUTION_DOWNLOAD_REQUEST',

  COPY_DOCUMENTS_REQUEST: 'COPY_DOCUMENTS_REQUEST',
  COPY_DOCUMENTS_SUCCESS: 'COPY_DOCUMENTS_SUCCESS',
  COPY_DOCUMENTS_FAILURE: 'COPY_DOCUMENTS_FAILURE',

  BINDER_WEBSOCKET_LOCK: 'BINDER_WEBSOCKET_LOCK',

  CLEAR_ERROR_SUCCESS: 'CLEAR_ERROR_SUCCESS',

  CLEAR_BINDER_MOVE_DATE: 'CLEAR_BINDER_MOVE_DATE',

  BINDER_SETTINGS_DELETE: 'BINDER_SETTINGS_DELETE',
  BINDER_SETTINGS_UPDATE: 'BINDER_SETTINGS_UPDATE',
};
