import { webConstants as baseConstants } from '../web.constants'

const constants = {
  ADD_FILE: 'ADD_FILE',
  ADD_FILES: 'ADD_FILES',
  ADD_DOCUMENT: 'ADD_DOCUMENT',
  ADD_BINDER: 'ADD_BINDER',
  ADD_TEMPLATE: 'ADD_TEMPLATE',
  PUBLISH_BINDER: 'PUBLISH_BINDER',
  ADD_MINUTES: 'ADD_MINUTES',

  PROCESS_BINDER_IMAGE: 'PROCESS_BINDER_IMAGE',
  PROCESS_BINDER2_IMAGE: 'PROCESS_BINDER2_IMAGE',
  UPDATE_BINDER_IMAGE: 'UPDATE_BINDER_IMAGE',
  UPDATE_BINDER_DETAILS: 'UPDATE_BINDER_DETAILS',

  GET_SESSION_TOKEN: 'GET_SESSION_TOKEN',
};

export const webConstants = Object.assign(constants, baseConstants)
