export const minutesConstants = {
  GET_MINUTES_REQUEST: 'GET_MINUTES_REQUEST',
  GET_MINUTES_SUCCESS: 'GET_MINUTES_SUCCESS',
  GET_MINUTES_FAILURE: 'GET_MINUTES_FAILURE',

  SET_MINUTES_REQUEST: 'SET_MINUTES_REQUEST',
  SET_MINUTES_SUCCESS: 'SET_MINUTES_SUCCESS',
  SET_MINUTES_FAILURE: 'SET_MINUTES_FAILURE',

  PUBLISH_MINUTES_REQUEST: 'PUBLISH_MINUTES_REQUEST',
  PUBLISH_MINUTES_SUCCESS: 'PUBLISH_MINUTES_SUCCESS',
  PUBLISH_MINUTES_FAILURE: 'PUBLISH_MINUTES_FAILURE',

  SAVE_MINUTECACHED_REQUEST: 'SAVE_MINUTECACHED_REQUEST',
  SAVE_MINUTECACHED_SUCCESS: 'SAVE_MINUTECACHED_SUCCESS',

  GET_MINUTESCACHED_SUCCESS: 'GET_MINUTESCACHED_SUCCESS',

  DELETE_MINUTECACHED_SUCCESS: 'DELETE_MINUTECACHED_SUCCESS',

  DELETE_MINUTECONTENT_REQUEST: 'DELETE_MINUTECONTENT_REQUEST',
  DELETE_MINUTECONTENT_SUCCESS: 'DELETE_MINUTECONTENT_SUCCESS',
  DELETE_MINUTECONTENT_FAILURE: 'DELETE_MINUTECONTENT_FAILURE',

  COPY_BINDERFORMINTUES_REQUEST: 'COPY_BINDERFORMINTUES_REQUEST',
  COPY_BINDERFORMINTUES_SUCCESS: 'COPY_BINDERFORMINTUES_SUCCESS',
  COPY_BINDERFORMINTUES_FAILURE: 'COPY_BINDERFORMINTUES_FAILURE',

  MINUTES_WEBSOCKET_LOCK: 'MINUTES_WEBSOCKET_LOCK',

  EXPORT_MINTUES_REQUEST: 'EXPORT_MINTUES_REQUEST',
  EXPORT_MINTUES_SUCCESS: 'EXPORT_MINTUES_SUCCESS',
  EXPORT_MINTUES_FAILURE: 'EXPORT_MINTUES_FAILURE',
}
