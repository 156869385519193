import { userConstants as baseConstants } from '../user.constants'

const constants = {
  CLEAR_AUTH_CODE_REQUEST: 'CLEAR_AUTH_CODE_REQUEST',

  LOGIN_CLEAR: 'LOGIN_CLEAR',
  LOGIN_CLEAR_ERROR: 'LOGIN_CLEAR_ERROR',
  LOGIN_CHECK: 'USERS_LOGIN_CHECK',

  REGISTER_USERANDDEVICE_REQUEST: 'REGISTER_USERANDDEVICE_REQUEST',
  REGISTER_USERANDDEVICE_SUCCESS: 'REGISTER_USERANDDEVICE_SUCCESS',
  REGISTER_USERANDDEVICE_FAILURE: 'REGISTER_USERANDDEVICE_FAILURE',

  REGISTER_USERDEVICEKEY_REQUEST: 'USERDEVICEKEY_REGISTER_REQUEST',
  REGISTER_USERDEVICEKEY_SUCCESS: 'USERDEVICEKEY_REGISTER_SUCCESS',
  REGISTER_USERDEVICEKEY_FAILURE: 'USERDEVICEKEY_REGISTER_FAILURE',

  REGISTER_NEW_USER_REQUEST: 'REGISTER_NEW_USER_REQUEST',
  REGISTER_NEW_USER_SUCCESS: 'REGISTER_NEW_USER_SUCCESS',
  REGISTER_NEW_USER_FAILURE: 'REGISTER_NEW_USER_FAILURE',
  REGISTER_NEW_USER_CLEAR: 'REGISTER_NEW_USER_CLEAR',

  FORGOT_NOCODE_LOGIN_REQUEST: 'FORGOT_NOCODE_LOGIN_REQUEST',

  FORGOT_NOCODE_NOTFY_REQUEST: 'FORGOT_NOCODE_NOTFY_REQUEST',
  FORGOT_NOCODE_NOTFY_SUCCESS: 'FORGOT_NOCODE_NOTFY_SUCCESS',
  FORGOT_NOCODE_NOTFY_FAILURE: 'FORGOT_NOCODE_NOTFY_FAILURE',

  FORGOT_NOCODE_CODE_REQUEST: 'FORGOT_NOCODE_CODE_REQUEST',
  FORGOT_NOCODE_CODE_SUCCESS: 'FORGOT_NOCODE_CODE_SUCCESS',
  FORGOT_NOCODE_CODE_FAILURE: 'FORGOT_NOCODE_CODE_FAILURE',

  FORGOT_NOCODE_NEWPASS_REQUEST: 'FORGOT_NOCODE_NEWPASS_REQUEST',
  FORGOT_NOCODE_NEWPASS_SUCCESS: 'FORGOT_NOCODE_NEWPASS_SUCCESS',
  FORGOT_NOCODE_NEWPASS_FAILURE: 'FORGOT_NOCODE_NEWPASS_FAILURE',

  FORGOT_CARD_NOTFY_REQUEST: 'FORGOT_CARD_NOTFY_REQUEST',
  FORGOT_CARD_NOTFY_SUCCESS: 'FORGOT_CARD_NOTFY_SUCCESS',
  FORGOT_CARD_NOTFY_FAILURE: 'FORGOT_CARD_NOTFY_FAILURE',

  FORGOT_CARD_NEWPASS_REQUEST: 'FORGOT_CARD_NEWPASS_REQUEST',
  FORGOT_CARD_NEWPASS_SUCCESS: 'FORGOT_CARD_NEWPASS_SUCCESS',
  FORGOT_CARD_NEWPASS_FAILURE: 'FORGOT_CARD_NEWPASS_FAILURE',

  AUTHZERO_RESET_REQUEST: 'AUTHZERO_RESET_REQUEST',
  AUTHZERO_RESET_SUCCESS: 'AUTHZERO_RESET_SUCCESS',
  AUTHZERO_RESET_FAILURE: 'AUTHZERO_RESET_FAILURE',

  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  REGISTER_DEVICE_REQUEST: 'DEVICE_REGISTER_REQUEST',
  REGISTER_DEVICE_SUCCESS: 'DEVICE_REGISTER_SUCCESS',
  REGISTER_DEVICE_FAILURE: 'DEVICE_REGISTER_FAILURE',

  REGISTER_USERDEVICE_POLICY_SUCCESS: 'REGISTER_USERDEVICE_POLICY_SUCCESS',

  REGISTER_RECOVERYCARD_REQUEST: 'REGISTER_RECOVERYCARD_REQUEST',
  REGISTER_RECOVERYCARD_SUCCESS: 'REGISTER_RECOVERYCARD_SUCCESS',
  REGISTER_RECOVERYCARD_FAILURE: 'REGISTER_RECOVERYCARD_FAILURE',

  LOGIN_WEBSOCKET_SUCCESS: 'LOGIN_WEBSOCKET_SUCCESS',
  LOGIN_WEBSOCKET_FAILURE: 'LOGIN_WEBSOCKET_FAILURE',

  LOGIN_LOCK_REQUEST: 'USERS_LOCK_LOGIN_REQUEST',
  LOGIN_LOCK_FAILURE: 'USERS_LOCK_LOGIN_FAILURE',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DEMO_ADDUSER: 'DEMO_ADDUSER',

  GETLOGIN_SUCCESS: 'GETLOGIN_SUCCESS',

  POPULATE_USERS_REQUEST: 'POPULATE_USERS_REQUEST',
  POPULATE_USERS_SUCCESS: 'POPULATE_USERS_SUCCESS',
  POPULATE_USERS_UPDATE: 'POPULATE_USERS_UPDATE',
  POPULATE_USERS_FAILURE: 'POPULATE_USERS_FAILURE',

  POPULATEALL_USERS_REQUEST: 'POPULATEALL_USERS_REQUEST',
  POPULATEALL_USERS_SUCCESS: 'POPULATEALL_USERS_SUCCESS',
  POPULATEALL_USERS_FAILURE: 'POPULATEALL_USERS_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  DELETE_MULTI_REQUEST: 'USERS_MULTI_DELETE_REQUEST',
  DELETE_MULTI_SUCCESS: 'USERS_MULTI_DELETE_SUCCESS',
  DELETE_MULTI_FAILURE: 'USERS_MULTI_DELETE_FAILURE',

  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',

  NEW_USER_REQUEST: 'NEW_USER_REQUEST',
  NEW_USER_SUCCESS: 'NEW_USER_SUCCESS',
  NEW_USER_FAILURE: 'NEW_USER_FAILURE',

  CHECK_USEREXISTS_REQUEST: 'CHECK_USEREXISTS_REQUEST',
  CHECK_USEREXISTS_SUCCESS: 'CHECK_USEREXISTS_SUCCESS',
  CHECK_USEREXISTS_FAILURE: 'CHECK_USEREXISTS_FAILURE',
  CLEAR_USEREXISTS_REQUEST: 'CLEAR_USEREXISTS_REQUEST',
  CONFIRM_USEREXISTS_REQUEST: 'CONFIRM_USEREXISTS_REQUEST',

  INVITE_USER_REQUEST: 'INVITE_USER_REQUEST',
  INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
  INVITE_USER_FAILURE: 'INVITE_USER_FAILURE',

  INVITE_USERS_REQUEST: 'INVITE_USERS_REQUEST',
  INVITE_USERS_SUCCESS: 'INVITE_USERS_SUCCESS',
  INVITE_USERS_FAILURE: 'INVITE_USERS_FAILURE',

  IMPORT_USER_REQUEST: 'IMPORT_USER_REQUEST',
  IMPORT_USER_SUCCESS: 'IMPORT_USER_SUCCESS',
  IMPORT_USER_FAILURE: 'IMPORT_USER_FAILURE',
  IMPORT_ADD_SUCCESS: 'IMPORT_ADD_SUCCESS',

  APPROVE_ADMINUSER_REQUEST: 'APPROVE_ADMINUSER_REQUEST',
  APPROVE_ADMINUSER_SUCCESS: 'APPROVE_ADMINUSER_SUCCESS',
  APPROVE_ADMINUSER_FAILURE: 'APPROVE_ADMINUSER_FAILURE',
  APPROVE_NORMUSER_REQUEST: 'APPROVE_ADMINUSER_REQUEST',
  APPROVE_NORMUSER_SUCCESS: 'APPROVE_NORMUSER_SUCCESS',
  APPROVE_NORMUSER_FAILURE: 'APPROVE_NORMUSER_FAILURE',

  UPDATE_USER_INITALPASS_REQUEST: 'UPDATE_USER_INITALPASS_REQUEST',
  UPDATE_USER_INITALPASS_SUCCESS: 'UPDATE_USER_INITALPASS_SUCCESS',
  UPDATE_USER_INITALPASS_FAILURE: 'UPDATE_USER_INITALPASS_FAILURE',
  UPDATE_USER_INITALTEMP_REQUEST: 'UPDATE_USER_INITALTEMP_REQUEST',
  UPDATE_USER_INITALGEN_REQUEST: 'UPDATE_USER_INITALGEN_REQUEST',
  UPDATE_USER_INITALCLEAR_REQUEST: 'UPDATE_USER_INITALCLEAR_REQUEST',
  UPDATE_USER_INITALALIAS_REQUEST: 'UPDATE_USER_INITALALIAS_REQUEST',

  USER_WELCOME_PDF_REQUEST: 'USER_WELCOME_PDF_REQUEST',
  USER_WELCOME_PDF_SUCCESS: 'USER_WELCOME_PDF_SUCCESS',

  GETUSER_REQUEST: 'GETUSER_REQUEST',
  GETUSER_SUCCESS: 'GETUSER_SUCCESS',
  GETUSER_FAILURE: 'GETUSER_FAILURE',

  GETUSER_PUBLICKEY_REQUEST: 'GETUSER_PUBLICKEY_REQUEST',
  GETUSER_PUBLICKEY_SUCCESS: 'GETUSER_PUBLICKEY_SUCCESS',
  GETUSER_PUBLICKEY_FAILURE: 'GETUSER_PUBLICKEY_FAILURE',

  APPROVE_USER_NEWPASS_REQUEST: 'APPROVE_USER_NEWPASS_REQUEST',
  APPROVE_USER_NEWPASS_SUCCESS: 'APPROVE_USER_NEWPASS_SUCCESS',
  APPROVE_USER_NEWPASS_FAILURE: 'APPROVE_USER_NEWPASS_FAILURE',

  GET_LIST_PENDING_PASSWORD_REQUEST: 'GET_LIST_PENDING_PASSWORD_REQUEST',
  GET_LIST_PENDING_PASSWORD_SUCCESS: 'GET_LIST_PENDING_PASSWORD_SUCCESS',
  GET_LIST_PENDING_PASSWORD_FAILURE: 'GET_LIST_PENDING_PASSWORD_FAILURE',

  GET_GENERIC_KEY_REQUEST: 'GET_GENERIC_KEY_REQUEST',
  GET_GENERIC_KEY_SUCCESS: 'GET_GENERIC_KEY_SUCCESS',
  GET_GENERIC_KEY_FAILURE: 'GET_GENERIC_KEY_FAILURE',

  GET_PGENERIC_KEY_REQUEST: 'GET_PGENERIC_KEY_REQUEST',
  GET_PGENERIC_KEY_SUCCESS: 'GET_PGENERIC_KEY_SUCCESS',
  GET_PGENERIC_KEY_FAILURE: 'GET_PGENERIC_KEY_FAILURE',

  CLEAR_NEW_TASK_REQUEST: 'CLEAR_NEW_TASK_REQUEST',

  LIST_ALL_TASK_REQUEST: 'LIST_ALL_TASK_REQUEST',
  LIST_ALL_TASK_SUCCESS: 'LIST_ALL_TASK_SUCCESS',
  LIST_ALL_TASK_FAILURE: 'LIST_ALL_TASK_FAILURE',

  GET_TASK_REQUEST: 'GET_TASK_REQUEST',
  GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
  GET_TASK_FAILURE: 'GET_TASK_FAILURE',

  UPDATE_TASK_REQUEST: 'UPDATE_TASK_REQUEST',

  COMPLETE_TASK_REQUEST: 'COMPLETE_TASK_REQUEST',
  COMPLETE_TASK_SUCCESS: 'COMPLETE_TASK_SUCCESS',
  COMPLETE_TASK_FAILURE: 'COMPLETE_TASK_FAILURE',

  LOCK_TASK_REQUEST: 'LOCK_TASK_REQUEST',
  LOCK_TASK_SUCCESS: 'LOCK_TASK_SUCCESS',
  LOCK_TASK_FAILURE: 'LOCK_TASK_FAILURE',

  ERROR_TASK_FAILURE: 'ERROR_TASK_FAILURE',
  RESET_TASK_REQUEST: 'RESET_TASK_REQUEST',

  STEP_TASK_REQUEST: 'STEP_TASK_REQUEST',

  GET_AVAILABLENAMES_REQUEST: 'GET_AVAILABLENAMES_REQUEST',
  GET_AVAILABLENAMES_SUCCESS: 'GET_AVAILABLENAMES_SUCCESS',
  GET_AVAILABLENAMES_FAILURE: 'GET_AVAILABLENAMES_FAILURE',

  CHECK_ALIASNAME_REQUEST: 'CHECK_ALIASNAME_REQUEST',
  CHECK_ALIASNAME_SUCCESS: 'CHECK_ALIASNAME_SUCCESS',
  CHECK_ALIASNAME_FAILURE: 'CHECK_ALIASNAME_FAILURE',

  GET_USER_SERIALCARD_REQUEST: 'GET_USER_SERIALCARD_REQUEST',
  GET_USER_SERIALCARD_SUCCESS: 'GET_USER_SERIALCARD_SUCCESS',
  GET_USER_SERIALCARD_FAILURE: 'GET_USER_SERIALCARD_FAILURE',

  GET_USER_DEVICES_REQUEST: 'GET_USER_DEVICES_REQUEST',
  GET_USER_DEVICES_SUCCESS: 'GET_USER_DEVICES_SUCCESS',
  GET_USER_DEVICES_FAILURE: 'GET_USER_DEVICES_FAILURE',

  GET_CUSTOMER_SERIALCARD_REQUEST: 'GET_CUSTOMER_SERIALCARD_REQUEST',
  GET_CUSTOMER_SERIALCARD_SUCCESS: 'GET_CUSTOMER_SERIALCARD_SUCCESS',
  GET_CUSTOMER_SERIALCARD_FAILURE: 'GET_CUSTOMER_SERIALCARD_FAILURE',

  SEND_WELCOMEEMAIL_REQUEST: 'SEND_WELCOMEEMAIL_REQUEST',
  SEND_WELCOMEEMAIL_SUCCESS: 'SEND_WELCOMEEMAIL_SUCCESS',
  SEND_WELCOMEEMAIL_FAILURE: 'SEND_WELCOMEEMAIL_FAILURE',

  REG_SIGNCERT_REQUEST: 'REG_SIGNCERT_REQUEST',
  REG_SIGNCERT_SUCCESS: 'REG_SIGNCERT_SUCCESS',
  REG_SIGNCERT_FAILURE: 'REG_SIGNCERT_FAILURE',

  KEEP_SESSION_ALIVE: 'KEEP_SESSION_ALIVE',
  KILL_SESSION_ALIVE: 'KILL_SESSION_ALIVE',
  WEBSITE_UPDATE_AVAILABLE: 'WEBSITE_UPDATE_AVAILABLE',

  INTERNET_LOST: 'INTERNET_LOST',

  GROUP_ADD_REQUEST: 'GROUP_ADD_REQUEST',
  GROUP_ADD_SUCCESS: 'GROUP_ADD_SUCCESS',
  GROUP_ADD_FAILURE: 'GROUP_ADD_FAILURE',

  GROUP_DELETE_REQUEST: 'GROUP_DELETE_REQUEST',
  GROUP_DELETE_SUCCESS: 'GROUP_DELETE_SUCCESS',
  GROUP_DELETE_FAILURE: 'GROUP_DELETE_FAILURE',

  GROUP_GET_REQUEST: 'GROUP_GET_REQUEST',
  GROUP_GET_SUCCESS: 'GROUP_GET_SUCCESS',
  GROUP_GET_FAILURE: 'GROUP_GET_FAILURE',

  GROUP_GETALL_REQUEST: 'GROUP_GETALL_REQUEST',
  GROUP_GETALL_SUCCESS: 'GROUP_GETALL_SUCCESS',
  GROUP_GETALL_FAILURE: 'GROUP_GETALL_FAILURE',

  GROUP_UPDATE_REQUEST: 'GROUP_UPDATE_REQUEST',
  GROUP_UPDATE_SUCCESS: 'GROUP_UPDATE_SUCCESS',
  GROUP_UPDATE_FAILURE: 'GROUP_UPDATE_FAILURE',

  USER_DISPLAYSETTINGS_SUCESS: 'USER_DISPLAYSETTINGS_SUCESS',

  MANUAL_CONFIRM_INVITE_REQUEST: 'MANUAL_CONFIRM_INVITE_REQUEST',
  MANUAL_CONFIRM_INVITE_SUCCESS: 'MANUAL_CONFIRM_INVITE_SUCCESS',
  MANUAL_CONFIRM_INVITE_FAILURE: 'MANUAL_CONFIRM_INVITE_FAILURE',

  AUTHCODE_CONFIRM_INVITE_REQUEST: 'AUTHCODE_CONFIRM_INVITE_REQUEST',
  AUTHCODE_CONFIRM_INVITE_SUCCESS: 'AUTHCODE_CONFIRM_INVITE_SUCCESS',
  AUTHCODE_CONFIRM_INVITE_FAILURE: 'AUTHCODE_CONFIRM_INVITE_FAILURE',

  LOCAKPASS_HASACCOUNT_REQUEST: 'LOCAKPASS_HASACCOUNT_REQUEST',
  LOCAKPASS_HASACCOUNT_SUCCESS: 'LOCAKPASS_HASACCOUNT_SUCCESS',
  LOCAKPASS_HASACCOUNT_FAILURE: 'LOCAKPASS_HASACCOUNT_FAILURE',

  LOCAKPASS_PAIRDEVICE_REQUEST: 'LOCAKPASS_PAIRDEVICE_REQUEST',
  LOCAKPASS_PAIRDEVICE_SUCCESS: 'LOCAKPASS_PAIRDEVICE_SUCCESS',
  LOCAKPASS_PAIRDEVICE_FAILURE: 'LOCAKPASS_PAIRDEVICE_FAILURE',

  USER_ANALYTICS_REQUEST: 'USER_ANALYTICS_REQUEST',
  USER_ANALYTICS_SUCCESS: 'USER_ANALYTICS_SUCCESS',
  USER_ANALYTICS_FAILURE: 'USER_ANALYTICS_FAILURE',

  MAINLOGIN_CLEAR_REQUEST: 'MAINLOGIN_CLEAR_REQUEST',

  TERMS_CONDITION_REQUEST: 'TERMS_CONDITION_REQUEST',
  TERMS_CONDITION_SUCCESS: 'TERMS_CONDITION_SUCCESS',
  TERMS_CONDITION_FAILURE: 'TERMS_CONDITION_FAILURE',

  TRAIL_GETNEWUSERINFO_REQUEST: 'TRAIL_GETNEWUSERINFO_REQUEST',
  TRAIL_GETNEWUSERINFO_SUCCESS: 'TRAIL_GETNEWUSERINFO_SUCCESS',
  TRAIL_GETNEWUSERINFO_FAILURE: 'TRAIL_GETNEWUSERINFO_FAILURE',

  TRAIL_COMPLETE_REQUEST: 'TRAIL_COMPLETE_REQUEST',
  TRAIL_COMPLETE_SUCCESS: 'TRAIL_COMPLETE_SUCCESS',
  TRAIL_COMPLETE_FAILURE: 'TRAIL_COMPLETE_FAILURE',

  TRAIL_EXISTINGCOMPLETE_REQUEST: 'TRAIL_EXISTINGCOMPLETE_REQUEST',
  TRAIL_EXISTINGCOMPLETE_SUCCESS: 'TRAIL_EXISTINGCOMPLETE_SUCCESS',
  TRAIL_EXISTINGCOMPLETE_FAILURE: 'TRAIL_EXISTINGCOMPLETE_FAILURE',

  TRAIL_EXISTINGREGISTER_REQUEST: 'TRAIL_EXISTINGREGISTER_REQUEST',
  TRAIL_EXISTINGREGISTER_SUCCESS: 'TRAIL_EXISTINGREGISTER_SUCCESS',
  TRAIL_EXISTINGREGISTER_FAILURE: 'TRAIL_EXISTINGREGISTER_FAILURE',

  TRAIL_CHECKEMAIL_REQUEST: 'TRAIL_CHECKEMAIL_REQUEST',
  TRAIL_CHECKEMAIL_SUCCESS: 'TRAIL_CHECKEMAIL_SUCCESS',
  TRAIL_CHECKEMAIL_FAILURE: 'TRAIL_CHECKEMAIL_FAILURE',
  TRAIL_CHECKEMAIL_DELETE: 'TRAIL_CHECKEMAIL_DELETE',

  TRAIL_COSECSAVE_REQUEST: 'TRAIL_COSECSAVE_REQUEST',
  TRAIL_COSECSAVE_SUCCESS: 'TRAIL_COSECSAVE_SUCCESS',
  TRAIL_COSECSAVE_FAILURE: 'TRAIL_COSECSAVE_FAILURE',

  TRAIL_COMPLETE_REQUEST: 'TRAIL_COMPLETE_REQUEST',
  TRAIL_COMPLETE_SUCCESS: 'TRAIL_COMPLETE_SUCCESS',
  TRAIL_COMPLETE_FAILURE: 'TRAIL_COMPLETE_FAILURE',

  LOGIN_AUTHZERO_REQUEST: 'LOGIN_AUTHZERO_REQUEST',
  LOGIN_AUTHZERO_SUCCESS: 'LOGIN_AUTHZERO_SUCCESS',
  LOGIN_AUTHZERO_FAILURE: 'LOGIN_AUTHZERO_FAILURE',

  DELETE_USER_SETTINGS: 'DELETE_USER_SETTINGS',
  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
};
export const userConstants = Object.assign(constants, baseConstants)
