var Module = function () {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;

  return function (Module) {
    Module = Module || {};
    var Module = typeof Module !== "undefined" ? Module : {};
    var moduleOverrides = {};
    var key;

    for (key in Module) {
      if (Module.hasOwnProperty(key)) {
        moduleOverrides[key] = Module[key];
      }
    }

    Module["arguments"] = [];
    Module["thisProgram"] = "./this.program";

    Module["quit"] = function (status, toThrow) {
      throw toThrow;
    };

    Module["preRun"] = [];
    Module["postRun"] = [];
    var ENVIRONMENT_IS_WEB = false;
    var ENVIRONMENT_IS_WORKER = false;
    var ENVIRONMENT_IS_NODE = false;
    var ENVIRONMENT_IS_SHELL = false;
    ENVIRONMENT_IS_WEB = typeof window === "object";
    ENVIRONMENT_IS_WORKER = typeof importScripts === "function";
    ENVIRONMENT_IS_NODE = typeof process === "object" && typeof require === "function" && !ENVIRONMENT_IS_WEB && !ENVIRONMENT_IS_WORKER;
    ENVIRONMENT_IS_SHELL = !ENVIRONMENT_IS_WEB && !ENVIRONMENT_IS_NODE && !ENVIRONMENT_IS_WORKER;

    if (Module["ENVIRONMENT"]) {
      throw new Error("Module.ENVIRONMENT has been deprecated. To force the environment, use the ENVIRONMENT compile-time option (for example, -s ENVIRONMENT=web or -s ENVIRONMENT=node)");
    }

    assert(typeof Module["memoryInitializerPrefixURL"] === "undefined", "Module.memoryInitializerPrefixURL option was removed, use Module.locateFile instead");
    assert(typeof Module["pthreadMainPrefixURL"] === "undefined", "Module.pthreadMainPrefixURL option was removed, use Module.locateFile instead");
    assert(typeof Module["cdInitializerPrefixURL"] === "undefined", "Module.cdInitializerPrefixURL option was removed, use Module.locateFile instead");
    assert(typeof Module["filePackagePrefixURL"] === "undefined", "Module.filePackagePrefixURL option was removed, use Module.locateFile instead");
    var scriptDirectory = "";

    function locateFile(path) {
      if (Module["locateFile"]) {
        return Module["locateFile"](path, scriptDirectory);
      } else {
        return scriptDirectory + path;
      }
    }

    if (ENVIRONMENT_IS_NODE) {
      scriptDirectory = __dirname + "/";
      var nodeFS;
      var nodePath;

      Module["read"] = function shell_read(filename, binary) {
        var ret;
        if (!nodeFS) nodeFS = require("fs");
        if (!nodePath) nodePath = require("path");
        filename = nodePath["normalize"](filename);
        ret = nodeFS["readFileSync"](filename);
        return binary ? ret : ret.toString();
      };

      Module["readBinary"] = function readBinary(filename) {
        var ret = Module["read"](filename, true);

        if (!ret.buffer) {
          ret = new Uint8Array(ret);
        }

        assert(ret.buffer);
        return ret;
      };

      if (process["argv"].length > 1) {
        Module["thisProgram"] = process["argv"][1].replace(/\\/g, "/");
      }

      Module["arguments"] = process["argv"].slice(2);
      process["on"]("uncaughtException", function (ex) {
        if (!(ex instanceof ExitStatus)) {
          throw ex;
        }
      });
      process["on"]("unhandledRejection", function (reason, p) {
        err("node.js exiting due to unhandled promise rejection");
        process["exit"](1);
      });

      Module["quit"] = function (status) {
        process["exit"](status);
      };

      Module["inspect"] = function () {
        return "[Emscripten Module object]";
      };
    } else if (ENVIRONMENT_IS_SHELL) {
      if (typeof read != "undefined") {
        Module["read"] = function shell_read(f) {
          return read(f);
        };
      }

      Module["readBinary"] = function readBinary(f) {
        var data;

        if (typeof readbuffer === "function") {
          return new Uint8Array(readbuffer(f));
        }

        data = read(f, "binary");
        assert(typeof data === "object");
        return data;
      };

      if (typeof scriptArgs != "undefined") {
        Module["arguments"] = scriptArgs;
      } else if (typeof arguments != "undefined") {
        Module["arguments"] = arguments;
      }

      if (typeof quit === "function") {
        Module["quit"] = function (status) {
          quit(status);
        };
      }
    } else if (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER) {
      if (ENVIRONMENT_IS_WEB) {
        if (document.currentScript) {
          scriptDirectory = document.currentScript.src;
        }
      } else {
        scriptDirectory = self.location.href;
      }

      if (_scriptDir) {
        scriptDirectory = _scriptDir;
      }

      if (scriptDirectory.indexOf("blob:") !== 0) {
        scriptDirectory = scriptDirectory.split("/").slice(0, -1).join("/") + "/";
      } else {
        scriptDirectory = "";
      }

      Module["read"] = function shell_read(url) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, false);
        xhr.send(null);
        return xhr.responseText;
      };

      if (ENVIRONMENT_IS_WORKER) {
        Module["readBinary"] = function readBinary(url) {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, false);
          xhr.responseType = "arraybuffer";
          xhr.send(null);
          return new Uint8Array(xhr.response);
        };
      }

      Module["readAsync"] = function readAsync(url, onload, onerror) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";

        xhr.onload = function xhr_onload() {
          if (xhr.status == 200 || xhr.status == 0 && xhr.response) {
            onload(xhr.response);
            return;
          }

          onerror();
        };

        xhr.onerror = onerror;
        xhr.send(null);
      };

      Module["setWindowTitle"] = function (title) {
        document.title = title;
      };
    } else {
      throw new Error("environment detection error");
    }

    var out = Module["print"] || (typeof console !== "undefined" ? console.log.bind(console) : typeof print !== "undefined" ? print : null);
    var err = Module["printErr"] || (typeof printErr !== "undefined" ? printErr : typeof console !== "undefined" && console.warn.bind(console) || out);

    for (key in moduleOverrides) {
      if (moduleOverrides.hasOwnProperty(key)) {
        Module[key] = moduleOverrides[key];
      }
    }

    moduleOverrides = undefined;
    var STACK_ALIGN = 16;

    _stackSave = _stackRestore = stackAlloc = setTempRet0 = getTempRet0 = function getTempRet0() {
      abort("cannot use the stack before compiled code is ready to run, and has provided stack access");
    };

    function staticAlloc(size) {
      assert(!staticSealed);
      var ret = STATICTOP;
      STATICTOP = STATICTOP + size + 15 & -16;
      assert(STATICTOP < TOTAL_MEMORY, "not enough memory for static allocation - increase TOTAL_MEMORY");
      return ret;
    }

    function dynamicAlloc(size) {
      assert(DYNAMICTOP_PTR);
      var ret = HEAP32[DYNAMICTOP_PTR >> 2];
      var end = ret + size + 15 & -16;
      HEAP32[DYNAMICTOP_PTR >> 2] = end;

      if (end >= TOTAL_MEMORY) {
        var success = enlargeMemory();

        if (!success) {
          HEAP32[DYNAMICTOP_PTR >> 2] = ret;
          return 0;
        }
      }

      return ret;
    }

    function alignMemory(size, factor) {
      if (!factor) factor = STACK_ALIGN;
      var ret = size = Math.ceil(size / factor) * factor;
      return ret;
    }

    function getNativeTypeSize(type) {
      switch (type) {
        case "i1":
        case "i8":
          return 1;

        case "i16":
          return 2;

        case "i32":
          return 4;

        case "i64":
          return 8;

        case "float":
          return 4;

        case "double":
          return 8;

        default:
          {
            if (type[type.length - 1] === "*") {
              return 4;
            } else if (type[0] === "i") {
              var bits = parseInt(type.substr(1));
              assert(bits % 8 === 0);
              return bits / 8;
            } else {
              return 0;
            }
          }
      }
    }

    function warnOnce(text) {
      if (!warnOnce.shown) warnOnce.shown = {};

      if (!warnOnce.shown[text]) {
        warnOnce.shown[text] = 1;
        err(text);
      }
    }

    var asm2wasmImports = {
      "f64-rem": function f64Rem(x, y) {
        return x % y;
      },
      "debugger": function _debugger() {
        debugger;
      }
    };
    var jsCallStartIndex = 1;
    var functionPointers = new Array(0);
    var funcWrappers = {};

    function dynCall(sig, ptr, args) {
      if (args && args.length) {
        assert(args.length == sig.length - 1);
        assert("dynCall_" + sig in Module, "bad function pointer type - no table for sig '" + sig + "'");
        return Module["dynCall_" + sig].apply(null, [ptr].concat(args));
      } else {
        assert(sig.length == 1);
        assert("dynCall_" + sig in Module, "bad function pointer type - no table for sig '" + sig + "'");
        return Module["dynCall_" + sig].call(null, ptr);
      }
    }

    var GLOBAL_BASE = 1024;
    var ABORT = 0;
    var EXITSTATUS = 0;

    function assert(condition, text) {
      if (!condition) {
        abort("Assertion failed: " + text);
      }
    }

    function getCFunc(ident) {
      var func = Module["_" + ident];
      assert(func, "Cannot call unknown function " + ident + ", make sure it is exported");
      return func;
    }

    var JSfuncs = {
      "stackSave": function stackSave() {
        _stackSave();
      },
      "stackRestore": function stackRestore() {
        _stackRestore();
      },
      "arrayToC": function arrayToC(arr) {
        var ret = stackAlloc(arr.length);
        writeArrayToMemory(arr, ret);
        return ret;
      },
      "stringToC": function stringToC(str) {
        var ret = 0;

        if (str !== null && str !== undefined && str !== 0) {
          var len = (str.length << 2) + 1;
          ret = stackAlloc(len);
          stringToUTF8(str, ret, len);
        }

        return ret;
      }
    };
    var toC = {
      "string": JSfuncs["stringToC"],
      "array": JSfuncs["arrayToC"]
    };

    function ccall(ident, returnType, argTypes, args, opts) {
      function convertReturnValue(ret) {
        if (returnType === "string") return Pointer_stringify(ret);
        if (returnType === "boolean") return Boolean(ret);
        return ret;
      }

      var func = getCFunc(ident);
      var cArgs = [];
      var stack = 0;
      assert(returnType !== "array", 'Return type should not be "array".');

      if (args) {
        for (var i = 0; i < args.length; i++) {
          var converter = toC[argTypes[i]];

          if (converter) {
            if (stack === 0) stack = _stackSave();
            cArgs[i] = converter(args[i]);
          } else {
            cArgs[i] = args[i];
          }
        }
      }

      var ret = func.apply(null, cArgs);
      ret = convertReturnValue(ret);
      if (stack !== 0) _stackRestore(stack);
      return ret;
    }

    function cwrap(ident, returnType, argTypes, opts) {
      return function () {
        return ccall(ident, returnType, argTypes, arguments, opts);
      };
    }

    function setValue(ptr, value, type, noSafe) {
      type = type || "i8";
      if (type.charAt(type.length - 1) === "*") type = "i32";

      switch (type) {
        case "i1":
          HEAP8[ptr >> 0] = value;
          break;

        case "i8":
          HEAP8[ptr >> 0] = value;
          break;

        case "i16":
          HEAP16[ptr >> 1] = value;
          break;

        case "i32":
          HEAP32[ptr >> 2] = value;
          break;

        case "i64":
          tempI64 = [value >>> 0, (tempDouble = value, +Math_abs(tempDouble) >= 1 ? tempDouble > 0 ? (Math_min(+Math_floor(tempDouble / 4294967296), 4294967295) | 0) >>> 0 : ~~+Math_ceil((tempDouble - +(~~tempDouble >>> 0)) / 4294967296) >>> 0 : 0)], HEAP32[ptr >> 2] = tempI64[0], HEAP32[ptr + 4 >> 2] = tempI64[1];
          break;

        case "float":
          HEAPF32[ptr >> 2] = value;
          break;

        case "double":
          HEAPF64[ptr >> 3] = value;
          break;

        default:
          abort("invalid type for setValue: " + type);
      }
    }

    function getValue(ptr, type, noSafe) {
      type = type || "i8";
      if (type.charAt(type.length - 1) === "*") type = "i32";

      switch (type) {
        case "i1":
          return HEAP8[ptr >> 0];

        case "i8":
          return HEAP8[ptr >> 0];

        case "i16":
          return HEAP16[ptr >> 1];

        case "i32":
          return HEAP32[ptr >> 2];

        case "i64":
          return HEAP32[ptr >> 2];

        case "float":
          return HEAPF32[ptr >> 2];

        case "double":
          return HEAPF64[ptr >> 3];

        default:
          abort("invalid type for getValue: " + type);
      }

      return null;
    }

    var ALLOC_STATIC = 2;
    var ALLOC_NONE = 4;

    function getMemory(size) {
      if (!staticSealed) return staticAlloc(size);
      if (!runtimeInitialized) return dynamicAlloc(size);
      return _malloc(size);
    }

    function Pointer_stringify(ptr, length) {
      if (length === 0 || !ptr) return "";
      var hasUtf = 0;
      var t;
      var i = 0;

      while (1) {
        assert(ptr + i < TOTAL_MEMORY);
        t = HEAPU8[ptr + i >> 0];
        hasUtf |= t;
        if (t == 0 && !length) break;
        i++;
        if (length && i == length) break;
      }

      if (!length) length = i;
      var ret = "";

      if (hasUtf < 128) {
        var MAX_CHUNK = 1024;
        var curr;

        while (length > 0) {
          curr = String.fromCharCode.apply(String, HEAPU8.subarray(ptr, ptr + Math.min(length, MAX_CHUNK)));
          ret = ret ? ret + curr : curr;
          ptr += MAX_CHUNK;
          length -= MAX_CHUNK;
        }

        return ret;
      }

      return UTF8ToString(ptr);
    }

    var UTF8Decoder = typeof TextDecoder !== "undefined" ? new TextDecoder("utf8") : undefined;

    function UTF8ArrayToString(u8Array, idx) {
      var endPtr = idx;

      while (u8Array[endPtr]) {
        ++endPtr;
      }

      if (endPtr - idx > 16 && u8Array.subarray && UTF8Decoder) {
        return UTF8Decoder.decode(u8Array.subarray(idx, endPtr));
      } else {
        var u0, u1, u2, u3, u4, u5;
        var str = "";

        while (1) {
          u0 = u8Array[idx++];
          if (!u0) return str;

          if (!(u0 & 128)) {
            str += String.fromCharCode(u0);
            continue;
          }

          u1 = u8Array[idx++] & 63;

          if ((u0 & 224) == 192) {
            str += String.fromCharCode((u0 & 31) << 6 | u1);
            continue;
          }

          u2 = u8Array[idx++] & 63;

          if ((u0 & 240) == 224) {
            u0 = (u0 & 15) << 12 | u1 << 6 | u2;
          } else {
            u3 = u8Array[idx++] & 63;

            if ((u0 & 248) == 240) {
              u0 = (u0 & 7) << 18 | u1 << 12 | u2 << 6 | u3;
            } else {
              u4 = u8Array[idx++] & 63;

              if ((u0 & 252) == 248) {
                u0 = (u0 & 3) << 24 | u1 << 18 | u2 << 12 | u3 << 6 | u4;
              } else {
                u5 = u8Array[idx++] & 63;
                u0 = (u0 & 1) << 30 | u1 << 24 | u2 << 18 | u3 << 12 | u4 << 6 | u5;
              }
            }
          }

          if (u0 < 65536) {
            str += String.fromCharCode(u0);
          } else {
            var ch = u0 - 65536;
            str += String.fromCharCode(55296 | ch >> 10, 56320 | ch & 1023);
          }
        }
      }
    }

    function UTF8ToString(ptr) {
      return UTF8ArrayToString(HEAPU8, ptr);
    }

    function stringToUTF8Array(str, outU8Array, outIdx, maxBytesToWrite) {
      if (!(maxBytesToWrite > 0)) return 0;
      var startIdx = outIdx;
      var endIdx = outIdx + maxBytesToWrite - 1;

      for (var i = 0; i < str.length; ++i) {
        var u = str.charCodeAt(i);

        if (u >= 55296 && u <= 57343) {
          var u1 = str.charCodeAt(++i);
          u = 65536 + ((u & 1023) << 10) | u1 & 1023;
        }

        if (u <= 127) {
          if (outIdx >= endIdx) break;
          outU8Array[outIdx++] = u;
        } else if (u <= 2047) {
          if (outIdx + 1 >= endIdx) break;
          outU8Array[outIdx++] = 192 | u >> 6;
          outU8Array[outIdx++] = 128 | u & 63;
        } else if (u <= 65535) {
          if (outIdx + 2 >= endIdx) break;
          outU8Array[outIdx++] = 224 | u >> 12;
          outU8Array[outIdx++] = 128 | u >> 6 & 63;
          outU8Array[outIdx++] = 128 | u & 63;
        } else if (u <= 2097151) {
          if (outIdx + 3 >= endIdx) break;
          outU8Array[outIdx++] = 240 | u >> 18;
          outU8Array[outIdx++] = 128 | u >> 12 & 63;
          outU8Array[outIdx++] = 128 | u >> 6 & 63;
          outU8Array[outIdx++] = 128 | u & 63;
        } else if (u <= 67108863) {
          if (outIdx + 4 >= endIdx) break;
          outU8Array[outIdx++] = 248 | u >> 24;
          outU8Array[outIdx++] = 128 | u >> 18 & 63;
          outU8Array[outIdx++] = 128 | u >> 12 & 63;
          outU8Array[outIdx++] = 128 | u >> 6 & 63;
          outU8Array[outIdx++] = 128 | u & 63;
        } else {
          if (outIdx + 5 >= endIdx) break;
          outU8Array[outIdx++] = 252 | u >> 30;
          outU8Array[outIdx++] = 128 | u >> 24 & 63;
          outU8Array[outIdx++] = 128 | u >> 18 & 63;
          outU8Array[outIdx++] = 128 | u >> 12 & 63;
          outU8Array[outIdx++] = 128 | u >> 6 & 63;
          outU8Array[outIdx++] = 128 | u & 63;
        }
      }

      outU8Array[outIdx] = 0;
      return outIdx - startIdx;
    }

    function stringToUTF8(str, outPtr, maxBytesToWrite) {
      assert(typeof maxBytesToWrite == "number", "stringToUTF8(str, outPtr, maxBytesToWrite) is missing the third parameter that specifies the length of the output buffer!");
      return stringToUTF8Array(str, HEAPU8, outPtr, maxBytesToWrite);
    }

    function lengthBytesUTF8(str) {
      var len = 0;

      for (var i = 0; i < str.length; ++i) {
        var u = str.charCodeAt(i);
        if (u >= 55296 && u <= 57343) u = 65536 + ((u & 1023) << 10) | str.charCodeAt(++i) & 1023;

        if (u <= 127) {
          ++len;
        } else if (u <= 2047) {
          len += 2;
        } else if (u <= 65535) {
          len += 3;
        } else if (u <= 2097151) {
          len += 4;
        } else if (u <= 67108863) {
          len += 5;
        } else {
          len += 6;
        }
      }

      return len;
    }

    var UTF16Decoder = typeof TextDecoder !== "undefined" ? new TextDecoder("utf-16le") : undefined;

    function demangle(func) {
      warnOnce("warning: build with  -s DEMANGLE_SUPPORT=1  to link in libcxxabi demangling");
      return func;
    }

    function demangleAll(text) {
      var regex = /__Z[\w\d_]+/g;
      return text.replace(regex, function (x) {
        var y = demangle(x);
        return x === y ? x : x + " [" + y + "]";
      });
    }

    function jsStackTrace() {
      var err = new Error();

      if (!err.stack) {
        try {
          throw new Error(0);
        } catch (e) {
          err = e;
        }

        if (!err.stack) {
          return "(no stack trace available)";
        }
      }

      return err.stack.toString();
    }

    function stackTrace() {
      var js = jsStackTrace();
      if (Module["extraStackTrace"]) js += "\n" + Module["extraStackTrace"]();
      return demangleAll(js);
    }

    var WASM_PAGE_SIZE = 65536;
    var ASMJS_PAGE_SIZE = 16777216;
    var MIN_TOTAL_MEMORY = 16777216;

    function alignUp(x, multiple) {
      if (x % multiple > 0) {
        x += multiple - x % multiple;
      }

      return x;
    }

    var buffer, HEAP8, HEAPU8, HEAP16, HEAPU16, HEAP32, HEAPU32, HEAPF32, HEAPF64;

    function updateGlobalBuffer(buf) {
      Module["buffer"] = buffer = buf;
    }

    function updateGlobalBufferViews() {
      Module["HEAP8"] = HEAP8 = new Int8Array(buffer);
      Module["HEAP16"] = HEAP16 = new Int16Array(buffer);
      Module["HEAP32"] = HEAP32 = new Int32Array(buffer);
      Module["HEAPU8"] = HEAPU8 = new Uint8Array(buffer);
      Module["HEAPU16"] = HEAPU16 = new Uint16Array(buffer);
      Module["HEAPU32"] = HEAPU32 = new Uint32Array(buffer);
      Module["HEAPF32"] = HEAPF32 = new Float32Array(buffer);
      Module["HEAPF64"] = HEAPF64 = new Float64Array(buffer);
    }

    var STATIC_BASE, STATICTOP, staticSealed;
    var STACK_BASE, STACKTOP, STACK_MAX;
    var DYNAMIC_BASE, DYNAMICTOP_PTR;
    STATIC_BASE = STATICTOP = STACK_BASE = STACKTOP = STACK_MAX = DYNAMIC_BASE = DYNAMICTOP_PTR = 0;
    staticSealed = false;

    function writeStackCookie() {
      assert((STACK_MAX & 3) == 0);
      HEAPU32[(STACK_MAX >> 2) - 1] = 34821223;
      HEAPU32[(STACK_MAX >> 2) - 2] = 2310721022;
    }

    function checkStackCookie() {
      if (HEAPU32[(STACK_MAX >> 2) - 1] != 34821223 || HEAPU32[(STACK_MAX >> 2) - 2] != 2310721022) {
        abort("Stack overflow! Stack cookie has been overwritten, expected hex dwords 0x89BACDFE and 0x02135467, but received 0x" + HEAPU32[(STACK_MAX >> 2) - 2].toString(16) + " " + HEAPU32[(STACK_MAX >> 2) - 1].toString(16));
      }

      if (HEAP32[0] !== 1668509029) throw "Runtime error: The application has corrupted its heap memory area (address zero)!";
    }

    function abortStackOverflow(allocSize) {
      abort("Stack overflow! Attempted to allocate " + allocSize + " bytes on the stack, but stack has only " + (STACK_MAX - _stackSave() + allocSize) + " bytes available!");
    }

    function abortOnCannotGrowMemory() {
      abort("Cannot enlarge memory arrays. Either (1) compile with  -s TOTAL_MEMORY=X  with X higher than the current value " + TOTAL_MEMORY + ", (2) compile with  -s ALLOW_MEMORY_GROWTH=1  which allows increasing the size at runtime, or (3) if you want malloc to return NULL (0) instead of this abort, compile with  -s ABORTING_MALLOC=0 ");
    }

    if (!Module["reallocBuffer"]) Module["reallocBuffer"] = function (size) {
      var ret;

      try {
        if (ArrayBuffer.transfer) {
          ret = ArrayBuffer.transfer(buffer, size);
        } else {
          var oldHEAP8 = HEAP8;
          ret = new ArrayBuffer(size);
          var temp = new Int8Array(ret);
          temp.set(oldHEAP8);
        }
      } catch (e) {
        return false;
      }

      var success = _emscripten_replace_memory(ret);

      if (!success) return false;
      return ret;
    };

    function enlargeMemory() {
      assert(HEAP32[DYNAMICTOP_PTR >> 2] > TOTAL_MEMORY);
      var PAGE_MULTIPLE = Module["usingWasm"] ? WASM_PAGE_SIZE : ASMJS_PAGE_SIZE;
      var LIMIT = 2147483648 - PAGE_MULTIPLE;

      if (HEAP32[DYNAMICTOP_PTR >> 2] > LIMIT) {
        err("Cannot enlarge memory, asked to go up to " + HEAP32[DYNAMICTOP_PTR >> 2] + " bytes, but the limit is " + LIMIT + " bytes!");
        return false;
      }

      var OLD_TOTAL_MEMORY = TOTAL_MEMORY;
      TOTAL_MEMORY = Math.max(TOTAL_MEMORY, MIN_TOTAL_MEMORY);

      while (TOTAL_MEMORY < HEAP32[DYNAMICTOP_PTR >> 2]) {
        if (TOTAL_MEMORY <= 536870912) {
          TOTAL_MEMORY = alignUp(2 * TOTAL_MEMORY, PAGE_MULTIPLE);
        } else {
          TOTAL_MEMORY = Math.min(alignUp((3 * TOTAL_MEMORY + 2147483648) / 4, PAGE_MULTIPLE), LIMIT);

          if (TOTAL_MEMORY === OLD_TOTAL_MEMORY) {
            warnOnce("Cannot ask for more memory since we reached the practical limit in browsers (which is just below 2GB), so the request would have failed. Requesting only " + TOTAL_MEMORY);
          }
        }
      }

      var start = Date.now();
      var replacement = Module["reallocBuffer"](TOTAL_MEMORY);

      if (!replacement || replacement.byteLength != TOTAL_MEMORY) {
        err("Failed to grow the heap from " + OLD_TOTAL_MEMORY + " bytes to " + TOTAL_MEMORY + " bytes, not enough memory!");

        if (replacement) {
          err("Expected to get back a buffer of size " + TOTAL_MEMORY + " bytes, but instead got back a buffer of size " + replacement.byteLength);
        }

        TOTAL_MEMORY = OLD_TOTAL_MEMORY;
        return false;
      }

      updateGlobalBuffer(replacement);
      updateGlobalBufferViews();

      if (!Module["usingWasm"]) {
        err("Warning: Enlarging memory arrays, this is not fast! " + [OLD_TOTAL_MEMORY, TOTAL_MEMORY]);
      }

      return true;
    }

    var byteLength;

    try {
      byteLength = Function.prototype.call.bind(Object.getOwnPropertyDescriptor(ArrayBuffer.prototype, "byteLength").get);
      byteLength(new ArrayBuffer(4));
    } catch (e) {
      byteLength = function byteLength(buffer) {
        return buffer.byteLength;
      };
    }

    var TOTAL_STACK = Module["TOTAL_STACK"] || 5242880;
    var TOTAL_MEMORY = Module["TOTAL_MEMORY"] || 16777216;
    if (TOTAL_MEMORY < TOTAL_STACK) err("TOTAL_MEMORY should be larger than TOTAL_STACK, was " + TOTAL_MEMORY + "! (TOTAL_STACK=" + TOTAL_STACK + ")");
    assert(typeof Int32Array !== "undefined" && typeof Float64Array !== "undefined" && Int32Array.prototype.subarray !== undefined && Int32Array.prototype.set !== undefined, "JS engine does not provide full typed array support");

    if (Module["buffer"]) {
      buffer = Module["buffer"];
      assert(buffer.byteLength === TOTAL_MEMORY, "provided buffer should be " + TOTAL_MEMORY + " bytes, but it is " + buffer.byteLength);
    } else {
      if (typeof WebAssembly === "object" && typeof WebAssembly.Memory === "function") {
        assert(TOTAL_MEMORY % WASM_PAGE_SIZE === 0);
        Module["wasmMemory"] = new WebAssembly.Memory({
          "initial": TOTAL_MEMORY / WASM_PAGE_SIZE
        });
        buffer = Module["wasmMemory"].buffer;
      } else {
        buffer = new ArrayBuffer(TOTAL_MEMORY);
      }

      assert(buffer.byteLength === TOTAL_MEMORY);
      Module["buffer"] = buffer;
    }

    updateGlobalBufferViews();

    function getTotalMemory() {
      return TOTAL_MEMORY;
    }

    HEAP32[0] = 1668509029;
    HEAP16[1] = 25459;
    if (HEAPU8[2] !== 115 || HEAPU8[3] !== 99) throw "Runtime error: expected the system to be little-endian!";

    function callRuntimeCallbacks(callbacks) {
      while (callbacks.length > 0) {
        var callback = callbacks.shift();

        if (typeof callback == "function") {
          callback();
          continue;
        }

        var func = callback.func;

        if (typeof func === "number") {
          if (callback.arg === undefined) {
            Module["dynCall_v"](func);
          } else {
            Module["dynCall_vi"](func, callback.arg);
          }
        } else {
          func(callback.arg === undefined ? null : callback.arg);
        }
      }
    }

    var __ATPRERUN__ = [];
    var __ATINIT__ = [];
    var __ATMAIN__ = [];
    var __ATEXIT__ = [];
    var __ATPOSTRUN__ = [];
    var runtimeInitialized = false;
    var runtimeExited = false;

    function preRun() {
      if (Module["preRun"]) {
        if (typeof Module["preRun"] == "function") Module["preRun"] = [Module["preRun"]];

        while (Module["preRun"].length) {
          addOnPreRun(Module["preRun"].shift());
        }
      }

      callRuntimeCallbacks(__ATPRERUN__);
    }

    function ensureInitRuntime() {
      checkStackCookie();
      if (runtimeInitialized) return;
      runtimeInitialized = true;
      callRuntimeCallbacks(__ATINIT__);
    }

    function preMain() {
      checkStackCookie();
      callRuntimeCallbacks(__ATMAIN__);
    }

    function exitRuntime() {
      checkStackCookie();
      callRuntimeCallbacks(__ATEXIT__);
      runtimeExited = true;
    }

    function postRun() {
      checkStackCookie();

      if (Module["postRun"]) {
        if (typeof Module["postRun"] == "function") Module["postRun"] = [Module["postRun"]];

        while (Module["postRun"].length) {
          addOnPostRun(Module["postRun"].shift());
        }
      }

      callRuntimeCallbacks(__ATPOSTRUN__);
    }

    function addOnPreRun(cb) {
      __ATPRERUN__.unshift(cb);
    }

    function addOnPostRun(cb) {
      __ATPOSTRUN__.unshift(cb);
    }

    function writeArrayToMemory(array, buffer) {
      assert(array.length >= 0, "writeArrayToMemory array must have a length (should be an array or typed array)");
      HEAP8.set(array, buffer);
    }

    function writeAsciiToMemory(str, buffer, dontAddNull) {
      for (var i = 0; i < str.length; ++i) {
        assert(str.charCodeAt(i) === str.charCodeAt(i) & 255);
        HEAP8[buffer++ >> 0] = str.charCodeAt(i);
      }

      if (!dontAddNull) HEAP8[buffer >> 0] = 0;
    }

    assert(Math["imul"] && Math["fround"] && Math["clz32"] && Math["trunc"], "this is a legacy browser, build with LEGACY_VM_SUPPORT");
    var Math_abs = Math.abs;
    var Math_ceil = Math.ceil;
    var Math_floor = Math.floor;
    var Math_min = Math.min;
    var runDependencies = 0;
    var runDependencyWatcher = null;
    var dependenciesFulfilled = null;
    var runDependencyTracking = {};

    function getUniqueRunDependency(id) {
      var orig = id;

      while (1) {
        if (!runDependencyTracking[id]) return id;
        id = orig + Math.random();
      }

      return id;
    }

    function addRunDependency(id) {
      runDependencies++;

      if (Module["monitorRunDependencies"]) {
        Module["monitorRunDependencies"](runDependencies);
      }

      if (id) {
        assert(!runDependencyTracking[id]);
        runDependencyTracking[id] = 1;

        if (runDependencyWatcher === null && typeof setInterval !== "undefined") {
          runDependencyWatcher = setInterval(function () {
            if (ABORT) {
              clearInterval(runDependencyWatcher);
              runDependencyWatcher = null;
              return;
            }

            var shown = false;

            for (var dep in runDependencyTracking) {
              if (!shown) {
                shown = true;
                err("still waiting on run dependencies:");
              }

              err("dependency: " + dep);
            }

            if (shown) {
              err("(end of list)");
            }
          }, 1e4);
        }
      } else {
        err("warning: run dependency added without ID");
      }
    }

    function removeRunDependency(id) {
      runDependencies--;

      if (Module["monitorRunDependencies"]) {
        Module["monitorRunDependencies"](runDependencies);
      }

      if (id) {
        assert(runDependencyTracking[id]);
        delete runDependencyTracking[id];
      } else {
        err("warning: run dependency removed without ID");
      }

      if (runDependencies == 0) {
        if (runDependencyWatcher !== null) {
          clearInterval(runDependencyWatcher);
          runDependencyWatcher = null;
        }

        if (dependenciesFulfilled) {
          var callback = dependenciesFulfilled;
          dependenciesFulfilled = null;
          callback();
        }
      }
    }

    Module["preloadedImages"] = {};
    Module["preloadedAudios"] = {};
    var dataURIPrefix = "data:application/octet-stream;base64,";

    function isDataURI(filename) {
      return String.prototype.startsWith ? filename.startsWith(dataURIPrefix) : filename.indexOf(dataURIPrefix) === 0;
    }

    function integrateWasmJS() {
      var wasmTextFile = "encryption.wast";
      var wasmBinaryFile = "encryption.wasm";
      var asmjsCodeFile = "encryption.temp.asm.js";

      if (!isDataURI(wasmTextFile)) {
        wasmTextFile = locateFile(wasmTextFile);
      }

      if (!isDataURI(wasmBinaryFile)) {
        wasmBinaryFile = locateFile(wasmBinaryFile);
      }

      if (!isDataURI(asmjsCodeFile)) {
        asmjsCodeFile = locateFile(asmjsCodeFile);
      }

      var wasmPageSize = 64 * 1024;
      var info = {
        "global": null,
        "env": null,
        "asm2wasm": asm2wasmImports,
        "parent": Module
      };
      var exports = null;

      function mergeMemory(newBuffer) {
        var oldBuffer = Module["buffer"];

        if (newBuffer.byteLength < oldBuffer.byteLength) {
          err("the new buffer in mergeMemory is smaller than the previous one. in native wasm, we should grow memory here");
        }

        var oldView = new Int8Array(oldBuffer);
        var newView = new Int8Array(newBuffer);
        newView.set(oldView);
        updateGlobalBuffer(newBuffer);
        updateGlobalBufferViews();
      }

      function fixImports(imports) {
        return imports;
      }

      function getBinary() {
        try {
          if (Module["wasmBinary"]) {
            return new Uint8Array(Module["wasmBinary"]);
          }

          if (Module["readBinary"]) {
            return Module["readBinary"](wasmBinaryFile);
          } else {
            throw "both async and sync fetching of the wasm failed";
          }
        } catch (err) {
          abort(err);
        }
      }

      function getBinaryPromise() {
        if (!Module["wasmBinary"] && (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER) && typeof fetch === "function") {
          return fetch(wasmBinaryFile, {
            credentials: "same-origin"
          }).then(function (response) {
            if (!response["ok"]) {
              throw "failed to load wasm binary file at '" + wasmBinaryFile + "'";
            }

            return response["arrayBuffer"]();
          }).catch(function () {
            return getBinary();
          });
        }

        return new Promise(function (resolve, reject) {
          resolve(getBinary());
        });
      }

      function doNativeWasm(global, env, providedBuffer) {
        if (typeof WebAssembly !== "object") {
          abort("No WebAssembly support found. Build with -s WASM=0 to target JavaScript instead.");
          err("no native wasm support detected");
          return false;
        }

        if (!(Module["wasmMemory"] instanceof WebAssembly.Memory)) {
          err("no native wasm Memory in use");
          return false;
        }

        env["memory"] = Module["wasmMemory"];
        info["global"] = {
          "NaN": NaN,
          "Infinity": Infinity
        };
        info["global.Math"] = Math;
        info["env"] = env;

        function receiveInstance(instance, module) {
          exports = instance.exports;
          if (exports.memory) mergeMemory(exports.memory);
          Module["asm"] = exports;
          Module["usingWasm"] = true;
          removeRunDependency("wasm-instantiate");
        }

        addRunDependency("wasm-instantiate");

        if (Module["instantiateWasm"]) {
          try {
            return Module["instantiateWasm"](info, receiveInstance);
          } catch (e) {
            err("Module.instantiateWasm callback failed with error: " + e);
            return false;
          }
        }

        var trueModule = Module;

        function receiveInstantiatedSource(output) {
          assert(Module === trueModule, "the Module object should not be replaced during async compilation - perhaps the order of HTML elements is wrong?");
          trueModule = null;
          receiveInstance(output["instance"], output["module"]);
        }

        function instantiateArrayBuffer(receiver) {
          getBinaryPromise().then(function (binary) {
            return WebAssembly.instantiate(binary, info);
          }).then(receiver).catch(function (reason) {
            err("failed to asynchronously prepare wasm: " + reason);
            abort(reason);
          });
        }

        if (!Module["wasmBinary"] && typeof WebAssembly.instantiateStreaming === "function" && !isDataURI(wasmBinaryFile) && typeof fetch === "function") {
          WebAssembly.instantiateStreaming(fetch(wasmBinaryFile, {
            credentials: "same-origin"
          }), info).then(receiveInstantiatedSource).catch(function (reason) {
            err("wasm streaming compile failed: " + reason);
            err("falling back to ArrayBuffer instantiation");
            instantiateArrayBuffer(receiveInstantiatedSource);
          });
        } else {
          instantiateArrayBuffer(receiveInstantiatedSource);
        }

        return {};
      }

      Module["asmPreload"] = Module["asm"];
      var asmjsReallocBuffer = Module["reallocBuffer"];

      var wasmReallocBuffer = function wasmReallocBuffer(size) {
        var PAGE_MULTIPLE = Module["usingWasm"] ? WASM_PAGE_SIZE : ASMJS_PAGE_SIZE;
        size = alignUp(size, PAGE_MULTIPLE);
        var old = Module["buffer"];
        var oldSize = old.byteLength;

        if (Module["usingWasm"]) {
          try {
            var result = Module["wasmMemory"].grow((size - oldSize) / wasmPageSize);

            if (result !== (-1 | 0)) {
              return Module["buffer"] = Module["wasmMemory"].buffer;
            } else {
              return null;
            }
          } catch (e) {
            console.error("Module.reallocBuffer: Attempted to grow from " + oldSize + " bytes to " + size + " bytes, but got error: " + e);
            return null;
          }
        }
      };

      Module["reallocBuffer"] = function (size) {
        if (finalMethod === "asmjs") {
          return asmjsReallocBuffer(size);
        } else {
          return wasmReallocBuffer(size);
        }
      };

      var finalMethod = "";

      Module["asm"] = function (global, env, providedBuffer) {
        env = fixImports(env);

        if (!env["table"]) {
          var TABLE_SIZE = Module["wasmTableSize"];
          if (TABLE_SIZE === undefined) TABLE_SIZE = 1024;
          var MAX_TABLE_SIZE = Module["wasmMaxTableSize"];

          if (typeof WebAssembly === "object" && typeof WebAssembly.Table === "function") {
            if (MAX_TABLE_SIZE !== undefined) {
              env["table"] = new WebAssembly.Table({
                "initial": TABLE_SIZE,
                "maximum": MAX_TABLE_SIZE,
                "element": "anyfunc"
              });
            } else {
              env["table"] = new WebAssembly.Table({
                "initial": TABLE_SIZE,
                element: "anyfunc"
              });
            }
          } else {
            env["table"] = new Array(TABLE_SIZE);
          }

          Module["wasmTable"] = env["table"];
        }

        if (!env["memoryBase"]) {
          env["memoryBase"] = Module["STATIC_BASE"];
        }

        if (!env["tableBase"]) {
          env["tableBase"] = 0;
        }

        var exports;
        exports = doNativeWasm(global, env, providedBuffer);
        assert(exports, "no binaryen method succeeded. consider enabling more options, like interpreting, if you want that: https://github.com/kripken/emscripten/wiki/WebAssembly#binaryen-methods");
        return exports;
      };
    }

    integrateWasmJS();
    STATIC_BASE = GLOBAL_BASE;
    STATICTOP = STATIC_BASE + 72144;

    __ATINIT__.push({
      func: function func() {
        __GLOBAL__sub_I_worker_cpp();
      }
    }, {
      func: function func() {
        __GLOBAL__sub_I_bind_cpp();
      }
    }, {
      func: function func() {
        ___emscripten_environ_constructor();
      }
    });

    var STATIC_BUMP = 72144;
    Module["STATIC_BASE"] = STATIC_BASE;
    Module["STATIC_BUMP"] = STATIC_BUMP;
    var tempDoublePtr = STATICTOP;
    STATICTOP += 16;
    assert(tempDoublePtr % 8 == 0);

    function _emscripten_set_main_loop_timing(mode, value) {
      Browser.mainLoop.timingMode = mode;
      Browser.mainLoop.timingValue = value;

      if (!Browser.mainLoop.func) {
        console.error("emscripten_set_main_loop_timing: Cannot set timing mode for main loop since a main loop does not exist! Call emscripten_set_main_loop first to set one up.");
        return 1;
      }

      if (mode == 0) {
        Browser.mainLoop.scheduler = function Browser_mainLoop_scheduler_setTimeout() {
          var timeUntilNextTick = Math.max(0, Browser.mainLoop.tickStartTime + value - _emscripten_get_now()) | 0;
          setTimeout(Browser.mainLoop.runner, timeUntilNextTick);
        };

        Browser.mainLoop.method = "timeout";
      } else if (mode == 1) {
        Browser.mainLoop.scheduler = function Browser_mainLoop_scheduler_rAF() {
          Browser.requestAnimationFrame(Browser.mainLoop.runner);
        };

        Browser.mainLoop.method = "rAF";
      } else if (mode == 2) {
        if (typeof setImmediate === "undefined") {
          var Browser_setImmediate_messageHandler = function Browser_setImmediate_messageHandler(event) {
            if (event.data === emscriptenMainLoopMessageId || event.data.target === emscriptenMainLoopMessageId) {
              event.stopPropagation();
              setImmediates.shift()();
            }
          };

          var setImmediates = [];
          var emscriptenMainLoopMessageId = "setimmediate";
          addEventListener("message", Browser_setImmediate_messageHandler, true);

          setImmediate = function Browser_emulated_setImmediate(func) {
            setImmediates.push(func);

            if (ENVIRONMENT_IS_WORKER) {
              if (Module["setImmediates"] === undefined) Module["setImmediates"] = [];
              Module["setImmediates"].push(func);
              postMessage({
                target: emscriptenMainLoopMessageId
              });
            } else postMessage(emscriptenMainLoopMessageId, "*");
          };
        }

        Browser.mainLoop.scheduler = function Browser_mainLoop_scheduler_setImmediate() {
          setImmediate(Browser.mainLoop.runner);
        };

        Browser.mainLoop.method = "immediate";
      }

      return 0;
    }

    function _emscripten_get_now() {
      abort();
    }

    function _emscripten_set_main_loop(func, fps, simulateInfiniteLoop, arg, noSetTiming) {
      Module["noExitRuntime"] = true;
      assert(!Browser.mainLoop.func, "emscripten_set_main_loop: there can only be one main loop function at once: call emscripten_cancel_main_loop to cancel the previous one before setting a new one with different parameters.");
      Browser.mainLoop.func = func;
      Browser.mainLoop.arg = arg;
      var browserIterationFunc;

      if (typeof arg !== "undefined") {
        browserIterationFunc = function browserIterationFunc() {
          Module["dynCall_vi"](func, arg);
        };
      } else {
        browserIterationFunc = function browserIterationFunc() {
          Module["dynCall_v"](func);
        };
      }

      var thisMainLoopId = Browser.mainLoop.currentlyRunningMainloop;

      Browser.mainLoop.runner = function Browser_mainLoop_runner() {
        if (ABORT) return;

        if (Browser.mainLoop.queue.length > 0) {
          var start = Date.now();
          var blocker = Browser.mainLoop.queue.shift();
          blocker.func(blocker.arg);

          if (Browser.mainLoop.remainingBlockers) {
            var remaining = Browser.mainLoop.remainingBlockers;
            var next = remaining % 1 == 0 ? remaining - 1 : Math.floor(remaining);

            if (blocker.counted) {
              Browser.mainLoop.remainingBlockers = next;
            } else {
              next = next + .5;
              Browser.mainLoop.remainingBlockers = (8 * remaining + next) / 9;
            }
          }

          console.log('main loop blocker "' + blocker.name + '" took ' + (Date.now() - start) + " ms");
          Browser.mainLoop.updateStatus();
          if (thisMainLoopId < Browser.mainLoop.currentlyRunningMainloop) return;
          setTimeout(Browser.mainLoop.runner, 0);
          return;
        }

        if (thisMainLoopId < Browser.mainLoop.currentlyRunningMainloop) return;
        Browser.mainLoop.currentFrameNumber = Browser.mainLoop.currentFrameNumber + 1 | 0;

        if (Browser.mainLoop.timingMode == 1 && Browser.mainLoop.timingValue > 1 && Browser.mainLoop.currentFrameNumber % Browser.mainLoop.timingValue != 0) {
          Browser.mainLoop.scheduler();
          return;
        } else if (Browser.mainLoop.timingMode == 0) {
          Browser.mainLoop.tickStartTime = _emscripten_get_now();
        }

        if (Browser.mainLoop.method === "timeout" && Module.ctx) {
          err("Looks like you are rendering without using requestAnimationFrame for the main loop. You should use 0 for the frame rate in emscripten_set_main_loop in order to use requestAnimationFrame, as that can greatly improve your frame rates!");
          Browser.mainLoop.method = "";
        }

        Browser.mainLoop.runIter(browserIterationFunc);
        checkStackCookie();
        if (thisMainLoopId < Browser.mainLoop.currentlyRunningMainloop) return;
        if (typeof SDL === "object" && SDL.audio && SDL.audio.queueNewAudioData) SDL.audio.queueNewAudioData();
        Browser.mainLoop.scheduler();
      };

      if (!noSetTiming) {
        if (fps && fps > 0) _emscripten_set_main_loop_timing(0, 1e3 / fps);else _emscripten_set_main_loop_timing(1, 1);
        Browser.mainLoop.scheduler();
      }

      if (simulateInfiniteLoop) {
        throw "SimulateInfiniteLoop";
      }
    }

    var Browser = {
      mainLoop: {
        scheduler: null,
        method: "",
        currentlyRunningMainloop: 0,
        func: null,
        arg: 0,
        timingMode: 0,
        timingValue: 0,
        currentFrameNumber: 0,
        queue: [],
        pause: function pause() {
          Browser.mainLoop.scheduler = null;
          Browser.mainLoop.currentlyRunningMainloop++;
        },
        resume: function resume() {
          Browser.mainLoop.currentlyRunningMainloop++;
          var timingMode = Browser.mainLoop.timingMode;
          var timingValue = Browser.mainLoop.timingValue;
          var func = Browser.mainLoop.func;
          Browser.mainLoop.func = null;

          _emscripten_set_main_loop(func, 0, false, Browser.mainLoop.arg, true);

          _emscripten_set_main_loop_timing(timingMode, timingValue);

          Browser.mainLoop.scheduler();
        },
        updateStatus: function updateStatus() {
          if (Module["setStatus"]) {
            var message = Module["statusMessage"] || "Please wait...";
            var remaining = Browser.mainLoop.remainingBlockers;
            var expected = Browser.mainLoop.expectedBlockers;

            if (remaining) {
              if (remaining < expected) {
                Module["setStatus"](message + " (" + (expected - remaining) + "/" + expected + ")");
              } else {
                Module["setStatus"](message);
              }
            } else {
              Module["setStatus"]("");
            }
          }
        },
        runIter: function runIter(func) {
          if (ABORT) return;

          if (Module["preMainLoop"]) {
            var preRet = Module["preMainLoop"]();

            if (preRet === false) {
              return;
            }
          }

          try {
            func();
          } catch (e) {
            if (e instanceof ExitStatus) {
              return;
            } else {
              if (e && typeof e === "object" && e.stack) err("exception thrown: " + [e, e.stack]);
              throw e;
            }
          }

          if (Module["postMainLoop"]) Module["postMainLoop"]();
        }
      },
      isFullscreen: false,
      pointerLock: false,
      moduleContextCreatedCallbacks: [],
      workers: [],
      init: function init() {
        if (!Module["preloadPlugins"]) Module["preloadPlugins"] = [];
        if (Browser.initted) return;
        Browser.initted = true;

        try {
          new Blob();
          Browser.hasBlobConstructor = true;
        } catch (e) {
          Browser.hasBlobConstructor = false;
          console.log("warning: no blob constructor, cannot create blobs with mimetypes");
        }

        Browser.BlobBuilder = typeof MozBlobBuilder != "undefined" ? MozBlobBuilder : typeof WebKitBlobBuilder != "undefined" ? WebKitBlobBuilder : !Browser.hasBlobConstructor ? console.log("warning: no BlobBuilder") : null;
        Browser.URLObject = typeof window != "undefined" ? window.URL ? window.URL : window.webkitURL : undefined;

        if (!Module.noImageDecoding && typeof Browser.URLObject === "undefined") {
          console.log("warning: Browser does not support creating object URLs. Built-in browser image decoding will not be available.");
          Module.noImageDecoding = true;
        }

        var imagePlugin = {};

        imagePlugin["canHandle"] = function imagePlugin_canHandle(name) {
          return !Module.noImageDecoding && /\.(jpg|jpeg|png|bmp)$/i.test(name);
        };

        imagePlugin["handle"] = function imagePlugin_handle(byteArray, name, onload, onerror) {
          var b = null;

          if (Browser.hasBlobConstructor) {
            try {
              b = new Blob([byteArray], {
                type: Browser.getMimetype(name)
              });

              if (b.size !== byteArray.length) {
                b = new Blob([new Uint8Array(byteArray).buffer], {
                  type: Browser.getMimetype(name)
                });
              }
            } catch (e) {
              warnOnce("Blob constructor present but fails: " + e + "; falling back to blob builder");
            }
          }

          if (!b) {
            var bb = new Browser.BlobBuilder();
            bb.append(new Uint8Array(byteArray).buffer);
            b = bb.getBlob();
          }

          var url = Browser.URLObject.createObjectURL(b);
          assert(typeof url == "string", "createObjectURL must return a url as a string");
          var img = new Image();

          img.onload = function img_onload() {
            assert(img.complete, "Image " + name + " could not be decoded");
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            Module["preloadedImages"][name] = canvas;
            Browser.URLObject.revokeObjectURL(url);
            if (onload) onload(byteArray);
          };

          img.onerror = function img_onerror(event) {
            console.log("Image " + url + " could not be decoded");
            if (onerror) onerror();
          };

          img.src = url;
        };

        Module["preloadPlugins"].push(imagePlugin);
        var audioPlugin = {};

        audioPlugin["canHandle"] = function audioPlugin_canHandle(name) {
          return !Module.noAudioDecoding && name.substr(-4) in {
            ".ogg": 1,
            ".wav": 1,
            ".mp3": 1
          };
        };

        audioPlugin["handle"] = function audioPlugin_handle(byteArray, name, onload, onerror) {
          var done = false;

          function finish(audio) {
            if (done) return;
            done = true;
            Module["preloadedAudios"][name] = audio;
            if (onload) onload(byteArray);
          }

          function fail() {
            if (done) return;
            done = true;
            Module["preloadedAudios"][name] = new Audio();
            if (onerror) onerror();
          }

          if (Browser.hasBlobConstructor) {
            try {
              var b = new Blob([byteArray], {
                type: Browser.getMimetype(name)
              });
            } catch (e) {
              return fail();
            }

            var url = Browser.URLObject.createObjectURL(b);
            assert(typeof url == "string", "createObjectURL must return a url as a string");
            var audio = new Audio();
            audio.addEventListener("canplaythrough", function () {
              finish(audio);
            }, false);

            audio.onerror = function audio_onerror(event) {
              if (done) return;
              console.log("warning: browser could not fully decode audio " + name + ", trying slower base64 approach");

              function encode64(data) {
                var BASE = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
                var PAD = "=";
                var ret = "";
                var leftchar = 0;
                var leftbits = 0;

                for (var i = 0; i < data.length; i++) {
                  leftchar = leftchar << 8 | data[i];
                  leftbits += 8;

                  while (leftbits >= 6) {
                    var curr = leftchar >> leftbits - 6 & 63;
                    leftbits -= 6;
                    ret += BASE[curr];
                  }
                }

                if (leftbits == 2) {
                  ret += BASE[(leftchar & 3) << 4];
                  ret += PAD + PAD;
                } else if (leftbits == 4) {
                  ret += BASE[(leftchar & 15) << 2];
                  ret += PAD;
                }

                return ret;
              }

              audio.src = "data:audio/x-" + name.substr(-3) + ";base64," + encode64(byteArray);
              finish(audio);
            };

            audio.src = url;
            Browser.safeSetTimeout(function () {
              finish(audio);
            }, 1e4);
          } else {
            return fail();
          }
        };

        Module["preloadPlugins"].push(audioPlugin);

        function pointerLockChange() {
          Browser.pointerLock = document["pointerLockElement"] === Module["canvas"] || document["mozPointerLockElement"] === Module["canvas"] || document["webkitPointerLockElement"] === Module["canvas"] || document["msPointerLockElement"] === Module["canvas"];
        }

        var canvas = Module["canvas"];

        if (canvas) {
          canvas.requestPointerLock = canvas["requestPointerLock"] || canvas["mozRequestPointerLock"] || canvas["webkitRequestPointerLock"] || canvas["msRequestPointerLock"] || function () {};

          canvas.exitPointerLock = document["exitPointerLock"] || document["mozExitPointerLock"] || document["webkitExitPointerLock"] || document["msExitPointerLock"] || function () {};

          canvas.exitPointerLock = canvas.exitPointerLock.bind(document);
          document.addEventListener("pointerlockchange", pointerLockChange, false);
          document.addEventListener("mozpointerlockchange", pointerLockChange, false);
          document.addEventListener("webkitpointerlockchange", pointerLockChange, false);
          document.addEventListener("mspointerlockchange", pointerLockChange, false);

          if (Module["elementPointerLock"]) {
            canvas.addEventListener("click", function (ev) {
              if (!Browser.pointerLock && Module["canvas"].requestPointerLock) {
                Module["canvas"].requestPointerLock();
                ev.preventDefault();
              }
            }, false);
          }
        }
      },
      createContext: function createContext(canvas, useWebGL, setInModule, webGLContextAttributes) {
        if (useWebGL && Module.ctx && canvas == Module.canvas) return Module.ctx;
        var ctx;
        var contextHandle;

        if (useWebGL) {
          var contextAttributes = {
            antialias: false,
            alpha: false
          };

          if (webGLContextAttributes) {
            for (var attribute in webGLContextAttributes) {
              contextAttributes[attribute] = webGLContextAttributes[attribute];
            }
          }

          contextHandle = GL.createContext(canvas, contextAttributes);

          if (contextHandle) {
            ctx = GL.getContext(contextHandle).GLctx;
          }
        } else {
          ctx = canvas.getContext("2d");
        }

        if (!ctx) return null;

        if (setInModule) {
          if (!useWebGL) assert(typeof GLctx === "undefined", "cannot set in module if GLctx is used, but we are a non-GL context that would replace it");
          Module.ctx = ctx;
          if (useWebGL) GL.makeContextCurrent(contextHandle);
          Module.useWebGL = useWebGL;
          Browser.moduleContextCreatedCallbacks.forEach(function (callback) {
            callback();
          });
          Browser.init();
        }

        return ctx;
      },
      destroyContext: function destroyContext(canvas, useWebGL, setInModule) {},
      fullscreenHandlersInstalled: false,
      lockPointer: undefined,
      resizeCanvas: undefined,
      requestFullscreen: function requestFullscreen(lockPointer, resizeCanvas, vrDevice) {
        Browser.lockPointer = lockPointer;
        Browser.resizeCanvas = resizeCanvas;
        Browser.vrDevice = vrDevice;
        if (typeof Browser.lockPointer === "undefined") Browser.lockPointer = true;
        if (typeof Browser.resizeCanvas === "undefined") Browser.resizeCanvas = false;
        if (typeof Browser.vrDevice === "undefined") Browser.vrDevice = null;
        var canvas = Module["canvas"];

        function fullscreenChange() {
          Browser.isFullscreen = false;
          var canvasContainer = canvas.parentNode;

          if ((document["fullscreenElement"] || document["mozFullScreenElement"] || document["msFullscreenElement"] || document["webkitFullscreenElement"] || document["webkitCurrentFullScreenElement"]) === canvasContainer) {
            canvas.exitFullscreen = document["exitFullscreen"] || document["cancelFullScreen"] || document["mozCancelFullScreen"] || document["msExitFullscreen"] || document["webkitCancelFullScreen"] || function () {};

            canvas.exitFullscreen = canvas.exitFullscreen.bind(document);
            if (Browser.lockPointer) canvas.requestPointerLock();
            Browser.isFullscreen = true;

            if (Browser.resizeCanvas) {
              Browser.setFullscreenCanvasSize();
            } else {
              Browser.updateCanvasDimensions(canvas);
            }
          } else {
            canvasContainer.parentNode.insertBefore(canvas, canvasContainer);
            canvasContainer.parentNode.removeChild(canvasContainer);

            if (Browser.resizeCanvas) {
              Browser.setWindowedCanvasSize();
            } else {
              Browser.updateCanvasDimensions(canvas);
            }
          }

          if (Module["onFullScreen"]) Module["onFullScreen"](Browser.isFullscreen);
          if (Module["onFullscreen"]) Module["onFullscreen"](Browser.isFullscreen);
        }

        if (!Browser.fullscreenHandlersInstalled) {
          Browser.fullscreenHandlersInstalled = true;
          document.addEventListener("fullscreenchange", fullscreenChange, false);
          document.addEventListener("mozfullscreenchange", fullscreenChange, false);
          document.addEventListener("webkitfullscreenchange", fullscreenChange, false);
          document.addEventListener("MSFullscreenChange", fullscreenChange, false);
        }

        var canvasContainer = document.createElement("div");
        canvas.parentNode.insertBefore(canvasContainer, canvas);
        canvasContainer.appendChild(canvas);
        canvasContainer.requestFullscreen = canvasContainer["requestFullscreen"] || canvasContainer["mozRequestFullScreen"] || canvasContainer["msRequestFullscreen"] || (canvasContainer["webkitRequestFullscreen"] ? function () {
          canvasContainer["webkitRequestFullscreen"](Element["ALLOW_KEYBOARD_INPUT"]);
        } : null) || (canvasContainer["webkitRequestFullScreen"] ? function () {
          canvasContainer["webkitRequestFullScreen"](Element["ALLOW_KEYBOARD_INPUT"]);
        } : null);

        if (vrDevice) {
          canvasContainer.requestFullscreen({
            vrDisplay: vrDevice
          });
        } else {
          canvasContainer.requestFullscreen();
        }
      },
      requestFullScreen: function requestFullScreen(lockPointer, resizeCanvas, vrDevice) {
        err("Browser.requestFullScreen() is deprecated. Please call Browser.requestFullscreen instead.");

        Browser.requestFullScreen = function (lockPointer, resizeCanvas, vrDevice) {
          return Browser.requestFullscreen(lockPointer, resizeCanvas, vrDevice);
        };

        return Browser.requestFullscreen(lockPointer, resizeCanvas, vrDevice);
      },
      nextRAF: 0,
      fakeRequestAnimationFrame: function fakeRequestAnimationFrame(func) {
        var now = Date.now();

        if (Browser.nextRAF === 0) {
          Browser.nextRAF = now + 1e3 / 60;
        } else {
          while (now + 2 >= Browser.nextRAF) {
            Browser.nextRAF += 1e3 / 60;
          }
        }

        var delay = Math.max(Browser.nextRAF - now, 0);
        setTimeout(func, delay);
      },
      requestAnimationFrame: function requestAnimationFrame(func) {
        if (typeof window === "undefined") {
          Browser.fakeRequestAnimationFrame(func);
        } else {
          if (!window.requestAnimationFrame) {
            window.requestAnimationFrame = window["requestAnimationFrame"] || window["mozRequestAnimationFrame"] || window["webkitRequestAnimationFrame"] || window["msRequestAnimationFrame"] || window["oRequestAnimationFrame"] || Browser.fakeRequestAnimationFrame;
          }

          window.requestAnimationFrame(func);
        }
      },
      safeCallback: function safeCallback(func) {
        return function () {
          if (!ABORT) return func.apply(null, arguments);
        };
      },
      allowAsyncCallbacks: true,
      queuedAsyncCallbacks: [],
      pauseAsyncCallbacks: function pauseAsyncCallbacks() {
        Browser.allowAsyncCallbacks = false;
      },
      resumeAsyncCallbacks: function resumeAsyncCallbacks() {
        Browser.allowAsyncCallbacks = true;

        if (Browser.queuedAsyncCallbacks.length > 0) {
          var callbacks = Browser.queuedAsyncCallbacks;
          Browser.queuedAsyncCallbacks = [];
          callbacks.forEach(function (func) {
            func();
          });
        }
      },
      safeRequestAnimationFrame: function safeRequestAnimationFrame(func) {
        return Browser.requestAnimationFrame(function () {
          if (ABORT) return;

          if (Browser.allowAsyncCallbacks) {
            func();
          } else {
            Browser.queuedAsyncCallbacks.push(func);
          }
        });
      },
      safeSetTimeout: function safeSetTimeout(func, timeout) {
        Module["noExitRuntime"] = true;
        return setTimeout(function () {
          if (ABORT) return;

          if (Browser.allowAsyncCallbacks) {
            func();
          } else {
            Browser.queuedAsyncCallbacks.push(func);
          }
        }, timeout);
      },
      safeSetInterval: function safeSetInterval(func, timeout) {
        Module["noExitRuntime"] = true;
        return setInterval(function () {
          if (ABORT) return;

          if (Browser.allowAsyncCallbacks) {
            func();
          }
        }, timeout);
      },
      getMimetype: function getMimetype(name) {
        return {
          "jpg": "image/jpeg",
          "jpeg": "image/jpeg",
          "png": "image/png",
          "bmp": "image/bmp",
          "ogg": "audio/ogg",
          "wav": "audio/wav",
          "mp3": "audio/mpeg"
        }[name.substr(name.lastIndexOf(".") + 1)];
      },
      getUserMedia: function getUserMedia(func) {
        if (!window.getUserMedia) {
          window.getUserMedia = navigator["getUserMedia"] || navigator["mozGetUserMedia"];
        }

        window.getUserMedia(func);
      },
      getMovementX: function getMovementX(event) {
        return event["movementX"] || event["mozMovementX"] || event["webkitMovementX"] || 0;
      },
      getMovementY: function getMovementY(event) {
        return event["movementY"] || event["mozMovementY"] || event["webkitMovementY"] || 0;
      },
      getMouseWheelDelta: function getMouseWheelDelta(event) {
        var delta = 0;

        switch (event.type) {
          case "DOMMouseScroll":
            delta = event.detail;
            break;

          case "mousewheel":
            delta = event.wheelDelta;
            break;

          case "wheel":
            delta = event["deltaY"];
            break;

          default:
            throw "unrecognized mouse wheel event: " + event.type;
        }

        return delta;
      },
      mouseX: 0,
      mouseY: 0,
      mouseMovementX: 0,
      mouseMovementY: 0,
      touches: {},
      lastTouches: {},
      calculateMouseEvent: function calculateMouseEvent(event) {
        if (Browser.pointerLock) {
          if (event.type != "mousemove" && "mozMovementX" in event) {
            Browser.mouseMovementX = Browser.mouseMovementY = 0;
          } else {
            Browser.mouseMovementX = Browser.getMovementX(event);
            Browser.mouseMovementY = Browser.getMovementY(event);
          }

          if (typeof SDL != "undefined") {
            Browser.mouseX = SDL.mouseX + Browser.mouseMovementX;
            Browser.mouseY = SDL.mouseY + Browser.mouseMovementY;
          } else {
            Browser.mouseX += Browser.mouseMovementX;
            Browser.mouseY += Browser.mouseMovementY;
          }
        } else {
          var rect = Module["canvas"].getBoundingClientRect();
          var cw = Module["canvas"].width;
          var ch = Module["canvas"].height;
          var scrollX = typeof window.scrollX !== "undefined" ? window.scrollX : window.pageXOffset;
          var scrollY = typeof window.scrollY !== "undefined" ? window.scrollY : window.pageYOffset;
          assert(typeof scrollX !== "undefined" && typeof scrollY !== "undefined", "Unable to retrieve scroll position, mouse positions likely broken.");

          if (event.type === "touchstart" || event.type === "touchend" || event.type === "touchmove") {
            var touch = event.touch;

            if (touch === undefined) {
              return;
            }

            var adjustedX = touch.pageX - (scrollX + rect.left);
            var adjustedY = touch.pageY - (scrollY + rect.top);
            adjustedX = adjustedX * (cw / rect.width);
            adjustedY = adjustedY * (ch / rect.height);
            var coords = {
              x: adjustedX,
              y: adjustedY
            };

            if (event.type === "touchstart") {
              Browser.lastTouches[touch.identifier] = coords;
              Browser.touches[touch.identifier] = coords;
            } else if (event.type === "touchend" || event.type === "touchmove") {
              var last = Browser.touches[touch.identifier];
              if (!last) last = coords;
              Browser.lastTouches[touch.identifier] = last;
              Browser.touches[touch.identifier] = coords;
            }

            return;
          }

          var x = event.pageX - (scrollX + rect.left);
          var y = event.pageY - (scrollY + rect.top);
          x = x * (cw / rect.width);
          y = y * (ch / rect.height);
          Browser.mouseMovementX = x - Browser.mouseX;
          Browser.mouseMovementY = y - Browser.mouseY;
          Browser.mouseX = x;
          Browser.mouseY = y;
        }
      },
      asyncLoad: function asyncLoad(url, onload, onerror, noRunDep) {
        var dep = !noRunDep ? getUniqueRunDependency("al " + url) : "";
        Module["readAsync"](url, function (arrayBuffer) {
          assert(arrayBuffer, 'Loading data file "' + url + '" failed (no arrayBuffer).');
          onload(new Uint8Array(arrayBuffer));
          if (dep) removeRunDependency(dep);
        }, function (event) {
          if (onerror) {
            onerror();
          } else {
            throw 'Loading data file "' + url + '" failed.';
          }
        });
        if (dep) addRunDependency(dep);
      },
      resizeListeners: [],
      updateResizeListeners: function updateResizeListeners() {
        var canvas = Module["canvas"];
        Browser.resizeListeners.forEach(function (listener) {
          listener(canvas.width, canvas.height);
        });
      },
      setCanvasSize: function setCanvasSize(width, height, noUpdates) {
        var canvas = Module["canvas"];
        Browser.updateCanvasDimensions(canvas, width, height);
        if (!noUpdates) Browser.updateResizeListeners();
      },
      windowedWidth: 0,
      windowedHeight: 0,
      setFullscreenCanvasSize: function setFullscreenCanvasSize() {
        if (typeof SDL != "undefined") {
          var flags = HEAPU32[SDL.screen >> 2];
          flags = flags | 8388608;
          HEAP32[SDL.screen >> 2] = flags;
        }

        Browser.updateCanvasDimensions(Module["canvas"]);
        Browser.updateResizeListeners();
      },
      setWindowedCanvasSize: function setWindowedCanvasSize() {
        if (typeof SDL != "undefined") {
          var flags = HEAPU32[SDL.screen >> 2];
          flags = flags & ~8388608;
          HEAP32[SDL.screen >> 2] = flags;
        }

        Browser.updateCanvasDimensions(Module["canvas"]);
        Browser.updateResizeListeners();
      },
      updateCanvasDimensions: function updateCanvasDimensions(canvas, wNative, hNative) {
        if (wNative && hNative) {
          canvas.widthNative = wNative;
          canvas.heightNative = hNative;
        } else {
          wNative = canvas.widthNative;
          hNative = canvas.heightNative;
        }

        var w = wNative;
        var h = hNative;

        if (Module["forcedAspectRatio"] && Module["forcedAspectRatio"] > 0) {
          if (w / h < Module["forcedAspectRatio"]) {
            w = Math.round(h * Module["forcedAspectRatio"]);
          } else {
            h = Math.round(w / Module["forcedAspectRatio"]);
          }
        }

        if ((document["fullscreenElement"] || document["mozFullScreenElement"] || document["msFullscreenElement"] || document["webkitFullscreenElement"] || document["webkitCurrentFullScreenElement"]) === canvas.parentNode && typeof screen != "undefined") {
          var factor = Math.min(screen.width / w, screen.height / h);
          w = Math.round(w * factor);
          h = Math.round(h * factor);
        }

        if (Browser.resizeCanvas) {
          if (canvas.width != w) canvas.width = w;
          if (canvas.height != h) canvas.height = h;

          if (typeof canvas.style != "undefined") {
            canvas.style.removeProperty("width");
            canvas.style.removeProperty("height");
          }
        } else {
          if (canvas.width != wNative) canvas.width = wNative;
          if (canvas.height != hNative) canvas.height = hNative;

          if (typeof canvas.style != "undefined") {
            if (w != wNative || h != hNative) {
              canvas.style.setProperty("width", w + "px", "important");
              canvas.style.setProperty("height", h + "px", "important");
            } else {
              canvas.style.removeProperty("width");
              canvas.style.removeProperty("height");
            }
          }
        }
      },
      wgetRequests: {},
      nextWgetRequestHandle: 0,
      getNextWgetRequestHandle: function getNextWgetRequestHandle() {
        var handle = Browser.nextWgetRequestHandle;
        Browser.nextWgetRequestHandle++;
        return handle;
      }
    };

    function _X509_ATTRIBUTE_free() {
      err("missing function: X509_ATTRIBUTE_free");
      abort(-1);
    }

    function _X509_SIG_free() {
      err("missing function: X509_SIG_free");
      abort(-1);
    }

    var ENV = {};

    function ___buildEnvironment(environ) {
      var MAX_ENV_VALUES = 64;
      var TOTAL_ENV_SIZE = 1024;
      var poolPtr;
      var envPtr;

      if (!___buildEnvironment.called) {
        ___buildEnvironment.called = true;
        ENV["USER"] = ENV["LOGNAME"] = "web_user";
        ENV["PATH"] = "/";
        ENV["PWD"] = "/";
        ENV["HOME"] = "/home/web_user";
        ENV["LANG"] = "C.UTF-8";
        ENV["_"] = Module["thisProgram"];
        poolPtr = getMemory(TOTAL_ENV_SIZE);
        envPtr = getMemory(MAX_ENV_VALUES * 4);
        HEAP32[envPtr >> 2] = poolPtr;
        HEAP32[environ >> 2] = envPtr;
      } else {
        envPtr = HEAP32[environ >> 2];
        poolPtr = HEAP32[envPtr >> 2];
      }

      var strings = [];
      var totalSize = 0;

      for (var key in ENV) {
        if (typeof ENV[key] === "string") {
          var line = key + "=" + ENV[key];
          strings.push(line);
          totalSize += line.length;
        }
      }

      if (totalSize > TOTAL_ENV_SIZE) {
        throw new Error("Environment size exceeded TOTAL_ENV_SIZE!");
      }

      var ptrSize = 4;

      for (var i = 0; i < strings.length; i++) {
        var line = strings[i];
        writeAsciiToMemory(line, poolPtr);
        HEAP32[envPtr + i * ptrSize >> 2] = poolPtr;
        poolPtr += line.length + 1;
      }

      HEAP32[envPtr + strings.length * ptrSize >> 2] = 0;
    }

    function __ZSt18uncaught_exceptionv() {
      return !!__ZSt18uncaught_exceptionv.uncaught_exception;
    }

    var EXCEPTIONS = {
      last: 0,
      caught: [],
      infos: {},
      deAdjust: function deAdjust(adjusted) {
        if (!adjusted || EXCEPTIONS.infos[adjusted]) return adjusted;

        for (var key in EXCEPTIONS.infos) {
          var ptr = +key;
          var info = EXCEPTIONS.infos[ptr];

          if (info.adjusted === adjusted) {
            return ptr;
          }
        }

        return adjusted;
      },
      addRef: function addRef(ptr) {
        if (!ptr) return;
        var info = EXCEPTIONS.infos[ptr];
        info.refcount++;
      },
      decRef: function decRef(ptr) {
        if (!ptr) return;
        var info = EXCEPTIONS.infos[ptr];
        assert(info.refcount > 0);
        info.refcount--;

        if (info.refcount === 0 && !info.rethrown) {
          if (info.destructor) {
            Module["dynCall_vi"](info.destructor, ptr);
          }

          delete EXCEPTIONS.infos[ptr];

          ___cxa_free_exception(ptr);
        }
      },
      clearRef: function clearRef(ptr) {
        if (!ptr) return;
        var info = EXCEPTIONS.infos[ptr];
        info.refcount = 0;
      }
    };

    function ___resumeException(ptr) {
      if (!EXCEPTIONS.last) {
        EXCEPTIONS.last = ptr;
      }

      throw ptr + " - Exception catching is disabled, this exception cannot be caught. Compile with -s DISABLE_EXCEPTION_CATCHING=0 or DISABLE_EXCEPTION_CATCHING=2 to catch.";
    }

    function ___cxa_find_matching_catch() {
      var thrown = EXCEPTIONS.last;

      if (!thrown) {
        return (setTempRet0(0), 0) | 0;
      }

      var info = EXCEPTIONS.infos[thrown];
      var throwntype = info.type;

      if (!throwntype) {
        return (setTempRet0(0), thrown) | 0;
      }

      var typeArray = Array.prototype.slice.call(arguments);
      var pointer = Module["___cxa_is_pointer_type"](throwntype);
      if (!___cxa_find_matching_catch.buffer) ___cxa_find_matching_catch.buffer = _malloc(4);
      HEAP32[___cxa_find_matching_catch.buffer >> 2] = thrown;
      thrown = ___cxa_find_matching_catch.buffer;

      for (var i = 0; i < typeArray.length; i++) {
        if (typeArray[i] && Module["___cxa_can_catch"](typeArray[i], throwntype, thrown)) {
          thrown = HEAP32[thrown >> 2];
          info.adjusted = thrown;
          return (setTempRet0(typeArray[i]), thrown) | 0;
        }
      }

      thrown = HEAP32[thrown >> 2];
      return (setTempRet0(throwntype), thrown) | 0;
    }

    function ___gxx_personality_v0() {}

    function ___lock() {}

    var ERRNO_CODES = {
      EPERM: 1,
      ENOENT: 2,
      ESRCH: 3,
      EINTR: 4,
      EIO: 5,
      ENXIO: 6,
      E2BIG: 7,
      ENOEXEC: 8,
      EBADF: 9,
      ECHILD: 10,
      EAGAIN: 11,
      EWOULDBLOCK: 11,
      ENOMEM: 12,
      EACCES: 13,
      EFAULT: 14,
      ENOTBLK: 15,
      EBUSY: 16,
      EEXIST: 17,
      EXDEV: 18,
      ENODEV: 19,
      ENOTDIR: 20,
      EISDIR: 21,
      EINVAL: 22,
      ENFILE: 23,
      EMFILE: 24,
      ENOTTY: 25,
      ETXTBSY: 26,
      EFBIG: 27,
      ENOSPC: 28,
      ESPIPE: 29,
      EROFS: 30,
      EMLINK: 31,
      EPIPE: 32,
      EDOM: 33,
      ERANGE: 34,
      ENOMSG: 42,
      EIDRM: 43,
      ECHRNG: 44,
      EL2NSYNC: 45,
      EL3HLT: 46,
      EL3RST: 47,
      ELNRNG: 48,
      EUNATCH: 49,
      ENOCSI: 50,
      EL2HLT: 51,
      EDEADLK: 35,
      ENOLCK: 37,
      EBADE: 52,
      EBADR: 53,
      EXFULL: 54,
      ENOANO: 55,
      EBADRQC: 56,
      EBADSLT: 57,
      EDEADLOCK: 35,
      EBFONT: 59,
      ENOSTR: 60,
      ENODATA: 61,
      ETIME: 62,
      ENOSR: 63,
      ENONET: 64,
      ENOPKG: 65,
      EREMOTE: 66,
      ENOLINK: 67,
      EADV: 68,
      ESRMNT: 69,
      ECOMM: 70,
      EPROTO: 71,
      EMULTIHOP: 72,
      EDOTDOT: 73,
      EBADMSG: 74,
      ENOTUNIQ: 76,
      EBADFD: 77,
      EREMCHG: 78,
      ELIBACC: 79,
      ELIBBAD: 80,
      ELIBSCN: 81,
      ELIBMAX: 82,
      ELIBEXEC: 83,
      ENOSYS: 38,
      ENOTEMPTY: 39,
      ENAMETOOLONG: 36,
      ELOOP: 40,
      EOPNOTSUPP: 95,
      EPFNOSUPPORT: 96,
      ECONNRESET: 104,
      ENOBUFS: 105,
      EAFNOSUPPORT: 97,
      EPROTOTYPE: 91,
      ENOTSOCK: 88,
      ENOPROTOOPT: 92,
      ESHUTDOWN: 108,
      ECONNREFUSED: 111,
      EADDRINUSE: 98,
      ECONNABORTED: 103,
      ENETUNREACH: 101,
      ENETDOWN: 100,
      ETIMEDOUT: 110,
      EHOSTDOWN: 112,
      EHOSTUNREACH: 113,
      EINPROGRESS: 115,
      EALREADY: 114,
      EDESTADDRREQ: 89,
      EMSGSIZE: 90,
      EPROTONOSUPPORT: 93,
      ESOCKTNOSUPPORT: 94,
      EADDRNOTAVAIL: 99,
      ENETRESET: 102,
      EISCONN: 106,
      ENOTCONN: 107,
      ETOOMANYREFS: 109,
      EUSERS: 87,
      EDQUOT: 122,
      ESTALE: 116,
      ENOTSUP: 95,
      ENOMEDIUM: 123,
      EILSEQ: 84,
      EOVERFLOW: 75,
      ECANCELED: 125,
      ENOTRECOVERABLE: 131,
      EOWNERDEAD: 130,
      ESTRPIPE: 86
    };
    var ERRNO_MESSAGES = {
      0: "Success",
      1: "Not super-user",
      2: "No such file or directory",
      3: "No such process",
      4: "Interrupted system call",
      5: "I/O error",
      6: "No such device or address",
      7: "Arg list too long",
      8: "Exec format error",
      9: "Bad file number",
      10: "No children",
      11: "No more processes",
      12: "Not enough core",
      13: "Permission denied",
      14: "Bad address",
      15: "Block device required",
      16: "Mount device busy",
      17: "File exists",
      18: "Cross-device link",
      19: "No such device",
      20: "Not a directory",
      21: "Is a directory",
      22: "Invalid argument",
      23: "Too many open files in system",
      24: "Too many open files",
      25: "Not a typewriter",
      26: "Text file busy",
      27: "File too large",
      28: "No space left on device",
      29: "Illegal seek",
      30: "Read only file system",
      31: "Too many links",
      32: "Broken pipe",
      33: "Math arg out of domain of func",
      34: "Math result not representable",
      35: "File locking deadlock error",
      36: "File or path name too long",
      37: "No record locks available",
      38: "Function not implemented",
      39: "Directory not empty",
      40: "Too many symbolic links",
      42: "No message of desired type",
      43: "Identifier removed",
      44: "Channel number out of range",
      45: "Level 2 not synchronized",
      46: "Level 3 halted",
      47: "Level 3 reset",
      48: "Link number out of range",
      49: "Protocol driver not attached",
      50: "No CSI structure available",
      51: "Level 2 halted",
      52: "Invalid exchange",
      53: "Invalid request descriptor",
      54: "Exchange full",
      55: "No anode",
      56: "Invalid request code",
      57: "Invalid slot",
      59: "Bad font file fmt",
      60: "Device not a stream",
      61: "No data (for no delay io)",
      62: "Timer expired",
      63: "Out of streams resources",
      64: "Machine is not on the network",
      65: "Package not installed",
      66: "The object is remote",
      67: "The link has been severed",
      68: "Advertise error",
      69: "Srmount error",
      70: "Communication error on send",
      71: "Protocol error",
      72: "Multihop attempted",
      73: "Cross mount point (not really error)",
      74: "Trying to read unreadable message",
      75: "Value too large for defined data type",
      76: "Given log. name not unique",
      77: "f.d. invalid for this operation",
      78: "Remote address changed",
      79: "Can   access a needed shared lib",
      80: "Accessing a corrupted shared lib",
      81: ".lib section in a.out corrupted",
      82: "Attempting to link in too many libs",
      83: "Attempting to exec a shared library",
      84: "Illegal byte sequence",
      86: "Streams pipe error",
      87: "Too many users",
      88: "Socket operation on non-socket",
      89: "Destination address required",
      90: "Message too long",
      91: "Protocol wrong type for socket",
      92: "Protocol not available",
      93: "Unknown protocol",
      94: "Socket type not supported",
      95: "Not supported",
      96: "Protocol family not supported",
      97: "Address family not supported by protocol family",
      98: "Address already in use",
      99: "Address not available",
      100: "Network interface is not configured",
      101: "Network is unreachable",
      102: "Connection reset by network",
      103: "Connection aborted",
      104: "Connection reset by peer",
      105: "No buffer space available",
      106: "Socket is already connected",
      107: "Socket is not connected",
      108: "Can't send after socket shutdown",
      109: "Too many references",
      110: "Connection timed out",
      111: "Connection refused",
      112: "Host is down",
      113: "Host is unreachable",
      114: "Socket already connected",
      115: "Connection already in progress",
      116: "Stale file handle",
      122: "Quota exceeded",
      123: "No medium (in tape drive)",
      125: "Operation canceled",
      130: "Previous owner died",
      131: "State not recoverable"
    };

    function ___setErrNo(value) {
      if (Module["___errno_location"]) HEAP32[Module["___errno_location"]() >> 2] = value;else err("failed to set errno from JS");
      return value;
    }

    var PATH = {
      splitPath: function splitPath(filename) {
        var splitPathRe = /^(\/?|)([\s\S]*?)((?:\.{1,2}|[^\/]+?|)(\.[^.\/]*|))(?:[\/]*)$/;
        return splitPathRe.exec(filename).slice(1);
      },
      normalizeArray: function normalizeArray(parts, allowAboveRoot) {
        var up = 0;

        for (var i = parts.length - 1; i >= 0; i--) {
          var last = parts[i];

          if (last === ".") {
            parts.splice(i, 1);
          } else if (last === "..") {
            parts.splice(i, 1);
            up++;
          } else if (up) {
            parts.splice(i, 1);
            up--;
          }
        }

        if (allowAboveRoot) {
          for (; up; up--) {
            parts.unshift("..");
          }
        }

        return parts;
      },
      normalize: function normalize(path) {
        var isAbsolute = path.charAt(0) === "/",
            trailingSlash = path.substr(-1) === "/";
        path = PATH.normalizeArray(path.split("/").filter(function (p) {
          return !!p;
        }), !isAbsolute).join("/");

        if (!path && !isAbsolute) {
          path = ".";
        }

        if (path && trailingSlash) {
          path += "/";
        }

        return (isAbsolute ? "/" : "") + path;
      },
      dirname: function dirname(path) {
        var result = PATH.splitPath(path),
            root = result[0],
            dir = result[1];

        if (!root && !dir) {
          return ".";
        }

        if (dir) {
          dir = dir.substr(0, dir.length - 1);
        }

        return root + dir;
      },
      basename: function basename(path) {
        if (path === "/") return "/";
        var lastSlash = path.lastIndexOf("/");
        if (lastSlash === -1) return path;
        return path.substr(lastSlash + 1);
      },
      extname: function extname(path) {
        return PATH.splitPath(path)[3];
      },
      join: function join() {
        var paths = Array.prototype.slice.call(arguments, 0);
        return PATH.normalize(paths.join("/"));
      },
      join2: function join2(l, r) {
        return PATH.normalize(l + "/" + r);
      },
      resolve: function resolve() {
        var resolvedPath = "",
            resolvedAbsolute = false;

        for (var i = arguments.length - 1; i >= -1 && !resolvedAbsolute; i--) {
          var path = i >= 0 ? arguments[i] : FS.cwd();

          if (typeof path !== "string") {
            throw new TypeError("Arguments to path.resolve must be strings");
          } else if (!path) {
            return "";
          }

          resolvedPath = path + "/" + resolvedPath;
          resolvedAbsolute = path.charAt(0) === "/";
        }

        resolvedPath = PATH.normalizeArray(resolvedPath.split("/").filter(function (p) {
          return !!p;
        }), !resolvedAbsolute).join("/");
        return (resolvedAbsolute ? "/" : "") + resolvedPath || ".";
      },
      relative: function relative(from, to) {
        from = PATH.resolve(from).substr(1);
        to = PATH.resolve(to).substr(1);

        function trim(arr) {
          var start = 0;

          for (; start < arr.length; start++) {
            if (arr[start] !== "") break;
          }

          var end = arr.length - 1;

          for (; end >= 0; end--) {
            if (arr[end] !== "") break;
          }

          if (start > end) return [];
          return arr.slice(start, end - start + 1);
        }

        var fromParts = trim(from.split("/"));
        var toParts = trim(to.split("/"));
        var length = Math.min(fromParts.length, toParts.length);
        var samePartsLength = length;

        for (var i = 0; i < length; i++) {
          if (fromParts[i] !== toParts[i]) {
            samePartsLength = i;
            break;
          }
        }

        var outputParts = [];

        for (var i = samePartsLength; i < fromParts.length; i++) {
          outputParts.push("..");
        }

        outputParts = outputParts.concat(toParts.slice(samePartsLength));
        return outputParts.join("/");
      }
    };
    var TTY = {
      ttys: [],
      init: function init() {},
      shutdown: function shutdown() {},
      register: function register(dev, ops) {
        TTY.ttys[dev] = {
          input: [],
          output: [],
          ops: ops
        };
        FS.registerDevice(dev, TTY.stream_ops);
      },
      stream_ops: {
        open: function open(stream) {
          var tty = TTY.ttys[stream.node.rdev];

          if (!tty) {
            throw new FS.ErrnoError(ERRNO_CODES.ENODEV);
          }

          stream.tty = tty;
          stream.seekable = false;
        },
        close: function close(stream) {
          stream.tty.ops.flush(stream.tty);
        },
        flush: function flush(stream) {
          stream.tty.ops.flush(stream.tty);
        },
        read: function read(stream, buffer, offset, length, pos) {
          if (!stream.tty || !stream.tty.ops.get_char) {
            throw new FS.ErrnoError(ERRNO_CODES.ENXIO);
          }

          var bytesRead = 0;

          for (var i = 0; i < length; i++) {
            var result;

            try {
              result = stream.tty.ops.get_char(stream.tty);
            } catch (e) {
              throw new FS.ErrnoError(ERRNO_CODES.EIO);
            }

            if (result === undefined && bytesRead === 0) {
              throw new FS.ErrnoError(ERRNO_CODES.EAGAIN);
            }

            if (result === null || result === undefined) break;
            bytesRead++;
            buffer[offset + i] = result;
          }

          if (bytesRead) {
            stream.node.timestamp = Date.now();
          }

          return bytesRead;
        },
        write: function write(stream, buffer, offset, length, pos) {
          if (!stream.tty || !stream.tty.ops.put_char) {
            throw new FS.ErrnoError(ERRNO_CODES.ENXIO);
          }

          for (var i = 0; i < length; i++) {
            try {
              stream.tty.ops.put_char(stream.tty, buffer[offset + i]);
            } catch (e) {
              throw new FS.ErrnoError(ERRNO_CODES.EIO);
            }
          }

          if (length) {
            stream.node.timestamp = Date.now();
          }

          return i;
        }
      },
      default_tty_ops: {
        get_char: function get_char(tty) {
          if (!tty.input.length) {
            var result = null;

            if (ENVIRONMENT_IS_NODE) {
              var BUFSIZE = 256;
              var buf = new Buffer(BUFSIZE);
              var bytesRead = 0;
              var isPosixPlatform = process.platform != "win32";
              var fd = process.stdin.fd;

              if (isPosixPlatform) {
                var usingDevice = false;

                try {
                  fd = fs.openSync("/dev/stdin", "r");
                  usingDevice = true;
                } catch (e) {}
              }

              try {
                bytesRead = fs.readSync(fd, buf, 0, BUFSIZE, null);
              } catch (e) {
                if (e.toString().indexOf("EOF") != -1) bytesRead = 0;else throw e;
              }

              if (usingDevice) {
                fs.closeSync(fd);
              }

              if (bytesRead > 0) {
                result = buf.slice(0, bytesRead).toString("utf-8");
              } else {
                result = null;
              }
            } else if (typeof window != "undefined" && typeof window.prompt == "function") {
              result = window.prompt("Input: ");

              if (result !== null) {
                result += "\n";
              }
            } else if (typeof readline == "function") {
              result = readline();

              if (result !== null) {
                result += "\n";
              }
            }

            if (!result) {
              return null;
            }

            tty.input = intArrayFromString(result, true);
          }

          return tty.input.shift();
        },
        put_char: function put_char(tty, val) {
          if (val === null || val === 10) {
            out(UTF8ArrayToString(tty.output, 0));
            tty.output = [];
          } else {
            if (val != 0) tty.output.push(val);
          }
        },
        flush: function flush(tty) {
          if (tty.output && tty.output.length > 0) {
            out(UTF8ArrayToString(tty.output, 0));
            tty.output = [];
          }
        }
      },
      default_tty1_ops: {
        put_char: function put_char(tty, val) {
          if (val === null || val === 10) {
            err(UTF8ArrayToString(tty.output, 0));
            tty.output = [];
          } else {
            if (val != 0) tty.output.push(val);
          }
        },
        flush: function flush(tty) {
          if (tty.output && tty.output.length > 0) {
            err(UTF8ArrayToString(tty.output, 0));
            tty.output = [];
          }
        }
      }
    };
    var MEMFS = {
      ops_table: null,
      mount: function mount(_mount) {
        return MEMFS.createNode(null, "/", 16384 | 511, 0);
      },
      createNode: function createNode(parent, name, mode, dev) {
        if (FS.isBlkdev(mode) || FS.isFIFO(mode)) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        if (!MEMFS.ops_table) {
          MEMFS.ops_table = {
            dir: {
              node: {
                getattr: MEMFS.node_ops.getattr,
                setattr: MEMFS.node_ops.setattr,
                lookup: MEMFS.node_ops.lookup,
                mknod: MEMFS.node_ops.mknod,
                rename: MEMFS.node_ops.rename,
                unlink: MEMFS.node_ops.unlink,
                rmdir: MEMFS.node_ops.rmdir,
                readdir: MEMFS.node_ops.readdir,
                symlink: MEMFS.node_ops.symlink
              },
              stream: {
                llseek: MEMFS.stream_ops.llseek
              }
            },
            file: {
              node: {
                getattr: MEMFS.node_ops.getattr,
                setattr: MEMFS.node_ops.setattr
              },
              stream: {
                llseek: MEMFS.stream_ops.llseek,
                read: MEMFS.stream_ops.read,
                write: MEMFS.stream_ops.write,
                allocate: MEMFS.stream_ops.allocate,
                mmap: MEMFS.stream_ops.mmap,
                msync: MEMFS.stream_ops.msync
              }
            },
            link: {
              node: {
                getattr: MEMFS.node_ops.getattr,
                setattr: MEMFS.node_ops.setattr,
                readlink: MEMFS.node_ops.readlink
              },
              stream: {}
            },
            chrdev: {
              node: {
                getattr: MEMFS.node_ops.getattr,
                setattr: MEMFS.node_ops.setattr
              },
              stream: FS.chrdev_stream_ops
            }
          };
        }

        var node = FS.createNode(parent, name, mode, dev);

        if (FS.isDir(node.mode)) {
          node.node_ops = MEMFS.ops_table.dir.node;
          node.stream_ops = MEMFS.ops_table.dir.stream;
          node.contents = {};
        } else if (FS.isFile(node.mode)) {
          node.node_ops = MEMFS.ops_table.file.node;
          node.stream_ops = MEMFS.ops_table.file.stream;
          node.usedBytes = 0;
          node.contents = null;
        } else if (FS.isLink(node.mode)) {
          node.node_ops = MEMFS.ops_table.link.node;
          node.stream_ops = MEMFS.ops_table.link.stream;
        } else if (FS.isChrdev(node.mode)) {
          node.node_ops = MEMFS.ops_table.chrdev.node;
          node.stream_ops = MEMFS.ops_table.chrdev.stream;
        }

        node.timestamp = Date.now();

        if (parent) {
          parent.contents[name] = node;
        }

        return node;
      },
      getFileDataAsRegularArray: function getFileDataAsRegularArray(node) {
        if (node.contents && node.contents.subarray) {
          var arr = [];

          for (var i = 0; i < node.usedBytes; ++i) {
            arr.push(node.contents[i]);
          }

          return arr;
        }

        return node.contents;
      },
      getFileDataAsTypedArray: function getFileDataAsTypedArray(node) {
        if (!node.contents) return new Uint8Array();
        if (node.contents.subarray) return node.contents.subarray(0, node.usedBytes);
        return new Uint8Array(node.contents);
      },
      expandFileStorage: function expandFileStorage(node, newCapacity) {
        if (node.contents && node.contents.subarray && newCapacity > node.contents.length) {
          node.contents = MEMFS.getFileDataAsRegularArray(node);
          node.usedBytes = node.contents.length;
        }

        if (!node.contents || node.contents.subarray) {
          var prevCapacity = node.contents ? node.contents.length : 0;
          if (prevCapacity >= newCapacity) return;
          var CAPACITY_DOUBLING_MAX = 1024 * 1024;
          newCapacity = Math.max(newCapacity, prevCapacity * (prevCapacity < CAPACITY_DOUBLING_MAX ? 2 : 1.125) | 0);
          if (prevCapacity != 0) newCapacity = Math.max(newCapacity, 256);
          var oldContents = node.contents;
          node.contents = new Uint8Array(newCapacity);
          if (node.usedBytes > 0) node.contents.set(oldContents.subarray(0, node.usedBytes), 0);
          return;
        }

        if (!node.contents && newCapacity > 0) node.contents = [];

        while (node.contents.length < newCapacity) {
          node.contents.push(0);
        }
      },
      resizeFileStorage: function resizeFileStorage(node, newSize) {
        if (node.usedBytes == newSize) return;

        if (newSize == 0) {
          node.contents = null;
          node.usedBytes = 0;
          return;
        }

        if (!node.contents || node.contents.subarray) {
          var oldContents = node.contents;
          node.contents = new Uint8Array(new ArrayBuffer(newSize));

          if (oldContents) {
            node.contents.set(oldContents.subarray(0, Math.min(newSize, node.usedBytes)));
          }

          node.usedBytes = newSize;
          return;
        }

        if (!node.contents) node.contents = [];
        if (node.contents.length > newSize) node.contents.length = newSize;else while (node.contents.length < newSize) {
          node.contents.push(0);
        }
        node.usedBytes = newSize;
      },
      node_ops: {
        getattr: function getattr(node) {
          var attr = {};
          attr.dev = FS.isChrdev(node.mode) ? node.id : 1;
          attr.ino = node.id;
          attr.mode = node.mode;
          attr.nlink = 1;
          attr.uid = 0;
          attr.gid = 0;
          attr.rdev = node.rdev;

          if (FS.isDir(node.mode)) {
            attr.size = 4096;
          } else if (FS.isFile(node.mode)) {
            attr.size = node.usedBytes;
          } else if (FS.isLink(node.mode)) {
            attr.size = node.link.length;
          } else {
            attr.size = 0;
          }

          attr.atime = new Date(node.timestamp);
          attr.mtime = new Date(node.timestamp);
          attr.ctime = new Date(node.timestamp);
          attr.blksize = 4096;
          attr.blocks = Math.ceil(attr.size / attr.blksize);
          return attr;
        },
        setattr: function setattr(node, attr) {
          if (attr.mode !== undefined) {
            node.mode = attr.mode;
          }

          if (attr.timestamp !== undefined) {
            node.timestamp = attr.timestamp;
          }

          if (attr.size !== undefined) {
            MEMFS.resizeFileStorage(node, attr.size);
          }
        },
        lookup: function lookup(parent, name) {
          throw FS.genericErrors[ERRNO_CODES.ENOENT];
        },
        mknod: function mknod(parent, name, mode, dev) {
          return MEMFS.createNode(parent, name, mode, dev);
        },
        rename: function rename(old_node, new_dir, new_name) {
          if (FS.isDir(old_node.mode)) {
            var new_node;

            try {
              new_node = FS.lookupNode(new_dir, new_name);
            } catch (e) {}

            if (new_node) {
              for (var i in new_node.contents) {
                throw new FS.ErrnoError(ERRNO_CODES.ENOTEMPTY);
              }
            }
          }

          delete old_node.parent.contents[old_node.name];
          old_node.name = new_name;
          new_dir.contents[new_name] = old_node;
          old_node.parent = new_dir;
        },
        unlink: function unlink(parent, name) {
          delete parent.contents[name];
        },
        rmdir: function rmdir(parent, name) {
          var node = FS.lookupNode(parent, name);

          for (var i in node.contents) {
            throw new FS.ErrnoError(ERRNO_CODES.ENOTEMPTY);
          }

          delete parent.contents[name];
        },
        readdir: function readdir(node) {
          var entries = [".", ".."];

          for (var key in node.contents) {
            if (!node.contents.hasOwnProperty(key)) {
              continue;
            }

            entries.push(key);
          }

          return entries;
        },
        symlink: function symlink(parent, newname, oldpath) {
          var node = MEMFS.createNode(parent, newname, 511 | 40960, 0);
          node.link = oldpath;
          return node;
        },
        readlink: function readlink(node) {
          if (!FS.isLink(node.mode)) {
            throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
          }

          return node.link;
        }
      },
      stream_ops: {
        read: function read(stream, buffer, offset, length, position) {
          var contents = stream.node.contents;
          if (position >= stream.node.usedBytes) return 0;
          var size = Math.min(stream.node.usedBytes - position, length);
          assert(size >= 0);

          if (size > 8 && contents.subarray) {
            buffer.set(contents.subarray(position, position + size), offset);
          } else {
            for (var i = 0; i < size; i++) {
              buffer[offset + i] = contents[position + i];
            }
          }

          return size;
        },
        write: function write(stream, buffer, offset, length, position, canOwn) {
          if (!length) return 0;
          var node = stream.node;
          node.timestamp = Date.now();

          if (buffer.subarray && (!node.contents || node.contents.subarray)) {
            if (canOwn) {
              assert(position === 0, "canOwn must imply no weird position inside the file");
              node.contents = buffer.subarray(offset, offset + length);
              node.usedBytes = length;
              return length;
            } else if (node.usedBytes === 0 && position === 0) {
              node.contents = new Uint8Array(buffer.subarray(offset, offset + length));
              node.usedBytes = length;
              return length;
            } else if (position + length <= node.usedBytes) {
              node.contents.set(buffer.subarray(offset, offset + length), position);
              return length;
            }
          }

          MEMFS.expandFileStorage(node, position + length);
          if (node.contents.subarray && buffer.subarray) node.contents.set(buffer.subarray(offset, offset + length), position);else {
            for (var i = 0; i < length; i++) {
              node.contents[position + i] = buffer[offset + i];
            }
          }
          node.usedBytes = Math.max(node.usedBytes, position + length);
          return length;
        },
        llseek: function llseek(stream, offset, whence) {
          var position = offset;

          if (whence === 1) {
            position += stream.position;
          } else if (whence === 2) {
            if (FS.isFile(stream.node.mode)) {
              position += stream.node.usedBytes;
            }
          }

          if (position < 0) {
            throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
          }

          return position;
        },
        allocate: function allocate(stream, offset, length) {
          MEMFS.expandFileStorage(stream.node, offset + length);
          stream.node.usedBytes = Math.max(stream.node.usedBytes, offset + length);
        },
        mmap: function mmap(stream, buffer, offset, length, position, prot, flags) {
          if (!FS.isFile(stream.node.mode)) {
            throw new FS.ErrnoError(ERRNO_CODES.ENODEV);
          }

          var ptr;
          var allocated;
          var contents = stream.node.contents;

          if (!(flags & 2) && (contents.buffer === buffer || contents.buffer === buffer.buffer)) {
            allocated = false;
            ptr = contents.byteOffset;
          } else {
            if (position > 0 || position + length < stream.node.usedBytes) {
              if (contents.subarray) {
                contents = contents.subarray(position, position + length);
              } else {
                contents = Array.prototype.slice.call(contents, position, position + length);
              }
            }

            allocated = true;
            ptr = _malloc(length);

            if (!ptr) {
              throw new FS.ErrnoError(ERRNO_CODES.ENOMEM);
            }

            buffer.set(contents, ptr);
          }

          return {
            ptr: ptr,
            allocated: allocated
          };
        },
        msync: function msync(stream, buffer, offset, length, mmapFlags) {
          if (!FS.isFile(stream.node.mode)) {
            throw new FS.ErrnoError(ERRNO_CODES.ENODEV);
          }

          if (mmapFlags & 2) {
            return 0;
          }

          var bytesWritten = MEMFS.stream_ops.write(stream, buffer, 0, length, offset, false);
          return 0;
        }
      }
    };
    var IDBFS = {
      dbs: {},
      indexedDB: function (_indexedDB) {
        function indexedDB() {
          return _indexedDB.apply(this, arguments);
        }

        indexedDB.toString = function () {
          return _indexedDB.toString();
        };

        return indexedDB;
      }(function () {
        if (typeof indexedDB !== "undefined") return indexedDB;
        var ret = null;
        if (typeof window === "object") ret = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
        assert(ret, "IDBFS used, but indexedDB not supported");
        return ret;
      }),
      DB_VERSION: 21,
      DB_STORE_NAME: "FILE_DATA",
      mount: function mount(_mount2) {
        return MEMFS.mount.apply(null, arguments);
      },
      syncfs: function syncfs(mount, populate, callback) {
        IDBFS.getLocalSet(mount, function (err, local) {
          if (err) return callback(err);
          IDBFS.getRemoteSet(mount, function (err, remote) {
            if (err) return callback(err);
            var src = populate ? remote : local;
            var dst = populate ? local : remote;
            IDBFS.reconcile(src, dst, callback);
          });
        });
      },
      getDB: function getDB(name, callback) {
        var db = IDBFS.dbs[name];

        if (db) {
          return callback(null, db);
        }

        var req;

        try {
          req = IDBFS.indexedDB().open(name, IDBFS.DB_VERSION);
        } catch (e) {
          return callback(e);
        }

        if (!req) {
          return callback("Unable to connect to IndexedDB");
        }

        req.onupgradeneeded = function (e) {
          var db = e.target.result;
          var transaction = e.target.transaction;
          var fileStore;

          if (db.objectStoreNames.contains(IDBFS.DB_STORE_NAME)) {
            fileStore = transaction.objectStore(IDBFS.DB_STORE_NAME);
          } else {
            fileStore = db.createObjectStore(IDBFS.DB_STORE_NAME);
          }

          if (!fileStore.indexNames.contains("timestamp")) {
            fileStore.createIndex("timestamp", "timestamp", {
              unique: false
            });
          }
        };

        req.onsuccess = function () {
          db = req.result;
          IDBFS.dbs[name] = db;
          callback(null, db);
        };

        req.onerror = function (e) {
          callback(this.error);
          e.preventDefault();
        };
      },
      getLocalSet: function getLocalSet(mount, callback) {
        var entries = {};

        function isRealDir(p) {
          return p !== "." && p !== "..";
        }

        function toAbsolute(root) {
          return function (p) {
            return PATH.join2(root, p);
          };
        }

        var check = FS.readdir(mount.mountpoint).filter(isRealDir).map(toAbsolute(mount.mountpoint));

        while (check.length) {
          var path = check.pop();
          var stat;

          try {
            stat = FS.stat(path);
          } catch (e) {
            return callback(e);
          }

          if (FS.isDir(stat.mode)) {
            check.push.apply(check, FS.readdir(path).filter(isRealDir).map(toAbsolute(path)));
          }

          entries[path] = {
            timestamp: stat.mtime
          };
        }

        return callback(null, {
          type: "local",
          entries: entries
        });
      },
      getRemoteSet: function getRemoteSet(mount, callback) {
        var entries = {};
        IDBFS.getDB(mount.mountpoint, function (err, db) {
          if (err) return callback(err);

          try {
            var transaction = db.transaction([IDBFS.DB_STORE_NAME], "readonly");

            transaction.onerror = function (e) {
              callback(this.error);
              e.preventDefault();
            };

            var store = transaction.objectStore(IDBFS.DB_STORE_NAME);
            var index = store.index("timestamp");

            index.openKeyCursor().onsuccess = function (event) {
              var cursor = event.target.result;

              if (!cursor) {
                return callback(null, {
                  type: "remote",
                  db: db,
                  entries: entries
                });
              }

              entries[cursor.primaryKey] = {
                timestamp: cursor.key
              };
              cursor.continue();
            };
          } catch (e) {
            return callback(e);
          }
        });
      },
      loadLocalEntry: function loadLocalEntry(path, callback) {
        var stat, node;

        try {
          var lookup = FS.lookupPath(path);
          node = lookup.node;
          stat = FS.stat(path);
        } catch (e) {
          return callback(e);
        }

        if (FS.isDir(stat.mode)) {
          return callback(null, {
            timestamp: stat.mtime,
            mode: stat.mode
          });
        } else if (FS.isFile(stat.mode)) {
          node.contents = MEMFS.getFileDataAsTypedArray(node);
          return callback(null, {
            timestamp: stat.mtime,
            mode: stat.mode,
            contents: node.contents
          });
        } else {
          return callback(new Error("node type not supported"));
        }
      },
      storeLocalEntry: function storeLocalEntry(path, entry, callback) {
        try {
          if (FS.isDir(entry.mode)) {
            FS.mkdir(path, entry.mode);
          } else if (FS.isFile(entry.mode)) {
            FS.writeFile(path, entry.contents, {
              canOwn: true
            });
          } else {
            return callback(new Error("node type not supported"));
          }

          FS.chmod(path, entry.mode);
          FS.utime(path, entry.timestamp, entry.timestamp);
        } catch (e) {
          return callback(e);
        }

        callback(null);
      },
      removeLocalEntry: function removeLocalEntry(path, callback) {
        try {
          var lookup = FS.lookupPath(path);
          var stat = FS.stat(path);

          if (FS.isDir(stat.mode)) {
            FS.rmdir(path);
          } else if (FS.isFile(stat.mode)) {
            FS.unlink(path);
          }
        } catch (e) {
          return callback(e);
        }

        callback(null);
      },
      loadRemoteEntry: function loadRemoteEntry(store, path, callback) {
        var req = store.get(path);

        req.onsuccess = function (event) {
          callback(null, event.target.result);
        };

        req.onerror = function (e) {
          callback(this.error);
          e.preventDefault();
        };
      },
      storeRemoteEntry: function storeRemoteEntry(store, path, entry, callback) {
        var req = store.put(entry, path);

        req.onsuccess = function () {
          callback(null);
        };

        req.onerror = function (e) {
          callback(this.error);
          e.preventDefault();
        };
      },
      removeRemoteEntry: function removeRemoteEntry(store, path, callback) {
        var req = store.delete(path);

        req.onsuccess = function () {
          callback(null);
        };

        req.onerror = function (e) {
          callback(this.error);
          e.preventDefault();
        };
      },
      reconcile: function reconcile(src, dst, callback) {
        var total = 0;
        var create = [];
        Object.keys(src.entries).forEach(function (key) {
          var e = src.entries[key];
          var e2 = dst.entries[key];

          if (!e2 || e.timestamp > e2.timestamp) {
            create.push(key);
            total++;
          }
        });
        var remove = [];
        Object.keys(dst.entries).forEach(function (key) {
          var e = dst.entries[key];
          var e2 = src.entries[key];

          if (!e2) {
            remove.push(key);
            total++;
          }
        });

        if (!total) {
          return callback(null);
        }

        var completed = 0;
        var db = src.type === "remote" ? src.db : dst.db;
        var transaction = db.transaction([IDBFS.DB_STORE_NAME], "readwrite");
        var store = transaction.objectStore(IDBFS.DB_STORE_NAME);

        function done(err) {
          if (err) {
            if (!done.errored) {
              done.errored = true;
              return callback(err);
            }

            return;
          }

          if (++completed >= total) {
            return callback(null);
          }
        }

        transaction.onerror = function (e) {
          done(this.error);
          e.preventDefault();
        };

        create.sort().forEach(function (path) {
          if (dst.type === "local") {
            IDBFS.loadRemoteEntry(store, path, function (err, entry) {
              if (err) return done(err);
              IDBFS.storeLocalEntry(path, entry, done);
            });
          } else {
            IDBFS.loadLocalEntry(path, function (err, entry) {
              if (err) return done(err);
              IDBFS.storeRemoteEntry(store, path, entry, done);
            });
          }
        });
        remove.sort().reverse().forEach(function (path) {
          if (dst.type === "local") {
            IDBFS.removeLocalEntry(path, done);
          } else {
            IDBFS.removeRemoteEntry(store, path, done);
          }
        });
      }
    };
    var NODEFS = {
      isWindows: false,
      staticInit: function staticInit() {
        NODEFS.isWindows = !!process.platform.match(/^win/);
        var flags = process["binding"]("constants");

        if (flags["fs"]) {
          flags = flags["fs"];
        }

        NODEFS.flagsForNodeMap = {
          "1024": flags["O_APPEND"],
          "64": flags["O_CREAT"],
          "128": flags["O_EXCL"],
          "0": flags["O_RDONLY"],
          "2": flags["O_RDWR"],
          "4096": flags["O_SYNC"],
          "512": flags["O_TRUNC"],
          "1": flags["O_WRONLY"]
        };
      },
      bufferFrom: function bufferFrom(arrayBuffer) {
        return Buffer.alloc ? Buffer.from(arrayBuffer) : new Buffer(arrayBuffer);
      },
      mount: function mount(_mount3) {
        assert(ENVIRONMENT_IS_NODE);
        return NODEFS.createNode(null, "/", NODEFS.getMode(_mount3.opts.root), 0);
      },
      createNode: function createNode(parent, name, mode, dev) {
        if (!FS.isDir(mode) && !FS.isFile(mode) && !FS.isLink(mode)) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        var node = FS.createNode(parent, name, mode);
        node.node_ops = NODEFS.node_ops;
        node.stream_ops = NODEFS.stream_ops;
        return node;
      },
      getMode: function getMode(path) {
        var stat;

        try {
          stat = fs.lstatSync(path);

          if (NODEFS.isWindows) {
            stat.mode = stat.mode | (stat.mode & 292) >> 2;
          }
        } catch (e) {
          if (!e.code) throw e;
          throw new FS.ErrnoError(ERRNO_CODES[e.code]);
        }

        return stat.mode;
      },
      realPath: function realPath(node) {
        var parts = [];

        while (node.parent !== node) {
          parts.push(node.name);
          node = node.parent;
        }

        parts.push(node.mount.opts.root);
        parts.reverse();
        return PATH.join.apply(null, parts);
      },
      flagsForNode: function flagsForNode(flags) {
        flags &= ~2097152;
        flags &= ~2048;
        flags &= ~32768;
        flags &= ~524288;
        var newFlags = 0;

        for (var k in NODEFS.flagsForNodeMap) {
          if (flags & k) {
            newFlags |= NODEFS.flagsForNodeMap[k];
            flags ^= k;
          }
        }

        if (!flags) {
          return newFlags;
        } else {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }
      },
      node_ops: {
        getattr: function getattr(node) {
          var path = NODEFS.realPath(node);
          var stat;

          try {
            stat = fs.lstatSync(path);
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }

          if (NODEFS.isWindows && !stat.blksize) {
            stat.blksize = 4096;
          }

          if (NODEFS.isWindows && !stat.blocks) {
            stat.blocks = (stat.size + stat.blksize - 1) / stat.blksize | 0;
          }

          return {
            dev: stat.dev,
            ino: stat.ino,
            mode: stat.mode,
            nlink: stat.nlink,
            uid: stat.uid,
            gid: stat.gid,
            rdev: stat.rdev,
            size: stat.size,
            atime: stat.atime,
            mtime: stat.mtime,
            ctime: stat.ctime,
            blksize: stat.blksize,
            blocks: stat.blocks
          };
        },
        setattr: function setattr(node, attr) {
          var path = NODEFS.realPath(node);

          try {
            if (attr.mode !== undefined) {
              fs.chmodSync(path, attr.mode);
              node.mode = attr.mode;
            }

            if (attr.timestamp !== undefined) {
              var date = new Date(attr.timestamp);
              fs.utimesSync(path, date, date);
            }

            if (attr.size !== undefined) {
              fs.truncateSync(path, attr.size);
            }
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        lookup: function lookup(parent, name) {
          var path = PATH.join2(NODEFS.realPath(parent), name);
          var mode = NODEFS.getMode(path);
          return NODEFS.createNode(parent, name, mode);
        },
        mknod: function mknod(parent, name, mode, dev) {
          var node = NODEFS.createNode(parent, name, mode, dev);
          var path = NODEFS.realPath(node);

          try {
            if (FS.isDir(node.mode)) {
              fs.mkdirSync(path, node.mode);
            } else {
              fs.writeFileSync(path, "", {
                mode: node.mode
              });
            }
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }

          return node;
        },
        rename: function rename(oldNode, newDir, newName) {
          var oldPath = NODEFS.realPath(oldNode);
          var newPath = PATH.join2(NODEFS.realPath(newDir), newName);

          try {
            fs.renameSync(oldPath, newPath);
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        unlink: function unlink(parent, name) {
          var path = PATH.join2(NODEFS.realPath(parent), name);

          try {
            fs.unlinkSync(path);
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        rmdir: function rmdir(parent, name) {
          var path = PATH.join2(NODEFS.realPath(parent), name);

          try {
            fs.rmdirSync(path);
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        readdir: function readdir(node) {
          var path = NODEFS.realPath(node);

          try {
            return fs.readdirSync(path);
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        symlink: function symlink(parent, newName, oldPath) {
          var newPath = PATH.join2(NODEFS.realPath(parent), newName);

          try {
            fs.symlinkSync(oldPath, newPath);
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        readlink: function readlink(node) {
          var path = NODEFS.realPath(node);

          try {
            path = fs.readlinkSync(path);
            path = NODEJS_PATH.relative(NODEJS_PATH.resolve(node.mount.opts.root), path);
            return path;
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        }
      },
      stream_ops: {
        open: function open(stream) {
          var path = NODEFS.realPath(stream.node);

          try {
            if (FS.isFile(stream.node.mode)) {
              stream.nfd = fs.openSync(path, NODEFS.flagsForNode(stream.flags));
            }
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        close: function close(stream) {
          try {
            if (FS.isFile(stream.node.mode) && stream.nfd) {
              fs.closeSync(stream.nfd);
            }
          } catch (e) {
            if (!e.code) throw e;
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        read: function read(stream, buffer, offset, length, position) {
          if (length === 0) return 0;

          try {
            return fs.readSync(stream.nfd, NODEFS.bufferFrom(buffer.buffer), offset, length, position);
          } catch (e) {
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        write: function write(stream, buffer, offset, length, position) {
          try {
            return fs.writeSync(stream.nfd, NODEFS.bufferFrom(buffer.buffer), offset, length, position);
          } catch (e) {
            throw new FS.ErrnoError(ERRNO_CODES[e.code]);
          }
        },
        llseek: function llseek(stream, offset, whence) {
          var position = offset;

          if (whence === 1) {
            position += stream.position;
          } else if (whence === 2) {
            if (FS.isFile(stream.node.mode)) {
              try {
                var stat = fs.fstatSync(stream.nfd);
                position += stat.size;
              } catch (e) {
                throw new FS.ErrnoError(ERRNO_CODES[e.code]);
              }
            }
          }

          if (position < 0) {
            throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
          }

          return position;
        }
      }
    };
    var WORKERFS = {
      DIR_MODE: 16895,
      FILE_MODE: 33279,
      reader: null,
      mount: function mount(_mount4) {
        assert(ENVIRONMENT_IS_WORKER);
        if (!WORKERFS.reader) WORKERFS.reader = new FileReaderSync();
        var root = WORKERFS.createNode(null, "/", WORKERFS.DIR_MODE, 0);
        var createdParents = {};

        function ensureParent(path) {
          var parts = path.split("/");
          var parent = root;

          for (var i = 0; i < parts.length - 1; i++) {
            var curr = parts.slice(0, i + 1).join("/");

            if (!createdParents[curr]) {
              createdParents[curr] = WORKERFS.createNode(parent, parts[i], WORKERFS.DIR_MODE, 0);
            }

            parent = createdParents[curr];
          }

          return parent;
        }

        function base(path) {
          var parts = path.split("/");
          return parts[parts.length - 1];
        }

        Array.prototype.forEach.call(_mount4.opts["files"] || [], function (file) {
          WORKERFS.createNode(ensureParent(file.name), base(file.name), WORKERFS.FILE_MODE, 0, file, file.lastModifiedDate);
        });
        (_mount4.opts["blobs"] || []).forEach(function (obj) {
          WORKERFS.createNode(ensureParent(obj["name"]), base(obj["name"]), WORKERFS.FILE_MODE, 0, obj["data"]);
        });
        (_mount4.opts["packages"] || []).forEach(function (pack) {
          pack["metadata"].files.forEach(function (file) {
            var name = file.filename.substr(1);
            WORKERFS.createNode(ensureParent(name), base(name), WORKERFS.FILE_MODE, 0, pack["blob"].slice(file.start, file.end));
          });
        });
        return root;
      },
      createNode: function createNode(parent, name, mode, dev, contents, mtime) {
        var node = FS.createNode(parent, name, mode);
        node.mode = mode;
        node.node_ops = WORKERFS.node_ops;
        node.stream_ops = WORKERFS.stream_ops;
        node.timestamp = (mtime || new Date()).getTime();
        assert(WORKERFS.FILE_MODE !== WORKERFS.DIR_MODE);

        if (mode === WORKERFS.FILE_MODE) {
          node.size = contents.size;
          node.contents = contents;
        } else {
          node.size = 4096;
          node.contents = {};
        }

        if (parent) {
          parent.contents[name] = node;
        }

        return node;
      },
      node_ops: {
        getattr: function getattr(node) {
          return {
            dev: 1,
            ino: undefined,
            mode: node.mode,
            nlink: 1,
            uid: 0,
            gid: 0,
            rdev: undefined,
            size: node.size,
            atime: new Date(node.timestamp),
            mtime: new Date(node.timestamp),
            ctime: new Date(node.timestamp),
            blksize: 4096,
            blocks: Math.ceil(node.size / 4096)
          };
        },
        setattr: function setattr(node, attr) {
          if (attr.mode !== undefined) {
            node.mode = attr.mode;
          }

          if (attr.timestamp !== undefined) {
            node.timestamp = attr.timestamp;
          }
        },
        lookup: function lookup(parent, name) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOENT);
        },
        mknod: function mknod(parent, name, mode, dev) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        },
        rename: function rename(oldNode, newDir, newName) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        },
        unlink: function unlink(parent, name) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        },
        rmdir: function rmdir(parent, name) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        },
        readdir: function readdir(node) {
          var entries = [".", ".."];

          for (var key in node.contents) {
            if (!node.contents.hasOwnProperty(key)) {
              continue;
            }

            entries.push(key);
          }

          return entries;
        },
        symlink: function symlink(parent, newName, oldPath) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        },
        readlink: function readlink(node) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }
      },
      stream_ops: {
        read: function read(stream, buffer, offset, length, position) {
          if (position >= stream.node.size) return 0;
          var chunk = stream.node.contents.slice(position, position + length);
          var ab = WORKERFS.reader.readAsArrayBuffer(chunk);
          buffer.set(new Uint8Array(ab), offset);
          return chunk.size;
        },
        write: function write(stream, buffer, offset, length, position) {
          throw new FS.ErrnoError(ERRNO_CODES.EIO);
        },
        llseek: function llseek(stream, offset, whence) {
          var position = offset;

          if (whence === 1) {
            position += stream.position;
          } else if (whence === 2) {
            if (FS.isFile(stream.node.mode)) {
              position += stream.node.size;
            }
          }

          if (position < 0) {
            throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
          }

          return position;
        }
      }
    };
    STATICTOP += 16;
    STATICTOP += 16;
    STATICTOP += 16;
    var FS = {
      root: null,
      mounts: [],
      devices: {},
      streams: [],
      nextInode: 1,
      nameTable: null,
      currentPath: "/",
      initialized: false,
      ignorePermissions: true,
      trackingDelegate: {},
      tracking: {
        openFlags: {
          READ: 1,
          WRITE: 2
        }
      },
      ErrnoError: null,
      genericErrors: {},
      filesystems: null,
      syncFSRequests: 0,
      handleFSError: function handleFSError(e) {
        if (!(e instanceof FS.ErrnoError)) throw e + " : " + stackTrace();
        return ___setErrNo(e.errno);
      },
      lookupPath: function lookupPath(path, opts) {
        path = PATH.resolve(FS.cwd(), path);
        opts = opts || {};
        if (!path) return {
          path: "",
          node: null
        };
        var defaults = {
          follow_mount: true,
          recurse_count: 0
        };

        for (var key in defaults) {
          if (opts[key] === undefined) {
            opts[key] = defaults[key];
          }
        }

        if (opts.recurse_count > 8) {
          throw new FS.ErrnoError(ERRNO_CODES.ELOOP);
        }

        var parts = PATH.normalizeArray(path.split("/").filter(function (p) {
          return !!p;
        }), false);
        var current = FS.root;
        var current_path = "/";

        for (var i = 0; i < parts.length; i++) {
          var islast = i === parts.length - 1;

          if (islast && opts.parent) {
            break;
          }

          current = FS.lookupNode(current, parts[i]);
          current_path = PATH.join2(current_path, parts[i]);

          if (FS.isMountpoint(current)) {
            if (!islast || islast && opts.follow_mount) {
              current = current.mounted.root;
            }
          }

          if (!islast || opts.follow) {
            var count = 0;

            while (FS.isLink(current.mode)) {
              var link = FS.readlink(current_path);
              current_path = PATH.resolve(PATH.dirname(current_path), link);
              var lookup = FS.lookupPath(current_path, {
                recurse_count: opts.recurse_count
              });
              current = lookup.node;

              if (count++ > 40) {
                throw new FS.ErrnoError(ERRNO_CODES.ELOOP);
              }
            }
          }
        }

        return {
          path: current_path,
          node: current
        };
      },
      getPath: function getPath(node) {
        var path;

        while (true) {
          if (FS.isRoot(node)) {
            var mount = node.mount.mountpoint;
            if (!path) return mount;
            return mount[mount.length - 1] !== "/" ? mount + "/" + path : mount + path;
          }

          path = path ? node.name + "/" + path : node.name;
          node = node.parent;
        }
      },
      hashName: function hashName(parentid, name) {
        var hash = 0;

        for (var i = 0; i < name.length; i++) {
          hash = (hash << 5) - hash + name.charCodeAt(i) | 0;
        }

        return (parentid + hash >>> 0) % FS.nameTable.length;
      },
      hashAddNode: function hashAddNode(node) {
        var hash = FS.hashName(node.parent.id, node.name);
        node.name_next = FS.nameTable[hash];
        FS.nameTable[hash] = node;
      },
      hashRemoveNode: function hashRemoveNode(node) {
        var hash = FS.hashName(node.parent.id, node.name);

        if (FS.nameTable[hash] === node) {
          FS.nameTable[hash] = node.name_next;
        } else {
          var current = FS.nameTable[hash];

          while (current) {
            if (current.name_next === node) {
              current.name_next = node.name_next;
              break;
            }

            current = current.name_next;
          }
        }
      },
      lookupNode: function lookupNode(parent, name) {
        var err = FS.mayLookup(parent);

        if (err) {
          throw new FS.ErrnoError(err, parent);
        }

        var hash = FS.hashName(parent.id, name);

        for (var node = FS.nameTable[hash]; node; node = node.name_next) {
          var nodeName = node.name;

          if (node.parent.id === parent.id && nodeName === name) {
            return node;
          }
        }

        return FS.lookup(parent, name);
      },
      createNode: function createNode(parent, name, mode, rdev) {
        if (!FS.FSNode) {
          FS.FSNode = function (parent, name, mode, rdev) {
            if (!parent) {
              parent = this;
            }

            this.parent = parent;
            this.mount = parent.mount;
            this.mounted = null;
            this.id = FS.nextInode++;
            this.name = name;
            this.mode = mode;
            this.node_ops = {};
            this.stream_ops = {};
            this.rdev = rdev;
          };

          FS.FSNode.prototype = {};
          var readMode = 292 | 73;
          var writeMode = 146;
          Object.defineProperties(FS.FSNode.prototype, {
            read: {
              get: function get() {
                return (this.mode & readMode) === readMode;
              },
              set: function set(val) {
                val ? this.mode |= readMode : this.mode &= ~readMode;
              }
            },
            write: {
              get: function get() {
                return (this.mode & writeMode) === writeMode;
              },
              set: function set(val) {
                val ? this.mode |= writeMode : this.mode &= ~writeMode;
              }
            },
            isFolder: {
              get: function get() {
                return FS.isDir(this.mode);
              }
            },
            isDevice: {
              get: function get() {
                return FS.isChrdev(this.mode);
              }
            }
          });
        }

        var node = new FS.FSNode(parent, name, mode, rdev);
        FS.hashAddNode(node);
        return node;
      },
      destroyNode: function destroyNode(node) {
        FS.hashRemoveNode(node);
      },
      isRoot: function isRoot(node) {
        return node === node.parent;
      },
      isMountpoint: function isMountpoint(node) {
        return !!node.mounted;
      },
      isFile: function isFile(mode) {
        return (mode & 61440) === 32768;
      },
      isDir: function isDir(mode) {
        return (mode & 61440) === 16384;
      },
      isLink: function isLink(mode) {
        return (mode & 61440) === 40960;
      },
      isChrdev: function isChrdev(mode) {
        return (mode & 61440) === 8192;
      },
      isBlkdev: function isBlkdev(mode) {
        return (mode & 61440) === 24576;
      },
      isFIFO: function isFIFO(mode) {
        return (mode & 61440) === 4096;
      },
      isSocket: function isSocket(mode) {
        return (mode & 49152) === 49152;
      },
      flagModes: {
        "r": 0,
        "rs": 1052672,
        "r+": 2,
        "w": 577,
        "wx": 705,
        "xw": 705,
        "w+": 578,
        "wx+": 706,
        "xw+": 706,
        "a": 1089,
        "ax": 1217,
        "xa": 1217,
        "a+": 1090,
        "ax+": 1218,
        "xa+": 1218
      },
      modeStringToFlags: function modeStringToFlags(str) {
        var flags = FS.flagModes[str];

        if (typeof flags === "undefined") {
          throw new Error("Unknown file open mode: " + str);
        }

        return flags;
      },
      flagsToPermissionString: function flagsToPermissionString(flag) {
        var perms = ["r", "w", "rw"][flag & 3];

        if (flag & 512) {
          perms += "w";
        }

        return perms;
      },
      nodePermissions: function nodePermissions(node, perms) {
        if (FS.ignorePermissions) {
          return 0;
        }

        if (perms.indexOf("r") !== -1 && !(node.mode & 292)) {
          return ERRNO_CODES.EACCES;
        } else if (perms.indexOf("w") !== -1 && !(node.mode & 146)) {
          return ERRNO_CODES.EACCES;
        } else if (perms.indexOf("x") !== -1 && !(node.mode & 73)) {
          return ERRNO_CODES.EACCES;
        }

        return 0;
      },
      mayLookup: function mayLookup(dir) {
        var err = FS.nodePermissions(dir, "x");
        if (err) return err;
        if (!dir.node_ops.lookup) return ERRNO_CODES.EACCES;
        return 0;
      },
      mayCreate: function mayCreate(dir, name) {
        try {
          var node = FS.lookupNode(dir, name);
          return ERRNO_CODES.EEXIST;
        } catch (e) {}

        return FS.nodePermissions(dir, "wx");
      },
      mayDelete: function mayDelete(dir, name, isdir) {
        var node;

        try {
          node = FS.lookupNode(dir, name);
        } catch (e) {
          return e.errno;
        }

        var err = FS.nodePermissions(dir, "wx");

        if (err) {
          return err;
        }

        if (isdir) {
          if (!FS.isDir(node.mode)) {
            return ERRNO_CODES.ENOTDIR;
          }

          if (FS.isRoot(node) || FS.getPath(node) === FS.cwd()) {
            return ERRNO_CODES.EBUSY;
          }
        } else {
          if (FS.isDir(node.mode)) {
            return ERRNO_CODES.EISDIR;
          }
        }

        return 0;
      },
      mayOpen: function mayOpen(node, flags) {
        if (!node) {
          return ERRNO_CODES.ENOENT;
        }

        if (FS.isLink(node.mode)) {
          return ERRNO_CODES.ELOOP;
        } else if (FS.isDir(node.mode)) {
          if (FS.flagsToPermissionString(flags) !== "r" || flags & 512) {
            return ERRNO_CODES.EISDIR;
          }
        }

        return FS.nodePermissions(node, FS.flagsToPermissionString(flags));
      },
      MAX_OPEN_FDS: 4096,
      nextfd: function nextfd(fd_start, fd_end) {
        fd_start = fd_start || 0;
        fd_end = fd_end || FS.MAX_OPEN_FDS;

        for (var fd = fd_start; fd <= fd_end; fd++) {
          if (!FS.streams[fd]) {
            return fd;
          }
        }

        throw new FS.ErrnoError(ERRNO_CODES.EMFILE);
      },
      getStream: function getStream(fd) {
        return FS.streams[fd];
      },
      createStream: function createStream(stream, fd_start, fd_end) {
        if (!FS.FSStream) {
          FS.FSStream = function () {};

          FS.FSStream.prototype = {};
          Object.defineProperties(FS.FSStream.prototype, {
            object: {
              get: function get() {
                return this.node;
              },
              set: function set(val) {
                this.node = val;
              }
            },
            isRead: {
              get: function get() {
                return (this.flags & 2097155) !== 1;
              }
            },
            isWrite: {
              get: function get() {
                return (this.flags & 2097155) !== 0;
              }
            },
            isAppend: {
              get: function get() {
                return this.flags & 1024;
              }
            }
          });
        }

        var newStream = new FS.FSStream();

        for (var p in stream) {
          newStream[p] = stream[p];
        }

        stream = newStream;
        var fd = FS.nextfd(fd_start, fd_end);
        stream.fd = fd;
        FS.streams[fd] = stream;
        return stream;
      },
      closeStream: function closeStream(fd) {
        FS.streams[fd] = null;
      },
      chrdev_stream_ops: {
        open: function open(stream) {
          var device = FS.getDevice(stream.node.rdev);
          stream.stream_ops = device.stream_ops;

          if (stream.stream_ops.open) {
            stream.stream_ops.open(stream);
          }
        },
        llseek: function llseek() {
          throw new FS.ErrnoError(ERRNO_CODES.ESPIPE);
        }
      },
      major: function major(dev) {
        return dev >> 8;
      },
      minor: function minor(dev) {
        return dev & 255;
      },
      makedev: function makedev(ma, mi) {
        return ma << 8 | mi;
      },
      registerDevice: function registerDevice(dev, ops) {
        FS.devices[dev] = {
          stream_ops: ops
        };
      },
      getDevice: function getDevice(dev) {
        return FS.devices[dev];
      },
      getMounts: function getMounts(mount) {
        var mounts = [];
        var check = [mount];

        while (check.length) {
          var m = check.pop();
          mounts.push(m);
          check.push.apply(check, m.mounts);
        }

        return mounts;
      },
      syncfs: function syncfs(populate, callback) {
        if (typeof populate === "function") {
          callback = populate;
          populate = false;
        }

        FS.syncFSRequests++;

        if (FS.syncFSRequests > 1) {
          console.log("warning: " + FS.syncFSRequests + " FS.syncfs operations in flight at once, probably just doing extra work");
        }

        var mounts = FS.getMounts(FS.root.mount);
        var completed = 0;

        function doCallback(err) {
          assert(FS.syncFSRequests > 0);
          FS.syncFSRequests--;
          return callback(err);
        }

        function done(err) {
          if (err) {
            if (!done.errored) {
              done.errored = true;
              return doCallback(err);
            }

            return;
          }

          if (++completed >= mounts.length) {
            doCallback(null);
          }
        }

        mounts.forEach(function (mount) {
          if (!mount.type.syncfs) {
            return done(null);
          }

          mount.type.syncfs(mount, populate, done);
        });
      },
      mount: function mount(type, opts, mountpoint) {
        var root = mountpoint === "/";
        var pseudo = !mountpoint;
        var node;

        if (root && FS.root) {
          throw new FS.ErrnoError(ERRNO_CODES.EBUSY);
        } else if (!root && !pseudo) {
          var lookup = FS.lookupPath(mountpoint, {
            follow_mount: false
          });
          mountpoint = lookup.path;
          node = lookup.node;

          if (FS.isMountpoint(node)) {
            throw new FS.ErrnoError(ERRNO_CODES.EBUSY);
          }

          if (!FS.isDir(node.mode)) {
            throw new FS.ErrnoError(ERRNO_CODES.ENOTDIR);
          }
        }

        var mount = {
          type: type,
          opts: opts,
          mountpoint: mountpoint,
          mounts: []
        };
        var mountRoot = type.mount(mount);
        mountRoot.mount = mount;
        mount.root = mountRoot;

        if (root) {
          FS.root = mountRoot;
        } else if (node) {
          node.mounted = mount;

          if (node.mount) {
            node.mount.mounts.push(mount);
          }
        }

        return mountRoot;
      },
      unmount: function unmount(mountpoint) {
        var lookup = FS.lookupPath(mountpoint, {
          follow_mount: false
        });

        if (!FS.isMountpoint(lookup.node)) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        var node = lookup.node;
        var mount = node.mounted;
        var mounts = FS.getMounts(mount);
        Object.keys(FS.nameTable).forEach(function (hash) {
          var current = FS.nameTable[hash];

          while (current) {
            var next = current.name_next;

            if (mounts.indexOf(current.mount) !== -1) {
              FS.destroyNode(current);
            }

            current = next;
          }
        });
        node.mounted = null;
        var idx = node.mount.mounts.indexOf(mount);
        assert(idx !== -1);
        node.mount.mounts.splice(idx, 1);
      },
      lookup: function lookup(parent, name) {
        return parent.node_ops.lookup(parent, name);
      },
      mknod: function mknod(path, mode, dev) {
        var lookup = FS.lookupPath(path, {
          parent: true
        });
        var parent = lookup.node;
        var name = PATH.basename(path);

        if (!name || name === "." || name === "..") {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        var err = FS.mayCreate(parent, name);

        if (err) {
          throw new FS.ErrnoError(err);
        }

        if (!parent.node_ops.mknod) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        return parent.node_ops.mknod(parent, name, mode, dev);
      },
      create: function create(path, mode) {
        mode = mode !== undefined ? mode : 438;
        mode &= 4095;
        mode |= 32768;
        return FS.mknod(path, mode, 0);
      },
      mkdir: function mkdir(path, mode) {
        mode = mode !== undefined ? mode : 511;
        mode &= 511 | 512;
        mode |= 16384;
        return FS.mknod(path, mode, 0);
      },
      mkdirTree: function mkdirTree(path, mode) {
        var dirs = path.split("/");
        var d = "";

        for (var i = 0; i < dirs.length; ++i) {
          if (!dirs[i]) continue;
          d += "/" + dirs[i];

          try {
            FS.mkdir(d, mode);
          } catch (e) {
            if (e.errno != ERRNO_CODES.EEXIST) throw e;
          }
        }
      },
      mkdev: function mkdev(path, mode, dev) {
        if (typeof dev === "undefined") {
          dev = mode;
          mode = 438;
        }

        mode |= 8192;
        return FS.mknod(path, mode, dev);
      },
      symlink: function symlink(oldpath, newpath) {
        if (!PATH.resolve(oldpath)) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOENT);
        }

        var lookup = FS.lookupPath(newpath, {
          parent: true
        });
        var parent = lookup.node;

        if (!parent) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOENT);
        }

        var newname = PATH.basename(newpath);
        var err = FS.mayCreate(parent, newname);

        if (err) {
          throw new FS.ErrnoError(err);
        }

        if (!parent.node_ops.symlink) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        return parent.node_ops.symlink(parent, newname, oldpath);
      },
      rename: function rename(old_path, new_path) {
        var old_dirname = PATH.dirname(old_path);
        var new_dirname = PATH.dirname(new_path);
        var old_name = PATH.basename(old_path);
        var new_name = PATH.basename(new_path);
        var lookup, old_dir, new_dir;

        try {
          lookup = FS.lookupPath(old_path, {
            parent: true
          });
          old_dir = lookup.node;
          lookup = FS.lookupPath(new_path, {
            parent: true
          });
          new_dir = lookup.node;
        } catch (e) {
          throw new FS.ErrnoError(ERRNO_CODES.EBUSY);
        }

        if (!old_dir || !new_dir) throw new FS.ErrnoError(ERRNO_CODES.ENOENT);

        if (old_dir.mount !== new_dir.mount) {
          throw new FS.ErrnoError(ERRNO_CODES.EXDEV);
        }

        var old_node = FS.lookupNode(old_dir, old_name);
        var relative = PATH.relative(old_path, new_dirname);

        if (relative.charAt(0) !== ".") {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        relative = PATH.relative(new_path, old_dirname);

        if (relative.charAt(0) !== ".") {
          throw new FS.ErrnoError(ERRNO_CODES.ENOTEMPTY);
        }

        var new_node;

        try {
          new_node = FS.lookupNode(new_dir, new_name);
        } catch (e) {}

        if (old_node === new_node) {
          return;
        }

        var isdir = FS.isDir(old_node.mode);
        var err = FS.mayDelete(old_dir, old_name, isdir);

        if (err) {
          throw new FS.ErrnoError(err);
        }

        err = new_node ? FS.mayDelete(new_dir, new_name, isdir) : FS.mayCreate(new_dir, new_name);

        if (err) {
          throw new FS.ErrnoError(err);
        }

        if (!old_dir.node_ops.rename) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        if (FS.isMountpoint(old_node) || new_node && FS.isMountpoint(new_node)) {
          throw new FS.ErrnoError(ERRNO_CODES.EBUSY);
        }

        if (new_dir !== old_dir) {
          err = FS.nodePermissions(old_dir, "w");

          if (err) {
            throw new FS.ErrnoError(err);
          }
        }

        try {
          if (FS.trackingDelegate["willMovePath"]) {
            FS.trackingDelegate["willMovePath"](old_path, new_path);
          }
        } catch (e) {
          console.log("FS.trackingDelegate['willMovePath']('" + old_path + "', '" + new_path + "') threw an exception: " + e.message);
        }

        FS.hashRemoveNode(old_node);

        try {
          old_dir.node_ops.rename(old_node, new_dir, new_name);
        } catch (e) {
          throw e;
        } finally {
          FS.hashAddNode(old_node);
        }

        try {
          if (FS.trackingDelegate["onMovePath"]) FS.trackingDelegate["onMovePath"](old_path, new_path);
        } catch (e) {
          console.log("FS.trackingDelegate['onMovePath']('" + old_path + "', '" + new_path + "') threw an exception: " + e.message);
        }
      },
      rmdir: function rmdir(path) {
        var lookup = FS.lookupPath(path, {
          parent: true
        });
        var parent = lookup.node;
        var name = PATH.basename(path);
        var node = FS.lookupNode(parent, name);
        var err = FS.mayDelete(parent, name, true);

        if (err) {
          throw new FS.ErrnoError(err);
        }

        if (!parent.node_ops.rmdir) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        if (FS.isMountpoint(node)) {
          throw new FS.ErrnoError(ERRNO_CODES.EBUSY);
        }

        try {
          if (FS.trackingDelegate["willDeletePath"]) {
            FS.trackingDelegate["willDeletePath"](path);
          }
        } catch (e) {
          console.log("FS.trackingDelegate['willDeletePath']('" + path + "') threw an exception: " + e.message);
        }

        parent.node_ops.rmdir(parent, name);
        FS.destroyNode(node);

        try {
          if (FS.trackingDelegate["onDeletePath"]) FS.trackingDelegate["onDeletePath"](path);
        } catch (e) {
          console.log("FS.trackingDelegate['onDeletePath']('" + path + "') threw an exception: " + e.message);
        }
      },
      readdir: function readdir(path) {
        var lookup = FS.lookupPath(path, {
          follow: true
        });
        var node = lookup.node;

        if (!node.node_ops.readdir) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOTDIR);
        }

        return node.node_ops.readdir(node);
      },
      unlink: function unlink(path) {
        var lookup = FS.lookupPath(path, {
          parent: true
        });
        var parent = lookup.node;
        var name = PATH.basename(path);
        var node = FS.lookupNode(parent, name);
        var err = FS.mayDelete(parent, name, false);

        if (err) {
          throw new FS.ErrnoError(err);
        }

        if (!parent.node_ops.unlink) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        if (FS.isMountpoint(node)) {
          throw new FS.ErrnoError(ERRNO_CODES.EBUSY);
        }

        try {
          if (FS.trackingDelegate["willDeletePath"]) {
            FS.trackingDelegate["willDeletePath"](path);
          }
        } catch (e) {
          console.log("FS.trackingDelegate['willDeletePath']('" + path + "') threw an exception: " + e.message);
        }

        parent.node_ops.unlink(parent, name);
        FS.destroyNode(node);

        try {
          if (FS.trackingDelegate["onDeletePath"]) FS.trackingDelegate["onDeletePath"](path);
        } catch (e) {
          console.log("FS.trackingDelegate['onDeletePath']('" + path + "') threw an exception: " + e.message);
        }
      },
      readlink: function readlink(path) {
        var lookup = FS.lookupPath(path);
        var link = lookup.node;

        if (!link) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOENT);
        }

        if (!link.node_ops.readlink) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        return PATH.resolve(FS.getPath(link.parent), link.node_ops.readlink(link));
      },
      stat: function stat(path, dontFollow) {
        var lookup = FS.lookupPath(path, {
          follow: !dontFollow
        });
        var node = lookup.node;

        if (!node) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOENT);
        }

        if (!node.node_ops.getattr) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        return node.node_ops.getattr(node);
      },
      lstat: function lstat(path) {
        return FS.stat(path, true);
      },
      chmod: function chmod(path, mode, dontFollow) {
        var node;

        if (typeof path === "string") {
          var lookup = FS.lookupPath(path, {
            follow: !dontFollow
          });
          node = lookup.node;
        } else {
          node = path;
        }

        if (!node.node_ops.setattr) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        node.node_ops.setattr(node, {
          mode: mode & 4095 | node.mode & ~4095,
          timestamp: Date.now()
        });
      },
      lchmod: function lchmod(path, mode) {
        FS.chmod(path, mode, true);
      },
      fchmod: function fchmod(fd, mode) {
        var stream = FS.getStream(fd);

        if (!stream) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        FS.chmod(stream.node, mode);
      },
      chown: function chown(path, uid, gid, dontFollow) {
        var node;

        if (typeof path === "string") {
          var lookup = FS.lookupPath(path, {
            follow: !dontFollow
          });
          node = lookup.node;
        } else {
          node = path;
        }

        if (!node.node_ops.setattr) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        node.node_ops.setattr(node, {
          timestamp: Date.now()
        });
      },
      lchown: function lchown(path, uid, gid) {
        FS.chown(path, uid, gid, true);
      },
      fchown: function fchown(fd, uid, gid) {
        var stream = FS.getStream(fd);

        if (!stream) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        FS.chown(stream.node, uid, gid);
      },
      truncate: function truncate(path, len) {
        if (len < 0) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        var node;

        if (typeof path === "string") {
          var lookup = FS.lookupPath(path, {
            follow: true
          });
          node = lookup.node;
        } else {
          node = path;
        }

        if (!node.node_ops.setattr) {
          throw new FS.ErrnoError(ERRNO_CODES.EPERM);
        }

        if (FS.isDir(node.mode)) {
          throw new FS.ErrnoError(ERRNO_CODES.EISDIR);
        }

        if (!FS.isFile(node.mode)) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        var err = FS.nodePermissions(node, "w");

        if (err) {
          throw new FS.ErrnoError(err);
        }

        node.node_ops.setattr(node, {
          size: len,
          timestamp: Date.now()
        });
      },
      ftruncate: function ftruncate(fd, len) {
        var stream = FS.getStream(fd);

        if (!stream) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        if ((stream.flags & 2097155) === 0) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        FS.truncate(stream.node, len);
      },
      utime: function utime(path, atime, mtime) {
        var lookup = FS.lookupPath(path, {
          follow: true
        });
        var node = lookup.node;
        node.node_ops.setattr(node, {
          timestamp: Math.max(atime, mtime)
        });
      },
      open: function open(path, flags, mode, fd_start, fd_end) {
        if (path === "") {
          throw new FS.ErrnoError(ERRNO_CODES.ENOENT);
        }

        flags = typeof flags === "string" ? FS.modeStringToFlags(flags) : flags;
        mode = typeof mode === "undefined" ? 438 : mode;

        if (flags & 64) {
          mode = mode & 4095 | 32768;
        } else {
          mode = 0;
        }

        var node;

        if (typeof path === "object") {
          node = path;
        } else {
          path = PATH.normalize(path);

          try {
            var lookup = FS.lookupPath(path, {
              follow: !(flags & 131072)
            });
            node = lookup.node;
          } catch (e) {}
        }

        var created = false;

        if (flags & 64) {
          if (node) {
            if (flags & 128) {
              throw new FS.ErrnoError(ERRNO_CODES.EEXIST);
            }
          } else {
            node = FS.mknod(path, mode, 0);
            created = true;
          }
        }

        if (!node) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOENT);
        }

        if (FS.isChrdev(node.mode)) {
          flags &= ~512;
        }

        if (flags & 65536 && !FS.isDir(node.mode)) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOTDIR);
        }

        if (!created) {
          var err = FS.mayOpen(node, flags);

          if (err) {
            throw new FS.ErrnoError(err);
          }
        }

        if (flags & 512) {
          FS.truncate(node, 0);
        }

        flags &= ~(128 | 512);
        var stream = FS.createStream({
          node: node,
          path: FS.getPath(node),
          flags: flags,
          seekable: true,
          position: 0,
          stream_ops: node.stream_ops,
          ungotten: [],
          error: false
        }, fd_start, fd_end);

        if (stream.stream_ops.open) {
          stream.stream_ops.open(stream);
        }

        if (Module["logReadFiles"] && !(flags & 1)) {
          if (!FS.readFiles) FS.readFiles = {};

          if (!(path in FS.readFiles)) {
            FS.readFiles[path] = 1;
            err("read file: " + path);
          }
        }

        try {
          if (FS.trackingDelegate["onOpenFile"]) {
            var trackingFlags = 0;

            if ((flags & 2097155) !== 1) {
              trackingFlags |= FS.tracking.openFlags.READ;
            }

            if ((flags & 2097155) !== 0) {
              trackingFlags |= FS.tracking.openFlags.WRITE;
            }

            FS.trackingDelegate["onOpenFile"](path, trackingFlags);
          }
        } catch (e) {
          console.log("FS.trackingDelegate['onOpenFile']('" + path + "', flags) threw an exception: " + e.message);
        }

        return stream;
      },
      close: function close(stream) {
        if (FS.isClosed(stream)) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        if (stream.getdents) stream.getdents = null;

        try {
          if (stream.stream_ops.close) {
            stream.stream_ops.close(stream);
          }
        } catch (e) {
          throw e;
        } finally {
          FS.closeStream(stream.fd);
        }

        stream.fd = null;
      },
      isClosed: function isClosed(stream) {
        return stream.fd === null;
      },
      llseek: function llseek(stream, offset, whence) {
        if (FS.isClosed(stream)) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        if (!stream.seekable || !stream.stream_ops.llseek) {
          throw new FS.ErrnoError(ERRNO_CODES.ESPIPE);
        }

        stream.position = stream.stream_ops.llseek(stream, offset, whence);
        stream.ungotten = [];
        return stream.position;
      },
      read: function read(stream, buffer, offset, length, position) {
        if (length < 0 || position < 0) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        if (FS.isClosed(stream)) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        if ((stream.flags & 2097155) === 1) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        if (FS.isDir(stream.node.mode)) {
          throw new FS.ErrnoError(ERRNO_CODES.EISDIR);
        }

        if (!stream.stream_ops.read) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        var seeking = typeof position !== "undefined";

        if (!seeking) {
          position = stream.position;
        } else if (!stream.seekable) {
          throw new FS.ErrnoError(ERRNO_CODES.ESPIPE);
        }

        var bytesRead = stream.stream_ops.read(stream, buffer, offset, length, position);
        if (!seeking) stream.position += bytesRead;
        return bytesRead;
      },
      write: function write(stream, buffer, offset, length, position, canOwn) {
        if (length < 0 || position < 0) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        if (FS.isClosed(stream)) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        if ((stream.flags & 2097155) === 0) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        if (FS.isDir(stream.node.mode)) {
          throw new FS.ErrnoError(ERRNO_CODES.EISDIR);
        }

        if (!stream.stream_ops.write) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        if (stream.flags & 1024) {
          FS.llseek(stream, 0, 2);
        }

        var seeking = typeof position !== "undefined";

        if (!seeking) {
          position = stream.position;
        } else if (!stream.seekable) {
          throw new FS.ErrnoError(ERRNO_CODES.ESPIPE);
        }

        var bytesWritten = stream.stream_ops.write(stream, buffer, offset, length, position, canOwn);
        if (!seeking) stream.position += bytesWritten;

        try {
          if (stream.path && FS.trackingDelegate["onWriteToFile"]) FS.trackingDelegate["onWriteToFile"](stream.path);
        } catch (e) {
          console.log("FS.trackingDelegate['onWriteToFile']('" + path + "') threw an exception: " + e.message);
        }

        return bytesWritten;
      },
      allocate: function allocate(stream, offset, length) {
        if (FS.isClosed(stream)) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        if (offset < 0 || length <= 0) {
          throw new FS.ErrnoError(ERRNO_CODES.EINVAL);
        }

        if ((stream.flags & 2097155) === 0) {
          throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        }

        if (!FS.isFile(stream.node.mode) && !FS.isDir(stream.node.mode)) {
          throw new FS.ErrnoError(ERRNO_CODES.ENODEV);
        }

        if (!stream.stream_ops.allocate) {
          throw new FS.ErrnoError(ERRNO_CODES.EOPNOTSUPP);
        }

        stream.stream_ops.allocate(stream, offset, length);
      },
      mmap: function mmap(stream, buffer, offset, length, position, prot, flags) {
        if ((stream.flags & 2097155) === 1) {
          throw new FS.ErrnoError(ERRNO_CODES.EACCES);
        }

        if (!stream.stream_ops.mmap) {
          throw new FS.ErrnoError(ERRNO_CODES.ENODEV);
        }

        return stream.stream_ops.mmap(stream, buffer, offset, length, position, prot, flags);
      },
      msync: function msync(stream, buffer, offset, length, mmapFlags) {
        if (!stream || !stream.stream_ops.msync) {
          return 0;
        }

        return stream.stream_ops.msync(stream, buffer, offset, length, mmapFlags);
      },
      munmap: function munmap(stream) {
        return 0;
      },
      ioctl: function ioctl(stream, cmd, arg) {
        if (!stream.stream_ops.ioctl) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOTTY);
        }

        return stream.stream_ops.ioctl(stream, cmd, arg);
      },
      readFile: function readFile(path, opts) {
        opts = opts || {};
        opts.flags = opts.flags || "r";
        opts.encoding = opts.encoding || "binary";

        if (opts.encoding !== "utf8" && opts.encoding !== "binary") {
          throw new Error('Invalid encoding type "' + opts.encoding + '"');
        }

        var ret;
        var stream = FS.open(path, opts.flags);
        var stat = FS.stat(path);
        var length = stat.size;
        var buf = new Uint8Array(length);
        FS.read(stream, buf, 0, length, 0);

        if (opts.encoding === "utf8") {
          ret = UTF8ArrayToString(buf, 0);
        } else if (opts.encoding === "binary") {
          ret = buf;
        }

        FS.close(stream);
        return ret;
      },
      writeFile: function writeFile(path, data, opts) {
        opts = opts || {};
        opts.flags = opts.flags || "w";
        var stream = FS.open(path, opts.flags, opts.mode);

        if (typeof data === "string") {
          var buf = new Uint8Array(lengthBytesUTF8(data) + 1);
          var actualNumBytes = stringToUTF8Array(data, buf, 0, buf.length);
          FS.write(stream, buf, 0, actualNumBytes, undefined, opts.canOwn);
        } else if (ArrayBuffer.isView(data)) {
          FS.write(stream, data, 0, data.byteLength, undefined, opts.canOwn);
        } else {
          throw new Error("Unsupported data type");
        }

        FS.close(stream);
      },
      cwd: function cwd() {
        return FS.currentPath;
      },
      chdir: function chdir(path) {
        var lookup = FS.lookupPath(path, {
          follow: true
        });

        if (lookup.node === null) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOENT);
        }

        if (!FS.isDir(lookup.node.mode)) {
          throw new FS.ErrnoError(ERRNO_CODES.ENOTDIR);
        }

        var err = FS.nodePermissions(lookup.node, "x");

        if (err) {
          throw new FS.ErrnoError(err);
        }

        FS.currentPath = lookup.path;
      },
      createDefaultDirectories: function createDefaultDirectories() {
        FS.mkdir("/tmp");
        FS.mkdir("/home");
        FS.mkdir("/home/web_user");
      },
      createDefaultDevices: function createDefaultDevices() {
        FS.mkdir("/dev");
        FS.registerDevice(FS.makedev(1, 3), {
          read: function read() {
            return 0;
          },
          write: function write(stream, buffer, offset, length, pos) {
            return length;
          }
        });
        FS.mkdev("/dev/null", FS.makedev(1, 3));
        TTY.register(FS.makedev(5, 0), TTY.default_tty_ops);
        TTY.register(FS.makedev(6, 0), TTY.default_tty1_ops);
        FS.mkdev("/dev/tty", FS.makedev(5, 0));
        FS.mkdev("/dev/tty1", FS.makedev(6, 0));
        var random_device;

        if (typeof crypto !== "undefined") {
          var randomBuffer = new Uint8Array(1);

          random_device = function random_device() {
            crypto.getRandomValues(randomBuffer);
            return randomBuffer[0];
          };
        } else if (ENVIRONMENT_IS_NODE) {
          random_device = function random_device() {
            return require("crypto")["randomBytes"](1)[0];
          };
        } else {
          random_device = function random_device() {
            return Math.random() * 256 | 0;
          };
        }

        FS.createDevice("/dev", "random", random_device);
        FS.createDevice("/dev", "urandom", random_device);
        FS.mkdir("/dev/shm");
        FS.mkdir("/dev/shm/tmp");
      },
      createSpecialDirectories: function createSpecialDirectories() {
        FS.mkdir("/proc");
        FS.mkdir("/proc/self");
        FS.mkdir("/proc/self/fd");
        FS.mount({
          mount: function mount() {
            var node = FS.createNode("/proc/self", "fd", 16384 | 511, 73);
            node.node_ops = {
              lookup: function lookup(parent, name) {
                var fd = +name;
                var stream = FS.getStream(fd);
                if (!stream) throw new FS.ErrnoError(ERRNO_CODES.EBADF);
                var ret = {
                  parent: null,
                  mount: {
                    mountpoint: "fake"
                  },
                  node_ops: {
                    readlink: function readlink() {
                      return stream.path;
                    }
                  }
                };
                ret.parent = ret;
                return ret;
              }
            };
            return node;
          }
        }, {}, "/proc/self/fd");
      },
      createStandardStreams: function createStandardStreams() {
        if (Module["stdin"]) {
          FS.createDevice("/dev", "stdin", Module["stdin"]);
        } else {
          FS.symlink("/dev/tty", "/dev/stdin");
        }

        if (Module["stdout"]) {
          FS.createDevice("/dev", "stdout", null, Module["stdout"]);
        } else {
          FS.symlink("/dev/tty", "/dev/stdout");
        }

        if (Module["stderr"]) {
          FS.createDevice("/dev", "stderr", null, Module["stderr"]);
        } else {
          FS.symlink("/dev/tty1", "/dev/stderr");
        }

        var stdin = FS.open("/dev/stdin", "r");
        assert(stdin.fd === 0, "invalid handle for stdin (" + stdin.fd + ")");
        var stdout = FS.open("/dev/stdout", "w");
        assert(stdout.fd === 1, "invalid handle for stdout (" + stdout.fd + ")");
        var stderr = FS.open("/dev/stderr", "w");
        assert(stderr.fd === 2, "invalid handle for stderr (" + stderr.fd + ")");
      },
      ensureErrnoError: function ensureErrnoError() {
        if (FS.ErrnoError) return;

        FS.ErrnoError = function ErrnoError(errno, node) {
          this.node = node;

          this.setErrno = function (errno) {
            this.errno = errno;

            for (var key in ERRNO_CODES) {
              if (ERRNO_CODES[key] === errno) {
                this.code = key;
                break;
              }
            }
          };

          this.setErrno(errno);
          this.message = ERRNO_MESSAGES[errno];
          if (this.stack) Object.defineProperty(this, "stack", {
            value: new Error().stack,
            writable: true
          });
          if (this.stack) this.stack = demangleAll(this.stack);
        };

        FS.ErrnoError.prototype = new Error();
        FS.ErrnoError.prototype.constructor = FS.ErrnoError;
        [ERRNO_CODES.ENOENT].forEach(function (code) {
          FS.genericErrors[code] = new FS.ErrnoError(code);
          FS.genericErrors[code].stack = "<generic error, no stack>";
        });
      },
      staticInit: function staticInit() {
        FS.ensureErrnoError();
        FS.nameTable = new Array(4096);
        FS.mount(MEMFS, {}, "/");
        FS.createDefaultDirectories();
        FS.createDefaultDevices();
        FS.createSpecialDirectories();
        FS.filesystems = {
          "MEMFS": MEMFS,
          "IDBFS": IDBFS,
          "NODEFS": NODEFS,
          "WORKERFS": WORKERFS
        };
      },
      init: function init(input, output, error) {
        assert(!FS.init.initialized, "FS.init was previously called. If you want to initialize later with custom parameters, remove any earlier calls (note that one is automatically added to the generated code)");
        FS.init.initialized = true;
        FS.ensureErrnoError();
        Module["stdin"] = input || Module["stdin"];
        Module["stdout"] = output || Module["stdout"];
        Module["stderr"] = error || Module["stderr"];
        FS.createStandardStreams();
      },
      quit: function quit() {
        FS.init.initialized = false;
        var fflush = Module["_fflush"];
        if (fflush) fflush(0);

        for (var i = 0; i < FS.streams.length; i++) {
          var stream = FS.streams[i];

          if (!stream) {
            continue;
          }

          FS.close(stream);
        }
      },
      getMode: function getMode(canRead, canWrite) {
        var mode = 0;
        if (canRead) mode |= 292 | 73;
        if (canWrite) mode |= 146;
        return mode;
      },
      joinPath: function joinPath(parts, forceRelative) {
        var path = PATH.join.apply(null, parts);
        if (forceRelative && path[0] == "/") path = path.substr(1);
        return path;
      },
      absolutePath: function absolutePath(relative, base) {
        return PATH.resolve(base, relative);
      },
      standardizePath: function standardizePath(path) {
        return PATH.normalize(path);
      },
      findObject: function findObject(path, dontResolveLastLink) {
        var ret = FS.analyzePath(path, dontResolveLastLink);

        if (ret.exists) {
          return ret.object;
        } else {
          ___setErrNo(ret.error);

          return null;
        }
      },
      analyzePath: function analyzePath(path, dontResolveLastLink) {
        try {
          var lookup = FS.lookupPath(path, {
            follow: !dontResolveLastLink
          });
          path = lookup.path;
        } catch (e) {}

        var ret = {
          isRoot: false,
          exists: false,
          error: 0,
          name: null,
          path: null,
          object: null,
          parentExists: false,
          parentPath: null,
          parentObject: null
        };

        try {
          var lookup = FS.lookupPath(path, {
            parent: true
          });
          ret.parentExists = true;
          ret.parentPath = lookup.path;
          ret.parentObject = lookup.node;
          ret.name = PATH.basename(path);
          lookup = FS.lookupPath(path, {
            follow: !dontResolveLastLink
          });
          ret.exists = true;
          ret.path = lookup.path;
          ret.object = lookup.node;
          ret.name = lookup.node.name;
          ret.isRoot = lookup.path === "/";
        } catch (e) {
          ret.error = e.errno;
        }

        return ret;
      },
      createFolder: function createFolder(parent, name, canRead, canWrite) {
        var path = PATH.join2(typeof parent === "string" ? parent : FS.getPath(parent), name);
        var mode = FS.getMode(canRead, canWrite);
        return FS.mkdir(path, mode);
      },
      createPath: function createPath(parent, path, canRead, canWrite) {
        parent = typeof parent === "string" ? parent : FS.getPath(parent);
        var parts = path.split("/").reverse();

        while (parts.length) {
          var part = parts.pop();
          if (!part) continue;
          var current = PATH.join2(parent, part);

          try {
            FS.mkdir(current);
          } catch (e) {}

          parent = current;
        }

        return current;
      },
      createFile: function createFile(parent, name, properties, canRead, canWrite) {
        var path = PATH.join2(typeof parent === "string" ? parent : FS.getPath(parent), name);
        var mode = FS.getMode(canRead, canWrite);
        return FS.create(path, mode);
      },
      createDataFile: function createDataFile(parent, name, data, canRead, canWrite, canOwn) {
        var path = name ? PATH.join2(typeof parent === "string" ? parent : FS.getPath(parent), name) : parent;
        var mode = FS.getMode(canRead, canWrite);
        var node = FS.create(path, mode);

        if (data) {
          if (typeof data === "string") {
            var arr = new Array(data.length);

            for (var i = 0, len = data.length; i < len; ++i) {
              arr[i] = data.charCodeAt(i);
            }

            data = arr;
          }

          FS.chmod(node, mode | 146);
          var stream = FS.open(node, "w");
          FS.write(stream, data, 0, data.length, 0, canOwn);
          FS.close(stream);
          FS.chmod(node, mode);
        }

        return node;
      },
      createDevice: function createDevice(parent, name, input, output) {
        var path = PATH.join2(typeof parent === "string" ? parent : FS.getPath(parent), name);
        var mode = FS.getMode(!!input, !!output);
        if (!FS.createDevice.major) FS.createDevice.major = 64;
        var dev = FS.makedev(FS.createDevice.major++, 0);
        FS.registerDevice(dev, {
          open: function open(stream) {
            stream.seekable = false;
          },
          close: function close(stream) {
            if (output && output.buffer && output.buffer.length) {
              output(10);
            }
          },
          read: function read(stream, buffer, offset, length, pos) {
            var bytesRead = 0;

            for (var i = 0; i < length; i++) {
              var result;

              try {
                result = input();
              } catch (e) {
                throw new FS.ErrnoError(ERRNO_CODES.EIO);
              }

              if (result === undefined && bytesRead === 0) {
                throw new FS.ErrnoError(ERRNO_CODES.EAGAIN);
              }

              if (result === null || result === undefined) break;
              bytesRead++;
              buffer[offset + i] = result;
            }

            if (bytesRead) {
              stream.node.timestamp = Date.now();
            }

            return bytesRead;
          },
          write: function write(stream, buffer, offset, length, pos) {
            for (var i = 0; i < length; i++) {
              try {
                output(buffer[offset + i]);
              } catch (e) {
                throw new FS.ErrnoError(ERRNO_CODES.EIO);
              }
            }

            if (length) {
              stream.node.timestamp = Date.now();
            }

            return i;
          }
        });
        return FS.mkdev(path, mode, dev);
      },
      createLink: function createLink(parent, name, target, canRead, canWrite) {
        var path = PATH.join2(typeof parent === "string" ? parent : FS.getPath(parent), name);
        return FS.symlink(target, path);
      },
      forceLoadFile: function forceLoadFile(obj) {
        if (obj.isDevice || obj.isFolder || obj.link || obj.contents) return true;
        var success = true;

        if (typeof XMLHttpRequest !== "undefined") {
          throw new Error("Lazy loading should have been performed (contents set) in createLazyFile, but it was not. Lazy loading only works in web workers. Use --embed-file or --preload-file in emcc on the main thread.");
        } else if (Module["read"]) {
          try {
            obj.contents = intArrayFromString(Module["read"](obj.url), true);
            obj.usedBytes = obj.contents.length;
          } catch (e) {
            success = false;
          }
        } else {
          throw new Error("Cannot load without read() or XMLHttpRequest.");
        }

        if (!success) ___setErrNo(ERRNO_CODES.EIO);
        return success;
      },
      createLazyFile: function createLazyFile(parent, name, url, canRead, canWrite) {
        function LazyUint8Array() {
          this.lengthKnown = false;
          this.chunks = [];
        }

        LazyUint8Array.prototype.get = function LazyUint8Array_get(idx) {
          if (idx > this.length - 1 || idx < 0) {
            return undefined;
          }

          var chunkOffset = idx % this.chunkSize;
          var chunkNum = idx / this.chunkSize | 0;
          return this.getter(chunkNum)[chunkOffset];
        };

        LazyUint8Array.prototype.setDataGetter = function LazyUint8Array_setDataGetter(getter) {
          this.getter = getter;
        };

        LazyUint8Array.prototype.cacheLength = function LazyUint8Array_cacheLength() {
          var xhr = new XMLHttpRequest();
          xhr.open("HEAD", url, false);
          xhr.send(null);
          if (!(xhr.status >= 200 && xhr.status < 300 || xhr.status === 304)) throw new Error("Couldn't load " + url + ". Status: " + xhr.status);
          var datalength = Number(xhr.getResponseHeader("Content-length"));
          var header;
          var hasByteServing = (header = xhr.getResponseHeader("Accept-Ranges")) && header === "bytes";
          var usesGzip = (header = xhr.getResponseHeader("Content-Encoding")) && header === "gzip";
          var chunkSize = 1024 * 1024;
          if (!hasByteServing) chunkSize = datalength;

          var doXHR = function doXHR(from, to) {
            if (from > to) throw new Error("invalid range (" + from + ", " + to + ") or no bytes requested!");
            if (to > datalength - 1) throw new Error("only " + datalength + " bytes available! programmer error!");
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url, false);
            if (datalength !== chunkSize) xhr.setRequestHeader("Range", "bytes=" + from + "-" + to);
            if (typeof Uint8Array != "undefined") xhr.responseType = "arraybuffer";

            if (xhr.overrideMimeType) {
              xhr.overrideMimeType("text/plain; charset=x-user-defined");
            }

            xhr.send(null);
            if (!(xhr.status >= 200 && xhr.status < 300 || xhr.status === 304)) throw new Error("Couldn't load " + url + ". Status: " + xhr.status);

            if (xhr.response !== undefined) {
              return new Uint8Array(xhr.response || []);
            } else {
              return intArrayFromString(xhr.responseText || "", true);
            }
          };

          var lazyArray = this;
          lazyArray.setDataGetter(function (chunkNum) {
            var start = chunkNum * chunkSize;
            var end = (chunkNum + 1) * chunkSize - 1;
            end = Math.min(end, datalength - 1);

            if (typeof lazyArray.chunks[chunkNum] === "undefined") {
              lazyArray.chunks[chunkNum] = doXHR(start, end);
            }

            if (typeof lazyArray.chunks[chunkNum] === "undefined") throw new Error("doXHR failed!");
            return lazyArray.chunks[chunkNum];
          });

          if (usesGzip || !datalength) {
            chunkSize = datalength = 1;
            datalength = this.getter(0).length;
            chunkSize = datalength;
            console.log("LazyFiles on gzip forces download of the whole file when length is accessed");
          }

          this._length = datalength;
          this._chunkSize = chunkSize;
          this.lengthKnown = true;
        };

        if (typeof XMLHttpRequest !== "undefined") {
          if (!ENVIRONMENT_IS_WORKER) throw "Cannot do synchronous binary XHRs outside webworkers in modern browsers. Use --embed-file or --preload-file in emcc";
          var lazyArray = new LazyUint8Array();
          Object.defineProperties(lazyArray, {
            length: {
              get: function get() {
                if (!this.lengthKnown) {
                  this.cacheLength();
                }

                return this._length;
              }
            },
            chunkSize: {
              get: function get() {
                if (!this.lengthKnown) {
                  this.cacheLength();
                }

                return this._chunkSize;
              }
            }
          });
          var properties = {
            isDevice: false,
            contents: lazyArray
          };
        } else {
          var properties = {
            isDevice: false,
            url: url
          };
        }

        var node = FS.createFile(parent, name, properties, canRead, canWrite);

        if (properties.contents) {
          node.contents = properties.contents;
        } else if (properties.url) {
          node.contents = null;
          node.url = properties.url;
        }

        Object.defineProperties(node, {
          usedBytes: {
            get: function get() {
              return this.contents.length;
            }
          }
        });
        var stream_ops = {};
        var keys = Object.keys(node.stream_ops);
        keys.forEach(function (key) {
          var fn = node.stream_ops[key];

          stream_ops[key] = function forceLoadLazyFile() {
            if (!FS.forceLoadFile(node)) {
              throw new FS.ErrnoError(ERRNO_CODES.EIO);
            }

            return fn.apply(null, arguments);
          };
        });

        stream_ops.read = function stream_ops_read(stream, buffer, offset, length, position) {
          if (!FS.forceLoadFile(node)) {
            throw new FS.ErrnoError(ERRNO_CODES.EIO);
          }

          var contents = stream.node.contents;
          if (position >= contents.length) return 0;
          var size = Math.min(contents.length - position, length);
          assert(size >= 0);

          if (contents.slice) {
            for (var i = 0; i < size; i++) {
              buffer[offset + i] = contents[position + i];
            }
          } else {
            for (var i = 0; i < size; i++) {
              buffer[offset + i] = contents.get(position + i);
            }
          }

          return size;
        };

        node.stream_ops = stream_ops;
        return node;
      },
      createPreloadedFile: function createPreloadedFile(parent, name, url, canRead, canWrite, onload, onerror, dontCreateFile, canOwn, preFinish) {
        Browser.init();
        var fullname = name ? PATH.resolve(PATH.join2(parent, name)) : parent;
        var dep = getUniqueRunDependency("cp " + fullname);

        function processData(byteArray) {
          function finish(byteArray) {
            if (preFinish) preFinish();

            if (!dontCreateFile) {
              FS.createDataFile(parent, name, byteArray, canRead, canWrite, canOwn);
            }

            if (onload) onload();
            removeRunDependency(dep);
          }

          var handled = false;
          Module["preloadPlugins"].forEach(function (plugin) {
            if (handled) return;

            if (plugin["canHandle"](fullname)) {
              plugin["handle"](byteArray, fullname, finish, function () {
                if (onerror) onerror();
                removeRunDependency(dep);
              });
              handled = true;
            }
          });
          if (!handled) finish(byteArray);
        }

        addRunDependency(dep);

        if (typeof url == "string") {
          Browser.asyncLoad(url, function (byteArray) {
            processData(byteArray);
          }, onerror);
        } else {
          processData(url);
        }
      },
      indexedDB: function indexedDB() {
        return window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
      },
      DB_NAME: function DB_NAME() {
        return "EM_FS_" + window.location.pathname;
      },
      DB_VERSION: 20,
      DB_STORE_NAME: "FILE_DATA",
      saveFilesToDB: function saveFilesToDB(paths, onload, onerror) {
        onload = onload || function () {};

        onerror = onerror || function () {};

        var indexedDB = FS.indexedDB();

        try {
          var openRequest = indexedDB.open(FS.DB_NAME(), FS.DB_VERSION);
        } catch (e) {
          return onerror(e);
        }

        openRequest.onupgradeneeded = function openRequest_onupgradeneeded() {
          console.log("creating db");
          var db = openRequest.result;
          db.createObjectStore(FS.DB_STORE_NAME);
        };

        openRequest.onsuccess = function openRequest_onsuccess() {
          var db = openRequest.result;
          var transaction = db.transaction([FS.DB_STORE_NAME], "readwrite");
          var files = transaction.objectStore(FS.DB_STORE_NAME);
          var ok = 0,
              fail = 0,
              total = paths.length;

          function finish() {
            if (fail == 0) onload();else onerror();
          }

          paths.forEach(function (path) {
            var putRequest = files.put(FS.analyzePath(path).object.contents, path);

            putRequest.onsuccess = function putRequest_onsuccess() {
              ok++;
              if (ok + fail == total) finish();
            };

            putRequest.onerror = function putRequest_onerror() {
              fail++;
              if (ok + fail == total) finish();
            };
          });
          transaction.onerror = onerror;
        };

        openRequest.onerror = onerror;
      },
      loadFilesFromDB: function loadFilesFromDB(paths, onload, onerror) {
        onload = onload || function () {};

        onerror = onerror || function () {};

        var indexedDB = FS.indexedDB();

        try {
          var openRequest = indexedDB.open(FS.DB_NAME(), FS.DB_VERSION);
        } catch (e) {
          return onerror(e);
        }

        openRequest.onupgradeneeded = onerror;

        openRequest.onsuccess = function openRequest_onsuccess() {
          var db = openRequest.result;

          try {
            var transaction = db.transaction([FS.DB_STORE_NAME], "readonly");
          } catch (e) {
            onerror(e);
            return;
          }

          var files = transaction.objectStore(FS.DB_STORE_NAME);
          var ok = 0,
              fail = 0,
              total = paths.length;

          function finish() {
            if (fail == 0) onload();else onerror();
          }

          paths.forEach(function (path) {
            var getRequest = files.get(path);

            getRequest.onsuccess = function getRequest_onsuccess() {
              if (FS.analyzePath(path).exists) {
                FS.unlink(path);
              }

              FS.createDataFile(PATH.dirname(path), PATH.basename(path), getRequest.result, true, true, true);
              ok++;
              if (ok + fail == total) finish();
            };

            getRequest.onerror = function getRequest_onerror() {
              fail++;
              if (ok + fail == total) finish();
            };
          });
          transaction.onerror = onerror;
        };

        openRequest.onerror = onerror;
      }
    };
    var SYSCALLS = {
      DEFAULT_POLLMASK: 5,
      mappings: {},
      umask: 511,
      calculateAt: function calculateAt(dirfd, path) {
        if (path[0] !== "/") {
          var dir;

          if (dirfd === -100) {
            dir = FS.cwd();
          } else {
            var dirstream = FS.getStream(dirfd);
            if (!dirstream) throw new FS.ErrnoError(ERRNO_CODES.EBADF);
            dir = dirstream.path;
          }

          path = PATH.join2(dir, path);
        }

        return path;
      },
      doStat: function doStat(func, path, buf) {
        try {
          var stat = func(path);
        } catch (e) {
          if (e && e.node && PATH.normalize(path) !== PATH.normalize(FS.getPath(e.node))) {
            return -ERRNO_CODES.ENOTDIR;
          }

          throw e;
        }

        HEAP32[buf >> 2] = stat.dev;
        HEAP32[buf + 4 >> 2] = 0;
        HEAP32[buf + 8 >> 2] = stat.ino;
        HEAP32[buf + 12 >> 2] = stat.mode;
        HEAP32[buf + 16 >> 2] = stat.nlink;
        HEAP32[buf + 20 >> 2] = stat.uid;
        HEAP32[buf + 24 >> 2] = stat.gid;
        HEAP32[buf + 28 >> 2] = stat.rdev;
        HEAP32[buf + 32 >> 2] = 0;
        HEAP32[buf + 36 >> 2] = stat.size;
        HEAP32[buf + 40 >> 2] = 4096;
        HEAP32[buf + 44 >> 2] = stat.blocks;
        HEAP32[buf + 48 >> 2] = stat.atime.getTime() / 1e3 | 0;
        HEAP32[buf + 52 >> 2] = 0;
        HEAP32[buf + 56 >> 2] = stat.mtime.getTime() / 1e3 | 0;
        HEAP32[buf + 60 >> 2] = 0;
        HEAP32[buf + 64 >> 2] = stat.ctime.getTime() / 1e3 | 0;
        HEAP32[buf + 68 >> 2] = 0;
        HEAP32[buf + 72 >> 2] = stat.ino;
        return 0;
      },
      doMsync: function doMsync(addr, stream, len, flags) {
        var buffer = new Uint8Array(HEAPU8.subarray(addr, addr + len));
        FS.msync(stream, buffer, 0, len, flags);
      },
      doMkdir: function doMkdir(path, mode) {
        path = PATH.normalize(path);
        if (path[path.length - 1] === "/") path = path.substr(0, path.length - 1);
        FS.mkdir(path, mode, 0);
        return 0;
      },
      doMknod: function doMknod(path, mode, dev) {
        switch (mode & 61440) {
          case 32768:
          case 8192:
          case 24576:
          case 4096:
          case 49152:
            break;

          default:
            return -ERRNO_CODES.EINVAL;
        }

        FS.mknod(path, mode, dev);
        return 0;
      },
      doReadlink: function doReadlink(path, buf, bufsize) {
        if (bufsize <= 0) return -ERRNO_CODES.EINVAL;
        var ret = FS.readlink(path);
        var len = Math.min(bufsize, lengthBytesUTF8(ret));
        var endChar = HEAP8[buf + len];
        stringToUTF8(ret, buf, bufsize + 1);
        HEAP8[buf + len] = endChar;
        return len;
      },
      doAccess: function doAccess(path, amode) {
        if (amode & ~7) {
          return -ERRNO_CODES.EINVAL;
        }

        var node;
        var lookup = FS.lookupPath(path, {
          follow: true
        });
        node = lookup.node;
        var perms = "";
        if (amode & 4) perms += "r";
        if (amode & 2) perms += "w";
        if (amode & 1) perms += "x";

        if (perms && FS.nodePermissions(node, perms)) {
          return -ERRNO_CODES.EACCES;
        }

        return 0;
      },
      doDup: function doDup(path, flags, suggestFD) {
        var suggest = FS.getStream(suggestFD);
        if (suggest) FS.close(suggest);
        return FS.open(path, flags, 0, suggestFD, suggestFD).fd;
      },
      doReadv: function doReadv(stream, iov, iovcnt, offset) {
        var ret = 0;

        for (var i = 0; i < iovcnt; i++) {
          var ptr = HEAP32[iov + i * 8 >> 2];
          var len = HEAP32[iov + (i * 8 + 4) >> 2];
          var curr = FS.read(stream, HEAP8, ptr, len, offset);
          if (curr < 0) return -1;
          ret += curr;
          if (curr < len) break;
        }

        return ret;
      },
      doWritev: function doWritev(stream, iov, iovcnt, offset) {
        var ret = 0;

        for (var i = 0; i < iovcnt; i++) {
          var ptr = HEAP32[iov + i * 8 >> 2];
          var len = HEAP32[iov + (i * 8 + 4) >> 2];
          var curr = FS.write(stream, HEAP8, ptr, len, offset);
          if (curr < 0) return -1;
          ret += curr;
        }

        return ret;
      },
      varargs: 0,
      get: function get(varargs) {
        SYSCALLS.varargs += 4;
        var ret = HEAP32[SYSCALLS.varargs - 4 >> 2];
        return ret;
      },
      getStr: function getStr() {
        var ret = Pointer_stringify(SYSCALLS.get());
        return ret;
      },
      getStreamFromFD: function getStreamFromFD() {
        var stream = FS.getStream(SYSCALLS.get());
        if (!stream) throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        return stream;
      },
      getSocketFromFD: function getSocketFromFD() {
        var socket = SOCKFS.getSocket(SYSCALLS.get());
        if (!socket) throw new FS.ErrnoError(ERRNO_CODES.EBADF);
        return socket;
      },
      getSocketAddress: function getSocketAddress(allowNull) {
        var addrp = SYSCALLS.get(),
            addrlen = SYSCALLS.get();
        if (allowNull && addrp === 0) return null;

        var info = __read_sockaddr(addrp, addrlen);

        if (info.errno) throw new FS.ErrnoError(info.errno);
        info.addr = DNS.lookup_addr(info.addr) || info.addr;
        return info;
      },
      get64: function get64() {
        var low = SYSCALLS.get(),
            high = SYSCALLS.get();
        if (low >= 0) assert(high === 0);else assert(high === -1);
        return low;
      },
      getZero: function getZero() {
        assert(SYSCALLS.get() === 0);
      }
    };

    function ___syscall140(which, varargs) {
      SYSCALLS.varargs = varargs;

      try {
        var stream = SYSCALLS.getStreamFromFD(),
            offset_high = SYSCALLS.get(),
            offset_low = SYSCALLS.get(),
            result = SYSCALLS.get(),
            whence = SYSCALLS.get();
        var offset = offset_low;
        FS.llseek(stream, offset, whence);
        HEAP32[result >> 2] = stream.position;
        if (stream.getdents && offset === 0 && whence === 0) stream.getdents = null;
        return 0;
      } catch (e) {
        if (typeof FS === "undefined" || !(e instanceof FS.ErrnoError)) abort(e);
        return -e.errno;
      }
    }

    function ___syscall146(which, varargs) {
      SYSCALLS.varargs = varargs;

      try {
        var stream = SYSCALLS.getStreamFromFD(),
            iov = SYSCALLS.get(),
            iovcnt = SYSCALLS.get();
        return SYSCALLS.doWritev(stream, iov, iovcnt);
      } catch (e) {
        if (typeof FS === "undefined" || !(e instanceof FS.ErrnoError)) abort(e);
        return -e.errno;
      }
    }

    function ___syscall54(which, varargs) {
      SYSCALLS.varargs = varargs;

      try {
        var stream = SYSCALLS.getStreamFromFD(),
            op = SYSCALLS.get();

        switch (op) {
          case 21509:
          case 21505:
            {
              if (!stream.tty) return -ERRNO_CODES.ENOTTY;
              return 0;
            }
            ;

          case 21510:
          case 21511:
          case 21512:
          case 21506:
          case 21507:
          case 21508:
            {
              if (!stream.tty) return -ERRNO_CODES.ENOTTY;
              return 0;
            }
            ;

          case 21519:
            {
              if (!stream.tty) return -ERRNO_CODES.ENOTTY;
              var argp = SYSCALLS.get();
              HEAP32[argp >> 2] = 0;
              return 0;
            }
            ;

          case 21520:
            {
              if (!stream.tty) return -ERRNO_CODES.ENOTTY;
              return -ERRNO_CODES.EINVAL;
            }
            ;

          case 21531:
            {
              var argp = SYSCALLS.get();
              return FS.ioctl(stream, op, argp);
            }
            ;

          case 21523:
            {
              if (!stream.tty) return -ERRNO_CODES.ENOTTY;
              return 0;
            }
            ;

          case 21524:
            {
              if (!stream.tty) return -ERRNO_CODES.ENOTTY;
              return 0;
            }
            ;

          default:
            abort("bad ioctl syscall " + op);
        }
      } catch (e) {
        if (typeof FS === "undefined" || !(e instanceof FS.ErrnoError)) abort(e);
        return -e.errno;
      }
    }

    function ___syscall6(which, varargs) {
      SYSCALLS.varargs = varargs;

      try {
        var stream = SYSCALLS.getStreamFromFD();
        FS.close(stream);
        return 0;
      } catch (e) {
        if (typeof FS === "undefined" || !(e instanceof FS.ErrnoError)) abort(e);
        return -e.errno;
      }
    }

    function ___unlock() {}

    function getShiftFromSize(size) {
      switch (size) {
        case 1:
          return 0;

        case 2:
          return 1;

        case 4:
          return 2;

        case 8:
          return 3;

        default:
          throw new TypeError("Unknown type size: " + size);
      }
    }

    function embind_init_charCodes() {
      var codes = new Array(256);

      for (var i = 0; i < 256; ++i) {
        codes[i] = String.fromCharCode(i);
      }

      embind_charCodes = codes;
    }

    var embind_charCodes = undefined;

    function readLatin1String(ptr) {
      var ret = "";
      var c = ptr;

      while (HEAPU8[c]) {
        ret += embind_charCodes[HEAPU8[c++]];
      }

      return ret;
    }

    var awaitingDependencies = {};
    var registeredTypes = {};
    var typeDependencies = {};
    var char_0 = 48;
    var char_9 = 57;

    function makeLegalFunctionName(name) {
      if (undefined === name) {
        return "_unknown";
      }

      name = name.replace(/[^a-zA-Z0-9_]/g, "$");
      var f = name.charCodeAt(0);

      if (f >= char_0 && f <= char_9) {
        return "_" + name;
      } else {
        return name;
      }
    }

    function createNamedFunction(name, body) {
      name = makeLegalFunctionName(name);
      return new Function("body", "return function " + name + "() {\n" + '    "use strict";' + "    return body.apply(this, arguments);\n" + "};\n")(body);
    }

    function extendError(baseErrorType, errorName) {
      var errorClass = createNamedFunction(errorName, function (message) {
        this.name = errorName;
        this.message = message;
        var stack = new Error(message).stack;

        if (stack !== undefined) {
          this.stack = this.toString() + "\n" + stack.replace(/^Error(:[^\n]*)?\n/, "");
        }
      });
      errorClass.prototype = Object.create(baseErrorType.prototype);
      errorClass.prototype.constructor = errorClass;

      errorClass.prototype.toString = function () {
        if (this.message === undefined) {
          return this.name;
        } else {
          return this.name + ": " + this.message;
        }
      };

      return errorClass;
    }

    var BindingError = undefined;

    function throwBindingError(message) {
      throw new BindingError(message);
    }

    var InternalError = undefined;

    function throwInternalError(message) {
      throw new InternalError(message);
    }

    function whenDependentTypesAreResolved(myTypes, dependentTypes, getTypeConverters) {
      myTypes.forEach(function (type) {
        typeDependencies[type] = dependentTypes;
      });

      function onComplete(typeConverters) {
        var myTypeConverters = getTypeConverters(typeConverters);

        if (myTypeConverters.length !== myTypes.length) {
          throwInternalError("Mismatched type converter count");
        }

        for (var i = 0; i < myTypes.length; ++i) {
          registerType(myTypes[i], myTypeConverters[i]);
        }
      }

      var typeConverters = new Array(dependentTypes.length);
      var unregisteredTypes = [];
      var registered = 0;
      dependentTypes.forEach(function (dt, i) {
        if (registeredTypes.hasOwnProperty(dt)) {
          typeConverters[i] = registeredTypes[dt];
        } else {
          unregisteredTypes.push(dt);

          if (!awaitingDependencies.hasOwnProperty(dt)) {
            awaitingDependencies[dt] = [];
          }

          awaitingDependencies[dt].push(function () {
            typeConverters[i] = registeredTypes[dt];
            ++registered;

            if (registered === unregisteredTypes.length) {
              onComplete(typeConverters);
            }
          });
        }
      });

      if (0 === unregisteredTypes.length) {
        onComplete(typeConverters);
      }
    }

    function registerType(rawType, registeredInstance, options) {
      options = options || {};

      if (!("argPackAdvance" in registeredInstance)) {
        throw new TypeError("registerType registeredInstance requires argPackAdvance");
      }

      var name = registeredInstance.name;

      if (!rawType) {
        throwBindingError('type "' + name + '" must have a positive integer typeid pointer');
      }

      if (registeredTypes.hasOwnProperty(rawType)) {
        if (options.ignoreDuplicateRegistrations) {
          return;
        } else {
          throwBindingError("Cannot register type '" + name + "' twice");
        }
      }

      registeredTypes[rawType] = registeredInstance;
      delete typeDependencies[rawType];

      if (awaitingDependencies.hasOwnProperty(rawType)) {
        var callbacks = awaitingDependencies[rawType];
        delete awaitingDependencies[rawType];
        callbacks.forEach(function (cb) {
          cb();
        });
      }
    }

    function __embind_register_bool(rawType, name, size, trueValue, falseValue) {
      var shift = getShiftFromSize(size);
      name = readLatin1String(name);
      registerType(rawType, {
        name: name,
        "fromWireType": function fromWireType(wt) {
          return !!wt;
        },
        "toWireType": function toWireType(destructors, o) {
          return o ? trueValue : falseValue;
        },
        "argPackAdvance": 8,
        "readValueFromPointer": function readValueFromPointer(pointer) {
          var heap;

          if (size === 1) {
            heap = HEAP8;
          } else if (size === 2) {
            heap = HEAP16;
          } else if (size === 4) {
            heap = HEAP32;
          } else {
            throw new TypeError("Unknown boolean type size: " + name);
          }

          return this["fromWireType"](heap[pointer >> shift]);
        },
        destructorFunction: null
      });
    }

    var emval_free_list = [];
    var emval_handle_array = [{}, {
      value: undefined
    }, {
      value: null
    }, {
      value: true
    }, {
      value: false
    }];

    function __emval_decref(handle) {
      if (handle > 4 && 0 === --emval_handle_array[handle].refcount) {
        emval_handle_array[handle] = undefined;
        emval_free_list.push(handle);
      }
    }

    function count_emval_handles() {
      var count = 0;

      for (var i = 5; i < emval_handle_array.length; ++i) {
        if (emval_handle_array[i] !== undefined) {
          ++count;
        }
      }

      return count;
    }

    function get_first_emval() {
      for (var i = 5; i < emval_handle_array.length; ++i) {
        if (emval_handle_array[i] !== undefined) {
          return emval_handle_array[i];
        }
      }

      return null;
    }

    function init_emval() {
      Module["count_emval_handles"] = count_emval_handles;
      Module["get_first_emval"] = get_first_emval;
    }

    function __emval_register(value) {
      switch (value) {
        case undefined:
          {
            return 1;
          }
          ;

        case null:
          {
            return 2;
          }
          ;

        case true:
          {
            return 3;
          }
          ;

        case false:
          {
            return 4;
          }
          ;

        default:
          {
            var handle = emval_free_list.length ? emval_free_list.pop() : emval_handle_array.length;
            emval_handle_array[handle] = {
              refcount: 1,
              value: value
            };
            return handle;
          }
      }
    }

    function simpleReadValueFromPointer(pointer) {
      return this["fromWireType"](HEAPU32[pointer >> 2]);
    }

    function __embind_register_emval(rawType, name) {
      name = readLatin1String(name);
      registerType(rawType, {
        name: name,
        "fromWireType": function fromWireType(handle) {
          var rv = emval_handle_array[handle].value;

          __emval_decref(handle);

          return rv;
        },
        "toWireType": function toWireType(destructors, value) {
          return __emval_register(value);
        },
        "argPackAdvance": 8,
        "readValueFromPointer": simpleReadValueFromPointer,
        destructorFunction: null
      });
    }

    function _embind_repr(v) {
      if (v === null) {
        return "null";
      }

      var t = typeof v;

      if (t === "object" || t === "array" || t === "function") {
        return v.toString();
      } else {
        return "" + v;
      }
    }

    function floatReadValueFromPointer(name, shift) {
      switch (shift) {
        case 2:
          return function (pointer) {
            return this["fromWireType"](HEAPF32[pointer >> 2]);
          };

        case 3:
          return function (pointer) {
            return this["fromWireType"](HEAPF64[pointer >> 3]);
          };

        default:
          throw new TypeError("Unknown float type: " + name);
      }
    }

    function __embind_register_float(rawType, name, size) {
      var shift = getShiftFromSize(size);
      name = readLatin1String(name);
      registerType(rawType, {
        name: name,
        "fromWireType": function fromWireType(value) {
          return value;
        },
        "toWireType": function toWireType(destructors, value) {
          if (typeof value !== "number" && typeof value !== "boolean") {
            throw new TypeError('Cannot convert "' + _embind_repr(value) + '" to ' + this.name);
          }

          return value;
        },
        "argPackAdvance": 8,
        "readValueFromPointer": floatReadValueFromPointer(name, shift),
        destructorFunction: null
      });
    }

    function new_(constructor, argumentList) {
      if (!(constructor instanceof Function)) {
        throw new TypeError("new_ called with constructor type " + typeof constructor + " which is not a function");
      }

      var dummy = createNamedFunction(constructor.name || "unknownFunctionName", function () {});
      dummy.prototype = constructor.prototype;
      var obj = new dummy();
      var r = constructor.apply(obj, argumentList);
      return r instanceof Object ? r : obj;
    }

    function runDestructors(destructors) {
      while (destructors.length) {
        var ptr = destructors.pop();
        var del = destructors.pop();
        del(ptr);
      }
    }

    function craftInvokerFunction(humanName, argTypes, classType, cppInvokerFunc, cppTargetFunc) {
      var argCount = argTypes.length;

      if (argCount < 2) {
        throwBindingError("argTypes array size mismatch! Must at least get return value and 'this' types!");
      }

      var isClassMethodFunc = argTypes[1] !== null && classType !== null;
      var needsDestructorStack = false;

      for (var i = 1; i < argTypes.length; ++i) {
        if (argTypes[i] !== null && argTypes[i].destructorFunction === undefined) {
          needsDestructorStack = true;
          break;
        }
      }

      var returns = argTypes[0].name !== "void";
      var argsList = "";
      var argsListWired = "";

      for (var i = 0; i < argCount - 2; ++i) {
        argsList += (i !== 0 ? ", " : "") + "arg" + i;
        argsListWired += (i !== 0 ? ", " : "") + "arg" + i + "Wired";
      }

      var invokerFnBody = "return function " + makeLegalFunctionName(humanName) + "(" + argsList + ") {\n" + "if (arguments.length !== " + (argCount - 2) + ") {\n" + "throwBindingError('function " + humanName + " called with ' + arguments.length + ' arguments, expected " + (argCount - 2) + " args!');\n" + "}\n";

      if (needsDestructorStack) {
        invokerFnBody += "var destructors = [];\n";
      }

      var dtorStack = needsDestructorStack ? "destructors" : "null";
      var args1 = ["throwBindingError", "invoker", "fn", "runDestructors", "retType", "classParam"];
      var args2 = [throwBindingError, cppInvokerFunc, cppTargetFunc, runDestructors, argTypes[0], argTypes[1]];

      if (isClassMethodFunc) {
        invokerFnBody += "var thisWired = classParam.toWireType(" + dtorStack + ", this);\n";
      }

      for (var i = 0; i < argCount - 2; ++i) {
        invokerFnBody += "var arg" + i + "Wired = argType" + i + ".toWireType(" + dtorStack + ", arg" + i + "); // " + argTypes[i + 2].name + "\n";
        args1.push("argType" + i);
        args2.push(argTypes[i + 2]);
      }

      if (isClassMethodFunc) {
        argsListWired = "thisWired" + (argsListWired.length > 0 ? ", " : "") + argsListWired;
      }

      invokerFnBody += (returns ? "var rv = " : "") + "invoker(fn" + (argsListWired.length > 0 ? ", " : "") + argsListWired + ");\n";

      if (needsDestructorStack) {
        invokerFnBody += "runDestructors(destructors);\n";
      } else {
        for (var i = isClassMethodFunc ? 1 : 2; i < argTypes.length; ++i) {
          var paramName = i === 1 ? "thisWired" : "arg" + (i - 2) + "Wired";

          if (argTypes[i].destructorFunction !== null) {
            invokerFnBody += paramName + "_dtor(" + paramName + "); // " + argTypes[i].name + "\n";
            args1.push(paramName + "_dtor");
            args2.push(argTypes[i].destructorFunction);
          }
        }
      }

      if (returns) {
        invokerFnBody += "var ret = retType.fromWireType(rv);\n" + "return ret;\n";
      } else {}

      invokerFnBody += "}\n";
      args1.push(invokerFnBody);
      var invokerFunction = new_(Function, args1).apply(null, args2);
      return invokerFunction;
    }

    function ensureOverloadTable(proto, methodName, humanName) {
      if (undefined === proto[methodName].overloadTable) {
        var prevFunc = proto[methodName];

        proto[methodName] = function () {
          if (!proto[methodName].overloadTable.hasOwnProperty(arguments.length)) {
            throwBindingError("Function '" + humanName + "' called with an invalid number of arguments (" + arguments.length + ") - expects one of (" + proto[methodName].overloadTable + ")!");
          }

          return proto[methodName].overloadTable[arguments.length].apply(this, arguments);
        };

        proto[methodName].overloadTable = [];
        proto[methodName].overloadTable[prevFunc.argCount] = prevFunc;
      }
    }

    function exposePublicSymbol(name, value, numArguments) {
      if (Module.hasOwnProperty(name)) {
        if (undefined === numArguments || undefined !== Module[name].overloadTable && undefined !== Module[name].overloadTable[numArguments]) {
          throwBindingError("Cannot register public name '" + name + "' twice");
        }

        ensureOverloadTable(Module, name, name);

        if (Module.hasOwnProperty(numArguments)) {
          throwBindingError("Cannot register multiple overloads of a function with the same number of arguments (" + numArguments + ")!");
        }

        Module[name].overloadTable[numArguments] = value;
      } else {
        Module[name] = value;

        if (undefined !== numArguments) {
          Module[name].numArguments = numArguments;
        }
      }
    }

    function heap32VectorToArray(count, firstElement) {
      var array = [];

      for (var i = 0; i < count; i++) {
        array.push(HEAP32[(firstElement >> 2) + i]);
      }

      return array;
    }

    function replacePublicSymbol(name, value, numArguments) {
      if (!Module.hasOwnProperty(name)) {
        throwInternalError("Replacing nonexistant public symbol");
      }

      if (undefined !== Module[name].overloadTable && undefined !== numArguments) {
        Module[name].overloadTable[numArguments] = value;
      } else {
        Module[name] = value;
        Module[name].argCount = numArguments;
      }
    }

    function embind__requireFunction(signature, rawFunction) {
      signature = readLatin1String(signature);

      function makeDynCaller(dynCall) {
        var args = [];

        for (var i = 1; i < signature.length; ++i) {
          args.push("a" + i);
        }

        var name = "dynCall_" + signature + "_" + rawFunction;
        var body = "return function " + name + "(" + args.join(", ") + ") {\n";
        body += "    return dynCall(rawFunction" + (args.length ? ", " : "") + args.join(", ") + ");\n";
        body += "};\n";
        return new Function("dynCall", "rawFunction", body)(dynCall, rawFunction);
      }

      var fp;

      if (Module["FUNCTION_TABLE_" + signature] !== undefined) {
        fp = Module["FUNCTION_TABLE_" + signature][rawFunction];
      } else if (typeof FUNCTION_TABLE !== "undefined") {
        fp = FUNCTION_TABLE[rawFunction];
      } else {
        var dc = Module["asm"]["dynCall_" + signature];

        if (dc === undefined) {
          dc = Module["asm"]["dynCall_" + signature.replace(/f/g, "d")];

          if (dc === undefined) {
            throwBindingError("No dynCall invoker for signature: " + signature);
          }
        }

        fp = makeDynCaller(dc);
      }

      if (typeof fp !== "function") {
        throwBindingError("unknown function pointer with signature " + signature + ": " + rawFunction);
      }

      return fp;
    }

    var UnboundTypeError = undefined;

    function getTypeName(type) {
      var ptr = ___getTypeName(type);

      var rv = readLatin1String(ptr);

      _free(ptr);

      return rv;
    }

    function throwUnboundTypeError(message, types) {
      var unboundTypes = [];
      var seen = {};

      function visit(type) {
        if (seen[type]) {
          return;
        }

        if (registeredTypes[type]) {
          return;
        }

        if (typeDependencies[type]) {
          typeDependencies[type].forEach(visit);
          return;
        }

        unboundTypes.push(type);
        seen[type] = true;
      }

      types.forEach(visit);
      throw new UnboundTypeError(message + ": " + unboundTypes.map(getTypeName).join([", "]));
    }

    function __embind_register_function(name, argCount, rawArgTypesAddr, signature, rawInvoker, fn) {
      var argTypes = heap32VectorToArray(argCount, rawArgTypesAddr);
      name = readLatin1String(name);
      rawInvoker = embind__requireFunction(signature, rawInvoker);
      exposePublicSymbol(name, function () {
        throwUnboundTypeError("Cannot call " + name + " due to unbound types", argTypes);
      }, argCount - 1);
      whenDependentTypesAreResolved([], argTypes, function (argTypes) {
        var invokerArgsArray = [argTypes[0], null].concat(argTypes.slice(1));
        replacePublicSymbol(name, craftInvokerFunction(name, invokerArgsArray, null, rawInvoker, fn), argCount - 1);
        return [];
      });
    }

    function integerReadValueFromPointer(name, shift, signed) {
      switch (shift) {
        case 0:
          return signed ? function readS8FromPointer(pointer) {
            return HEAP8[pointer];
          } : function readU8FromPointer(pointer) {
            return HEAPU8[pointer];
          };

        case 1:
          return signed ? function readS16FromPointer(pointer) {
            return HEAP16[pointer >> 1];
          } : function readU16FromPointer(pointer) {
            return HEAPU16[pointer >> 1];
          };

        case 2:
          return signed ? function readS32FromPointer(pointer) {
            return HEAP32[pointer >> 2];
          } : function readU32FromPointer(pointer) {
            return HEAPU32[pointer >> 2];
          };

        default:
          throw new TypeError("Unknown integer type: " + name);
      }
    }

    function __embind_register_integer(primitiveType, name, size, minRange, maxRange) {
      name = readLatin1String(name);

      if (maxRange === -1) {
        maxRange = 4294967295;
      }

      var shift = getShiftFromSize(size);

      var fromWireType = function fromWireType(value) {
        return value;
      };

      if (minRange === 0) {
        var bitshift = 32 - 8 * size;

        fromWireType = function fromWireType(value) {
          return value << bitshift >>> bitshift;
        };
      }

      var isUnsignedType = name.indexOf("unsigned") != -1;
      registerType(primitiveType, {
        name: name,
        "fromWireType": fromWireType,
        "toWireType": function toWireType(destructors, value) {
          if (typeof value !== "number" && typeof value !== "boolean") {
            throw new TypeError('Cannot convert "' + _embind_repr(value) + '" to ' + this.name);
          }

          if (value < minRange || value > maxRange) {
            throw new TypeError('Passing a number "' + _embind_repr(value) + '" from JS side to C/C++ side to an argument of type "' + name + '", which is outside the valid range [' + minRange + ", " + maxRange + "]!");
          }

          return isUnsignedType ? value >>> 0 : value | 0;
        },
        "argPackAdvance": 8,
        "readValueFromPointer": integerReadValueFromPointer(name, shift, minRange !== 0),
        destructorFunction: null
      });
    }

    function __embind_register_memory_view(rawType, dataTypeIndex, name) {
      var typeMapping = [Int8Array, Uint8Array, Int16Array, Uint16Array, Int32Array, Uint32Array, Float32Array, Float64Array];
      var TA = typeMapping[dataTypeIndex];

      function decodeMemoryView(handle) {
        handle = handle >> 2;
        var heap = HEAPU32;
        var size = heap[handle];
        var data = heap[handle + 1];
        return new TA(heap["buffer"], data, size);
      }

      name = readLatin1String(name);
      registerType(rawType, {
        name: name,
        "fromWireType": decodeMemoryView,
        "argPackAdvance": 8,
        "readValueFromPointer": decodeMemoryView
      }, {
        ignoreDuplicateRegistrations: true
      });
    }

    function __embind_register_std_string(rawType, name) {
      name = readLatin1String(name);
      var stdStringIsUTF8 = name === "std::string";
      registerType(rawType, {
        name: name,
        "fromWireType": function fromWireType(value) {
          var length = HEAPU32[value >> 2];
          var str;

          if (stdStringIsUTF8) {
            var endChar = HEAPU8[value + 4 + length];
            var endCharSwap = 0;

            if (endChar != 0) {
              endCharSwap = endChar;
              HEAPU8[value + 4 + length] = 0;
            }

            var decodeStartPtr = value + 4;

            for (var i = 0; i <= length; ++i) {
              var currentBytePtr = value + 4 + i;

              if (HEAPU8[currentBytePtr] == 0) {
                var stringSegment = UTF8ToString(decodeStartPtr);
                if (str === undefined) str = stringSegment;else {
                  str += String.fromCharCode(0);
                  str += stringSegment;
                }
                decodeStartPtr = currentBytePtr + 1;
              }
            }

            if (endCharSwap != 0) HEAPU8[value + 4 + length] = endCharSwap;
          } else {
            var a = new Array(length);

            for (var i = 0; i < length; ++i) {
              a[i] = String.fromCharCode(HEAPU8[value + 4 + i]);
            }

            str = a.join("");
          }

          _free(value);

          return str;
        },
        "toWireType": function toWireType(destructors, value) {
          if (value instanceof ArrayBuffer) {
            value = new Uint8Array(value);
          }

          var getLength;
          var valueIsOfTypeString = typeof value === "string";

          if (!(valueIsOfTypeString || value instanceof Uint8Array || value instanceof Uint8ClampedArray || value instanceof Int8Array)) {
            throwBindingError("Cannot pass non-string to std::string");
          }

          if (stdStringIsUTF8 && valueIsOfTypeString) {
            getLength = function getLength() {
              return lengthBytesUTF8(value);
            };
          } else {
            getLength = function getLength() {
              return value.length;
            };
          }

          var length = getLength();

          var ptr = _malloc(4 + length + 1);

          HEAPU32[ptr >> 2] = length;

          if (stdStringIsUTF8 && valueIsOfTypeString) {
            stringToUTF8(value, ptr + 4, length + 1);
          } else {
            if (valueIsOfTypeString) {
              for (var i = 0; i < length; ++i) {
                var charCode = value.charCodeAt(i);

                if (charCode > 255) {
                  _free(ptr);

                  throwBindingError("String has UTF-16 code units that do not fit in 8 bits");
                }

                HEAPU8[ptr + 4 + i] = charCode;
              }
            } else {
              for (var i = 0; i < length; ++i) {
                HEAPU8[ptr + 4 + i] = value[i];
              }
            }
          }

          if (destructors !== null) {
            destructors.push(_free, ptr);
          }

          return ptr;
        },
        "argPackAdvance": 8,
        "readValueFromPointer": simpleReadValueFromPointer,
        destructorFunction: function destructorFunction(ptr) {
          _free(ptr);
        }
      });
    }

    function __embind_register_std_wstring(rawType, charSize, name) {
      name = readLatin1String(name);
      var getHeap, shift;

      if (charSize === 2) {
        getHeap = function getHeap() {
          return HEAPU16;
        };

        shift = 1;
      } else if (charSize === 4) {
        getHeap = function getHeap() {
          return HEAPU32;
        };

        shift = 2;
      }

      registerType(rawType, {
        name: name,
        "fromWireType": function fromWireType(value) {
          var HEAP = getHeap();
          var length = HEAPU32[value >> 2];
          var a = new Array(length);
          var start = value + 4 >> shift;

          for (var i = 0; i < length; ++i) {
            a[i] = String.fromCharCode(HEAP[start + i]);
          }

          _free(value);

          return a.join("");
        },
        "toWireType": function toWireType(destructors, value) {
          var HEAP = getHeap();
          var length = value.length;

          var ptr = _malloc(4 + length * charSize);

          HEAPU32[ptr >> 2] = length;
          var start = ptr + 4 >> shift;

          for (var i = 0; i < length; ++i) {
            HEAP[start + i] = value.charCodeAt(i);
          }

          if (destructors !== null) {
            destructors.push(_free, ptr);
          }

          return ptr;
        },
        "argPackAdvance": 8,
        "readValueFromPointer": simpleReadValueFromPointer,
        destructorFunction: function destructorFunction(ptr) {
          _free(ptr);
        }
      });
    }

    function __embind_register_void(rawType, name) {
      name = readLatin1String(name);
      registerType(rawType, {
        isVoid: true,
        name: name,
        "argPackAdvance": 0,
        "fromWireType": function fromWireType() {
          return undefined;
        },
        "toWireType": function toWireType(destructors, o) {
          return undefined;
        }
      });
    }

    function _abort() {
      Module["abort"]();
    }

    function _d2i_X509_SIG() {
      err("missing function: d2i_X509_SIG");
      abort(-1);
    }

    function _i2d_X509_SIG() {
      err("missing function: i2d_X509_SIG");
      abort(-1);
    }

    function _emscripten_memcpy_big(dest, src, num) {
      HEAPU8.set(HEAPU8.subarray(src, src + num), dest);
      return dest;
    }

    Module["requestFullScreen"] = function Module_requestFullScreen(lockPointer, resizeCanvas, vrDevice) {
      err("Module.requestFullScreen is deprecated. Please call Module.requestFullscreen instead.");
      Module["requestFullScreen"] = Module["requestFullscreen"];
      Browser.requestFullScreen(lockPointer, resizeCanvas, vrDevice);
    };

    Module["requestFullscreen"] = function Module_requestFullscreen(lockPointer, resizeCanvas, vrDevice) {
      Browser.requestFullscreen(lockPointer, resizeCanvas, vrDevice);
    };

    Module["requestAnimationFrame"] = function Module_requestAnimationFrame(func) {
      Browser.requestAnimationFrame(func);
    };

    Module["setCanvasSize"] = function Module_setCanvasSize(width, height, noUpdates) {
      Browser.setCanvasSize(width, height, noUpdates);
    };

    Module["pauseMainLoop"] = function Module_pauseMainLoop() {
      Browser.mainLoop.pause();
    };

    Module["resumeMainLoop"] = function Module_resumeMainLoop() {
      Browser.mainLoop.resume();
    };

    Module["getUserMedia"] = function Module_getUserMedia() {
      Browser.getUserMedia();
    };

    Module["createContext"] = function Module_createContext(canvas, useWebGL, setInModule, webGLContextAttributes) {
      return Browser.createContext(canvas, useWebGL, setInModule, webGLContextAttributes);
    };

    if (ENVIRONMENT_IS_NODE) {
      _emscripten_get_now = function _emscripten_get_now_actual() {
        var t = process["hrtime"]();
        return t[0] * 1e3 + t[1] / 1e6;
      };
    } else if (typeof dateNow !== "undefined") {
      _emscripten_get_now = dateNow;
    } else if (typeof self === "object" && self["performance"] && typeof self["performance"]["now"] === "function") {
      _emscripten_get_now = function _emscripten_get_now() {
        return self["performance"]["now"]();
      };
    } else if (typeof performance === "object" && typeof performance["now"] === "function") {
      _emscripten_get_now = function _emscripten_get_now() {
        return performance["now"]();
      };
    } else {
      _emscripten_get_now = Date.now;
    }

    FS.staticInit();

    __ATINIT__.unshift(function () {
      if (!Module["noFSInit"] && !FS.init.initialized) FS.init();
    });

    __ATMAIN__.push(function () {
      FS.ignorePermissions = false;
    });

    __ATEXIT__.push(function () {
      FS.quit();
    });

    Module["FS_createFolder"] = FS.createFolder;
    Module["FS_createPath"] = FS.createPath;
    Module["FS_createDataFile"] = FS.createDataFile;
    Module["FS_createPreloadedFile"] = FS.createPreloadedFile;
    Module["FS_createLazyFile"] = FS.createLazyFile;
    Module["FS_createLink"] = FS.createLink;
    Module["FS_createDevice"] = FS.createDevice;
    Module["FS_unlink"] = FS.unlink;

    __ATINIT__.unshift(function () {
      TTY.init();
    });

    __ATEXIT__.push(function () {
      TTY.shutdown();
    });

    if (ENVIRONMENT_IS_NODE) {
      var fs = require("fs");

      var NODEJS_PATH = require("path");

      NODEFS.staticInit();
    }

    embind_init_charCodes();
    BindingError = Module["BindingError"] = extendError(Error, "BindingError");
    InternalError = Module["InternalError"] = extendError(Error, "InternalError");
    init_emval();
    UnboundTypeError = Module["UnboundTypeError"] = extendError(Error, "UnboundTypeError");
    DYNAMICTOP_PTR = staticAlloc(4);
    STACK_BASE = STACKTOP = alignMemory(STATICTOP);
    STACK_MAX = STACK_BASE + TOTAL_STACK;
    DYNAMIC_BASE = alignMemory(STACK_MAX);
    HEAP32[DYNAMICTOP_PTR >> 2] = DYNAMIC_BASE;
    staticSealed = true;
    assert(DYNAMIC_BASE < TOTAL_MEMORY, "TOTAL_MEMORY not big enough for stack");
    var ASSERTIONS = true;

    function intArrayFromString(stringy, dontAddNull, length) {
      var len = length > 0 ? length : lengthBytesUTF8(stringy) + 1;
      var u8array = new Array(len);
      var numBytesWritten = stringToUTF8Array(stringy, u8array, 0, u8array.length);
      if (dontAddNull) u8array.length = numBytesWritten;
      return u8array;
    }

    function nullFunc_i(x) {
      err("Invalid function pointer called with signature 'i'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_ii(x) {
      err("Invalid function pointer called with signature 'ii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_iii(x) {
      err("Invalid function pointer called with signature 'iii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_iiii(x) {
      err("Invalid function pointer called with signature 'iiii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_iiiii(x) {
      err("Invalid function pointer called with signature 'iiiii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_iiiiii(x) {
      err("Invalid function pointer called with signature 'iiiiii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_iiiiiii(x) {
      err("Invalid function pointer called with signature 'iiiiiii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_v(x) {
      err("Invalid function pointer called with signature 'v'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_vi(x) {
      err("Invalid function pointer called with signature 'vi'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_vii(x) {
      err("Invalid function pointer called with signature 'vii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_viii(x) {
      err("Invalid function pointer called with signature 'viii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_viiii(x) {
      err("Invalid function pointer called with signature 'viiii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_viiiii(x) {
      err("Invalid function pointer called with signature 'viiiii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    function nullFunc_viiiiii(x) {
      err("Invalid function pointer called with signature 'viiiiii'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this)");
      err("Build with ASSERTIONS=2 for more info.");
      abort(x);
    }

    Module["wasmTableSize"] = 103;
    Module["wasmMaxTableSize"] = 103;

    function invoke_i(index) {
      var sp = _stackSave();

      try {
        return Module["dynCall_i"](index);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_ii(index, a1) {
      var sp = _stackSave();

      try {
        return Module["dynCall_ii"](index, a1);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_iii(index, a1, a2) {
      var sp = _stackSave();

      try {
        return Module["dynCall_iii"](index, a1, a2);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_iiii(index, a1, a2, a3) {
      var sp = _stackSave();

      try {
        return Module["dynCall_iiii"](index, a1, a2, a3);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_iiiii(index, a1, a2, a3, a4) {
      var sp = _stackSave();

      try {
        return Module["dynCall_iiiii"](index, a1, a2, a3, a4);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_iiiiii(index, a1, a2, a3, a4, a5) {
      var sp = _stackSave();

      try {
        return Module["dynCall_iiiiii"](index, a1, a2, a3, a4, a5);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_iiiiiii(index, a1, a2, a3, a4, a5, a6) {
      var sp = _stackSave();

      try {
        return Module["dynCall_iiiiiii"](index, a1, a2, a3, a4, a5, a6);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_v(index) {
      var sp = _stackSave();

      try {
        Module["dynCall_v"](index);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_vi(index, a1) {
      var sp = _stackSave();

      try {
        Module["dynCall_vi"](index, a1);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_vii(index, a1, a2) {
      var sp = _stackSave();

      try {
        Module["dynCall_vii"](index, a1, a2);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_viii(index, a1, a2, a3) {
      var sp = _stackSave();

      try {
        Module["dynCall_viii"](index, a1, a2, a3);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_viiii(index, a1, a2, a3, a4) {
      var sp = _stackSave();

      try {
        Module["dynCall_viiii"](index, a1, a2, a3, a4);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_viiiii(index, a1, a2, a3, a4, a5) {
      var sp = _stackSave();

      try {
        Module["dynCall_viiiii"](index, a1, a2, a3, a4, a5);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    function invoke_viiiiii(index, a1, a2, a3, a4, a5, a6) {
      var sp = _stackSave();

      try {
        Module["dynCall_viiiiii"](index, a1, a2, a3, a4, a5, a6);
      } catch (e) {
        _stackRestore(sp);

        if (typeof e !== "number" && e !== "longjmp") throw e;
        Module["setThrew"](1, 0);
      }
    }

    Module.asmGlobalArg = {};
    Module.asmLibraryArg = {
      "abort": abort,
      "assert": assert,
      "enlargeMemory": enlargeMemory,
      "getTotalMemory": getTotalMemory,
      "abortOnCannotGrowMemory": abortOnCannotGrowMemory,
      "abortStackOverflow": abortStackOverflow,
      "nullFunc_i": nullFunc_i,
      "nullFunc_ii": nullFunc_ii,
      "nullFunc_iii": nullFunc_iii,
      "nullFunc_iiii": nullFunc_iiii,
      "nullFunc_iiiii": nullFunc_iiiii,
      "nullFunc_iiiiii": nullFunc_iiiiii,
      "nullFunc_iiiiiii": nullFunc_iiiiiii,
      "nullFunc_v": nullFunc_v,
      "nullFunc_vi": nullFunc_vi,
      "nullFunc_vii": nullFunc_vii,
      "nullFunc_viii": nullFunc_viii,
      "nullFunc_viiii": nullFunc_viiii,
      "nullFunc_viiiii": nullFunc_viiiii,
      "nullFunc_viiiiii": nullFunc_viiiiii,
      "invoke_i": invoke_i,
      "invoke_ii": invoke_ii,
      "invoke_iii": invoke_iii,
      "invoke_iiii": invoke_iiii,
      "invoke_iiiii": invoke_iiiii,
      "invoke_iiiiii": invoke_iiiiii,
      "invoke_iiiiiii": invoke_iiiiiii,
      "invoke_v": invoke_v,
      "invoke_vi": invoke_vi,
      "invoke_vii": invoke_vii,
      "invoke_viii": invoke_viii,
      "invoke_viiii": invoke_viiii,
      "invoke_viiiii": invoke_viiiii,
      "invoke_viiiiii": invoke_viiiiii,
      "_X509_ATTRIBUTE_free": _X509_ATTRIBUTE_free,
      "_X509_SIG_free": _X509_SIG_free,
      "__ZSt18uncaught_exceptionv": __ZSt18uncaught_exceptionv,
      "___buildEnvironment": ___buildEnvironment,
      "___cxa_find_matching_catch": ___cxa_find_matching_catch,
      "___gxx_personality_v0": ___gxx_personality_v0,
      "___lock": ___lock,
      "___resumeException": ___resumeException,
      "___setErrNo": ___setErrNo,
      "___syscall140": ___syscall140,
      "___syscall146": ___syscall146,
      "___syscall54": ___syscall54,
      "___syscall6": ___syscall6,
      "___unlock": ___unlock,
      "__embind_register_bool": __embind_register_bool,
      "__embind_register_emval": __embind_register_emval,
      "__embind_register_float": __embind_register_float,
      "__embind_register_function": __embind_register_function,
      "__embind_register_integer": __embind_register_integer,
      "__embind_register_memory_view": __embind_register_memory_view,
      "__embind_register_std_string": __embind_register_std_string,
      "__embind_register_std_wstring": __embind_register_std_wstring,
      "__embind_register_void": __embind_register_void,
      "__emval_decref": __emval_decref,
      "__emval_register": __emval_register,
      "_abort": _abort,
      "_d2i_X509_SIG": _d2i_X509_SIG,
      "_embind_repr": _embind_repr,
      "_emscripten_get_now": _emscripten_get_now,
      "_emscripten_memcpy_big": _emscripten_memcpy_big,
      "_emscripten_set_main_loop": _emscripten_set_main_loop,
      "_emscripten_set_main_loop_timing": _emscripten_set_main_loop_timing,
      "_i2d_X509_SIG": _i2d_X509_SIG,
      "count_emval_handles": count_emval_handles,
      "craftInvokerFunction": craftInvokerFunction,
      "createNamedFunction": createNamedFunction,
      "embind__requireFunction": embind__requireFunction,
      "embind_init_charCodes": embind_init_charCodes,
      "ensureOverloadTable": ensureOverloadTable,
      "exposePublicSymbol": exposePublicSymbol,
      "extendError": extendError,
      "floatReadValueFromPointer": floatReadValueFromPointer,
      "getShiftFromSize": getShiftFromSize,
      "getTypeName": getTypeName,
      "get_first_emval": get_first_emval,
      "heap32VectorToArray": heap32VectorToArray,
      "init_emval": init_emval,
      "integerReadValueFromPointer": integerReadValueFromPointer,
      "makeLegalFunctionName": makeLegalFunctionName,
      "new_": new_,
      "readLatin1String": readLatin1String,
      "registerType": registerType,
      "replacePublicSymbol": replacePublicSymbol,
      "runDestructors": runDestructors,
      "simpleReadValueFromPointer": simpleReadValueFromPointer,
      "throwBindingError": throwBindingError,
      "throwInternalError": throwInternalError,
      "throwUnboundTypeError": throwUnboundTypeError,
      "whenDependentTypesAreResolved": whenDependentTypesAreResolved,
      "DYNAMICTOP_PTR": DYNAMICTOP_PTR,
      "tempDoublePtr": tempDoublePtr,
      "ABORT": ABORT,
      "STACKTOP": STACKTOP,
      "STACK_MAX": STACK_MAX
    };
    var asm = Module["asm"](Module.asmGlobalArg, Module.asmLibraryArg, buffer);
    var real__AES_Finish = asm["_AES_Finish"];

    asm["_AES_Finish"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real__AES_Finish.apply(null, arguments);
    };

    var real__AES_Init = asm["_AES_Init"];

    asm["_AES_Init"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real__AES_Init.apply(null, arguments);
    };

    var real__AES_Push = asm["_AES_Push"];

    asm["_AES_Push"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real__AES_Push.apply(null, arguments);
    };

    var real___GLOBAL__sub_I_bind_cpp = asm["__GLOBAL__sub_I_bind_cpp"];

    asm["__GLOBAL__sub_I_bind_cpp"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real___GLOBAL__sub_I_bind_cpp.apply(null, arguments);
    };

    var real___GLOBAL__sub_I_worker_cpp = asm["__GLOBAL__sub_I_worker_cpp"];

    asm["__GLOBAL__sub_I_worker_cpp"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real___GLOBAL__sub_I_worker_cpp.apply(null, arguments);
    };

    var real____emscripten_environ_constructor = asm["___emscripten_environ_constructor"];

    asm["___emscripten_environ_constructor"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real____emscripten_environ_constructor.apply(null, arguments);
    };

    var real____errno_location = asm["___errno_location"];

    asm["___errno_location"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real____errno_location.apply(null, arguments);
    };

    var real____getTypeName = asm["___getTypeName"];

    asm["___getTypeName"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real____getTypeName.apply(null, arguments);
    };

    var real___get_daylight = asm["__get_daylight"];

    asm["__get_daylight"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real___get_daylight.apply(null, arguments);
    };

    var real___get_timezone = asm["__get_timezone"];

    asm["__get_timezone"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real___get_timezone.apply(null, arguments);
    };

    var real___get_tzname = asm["__get_tzname"];

    asm["__get_tzname"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real___get_tzname.apply(null, arguments);
    };

    var real__fflush = asm["_fflush"];

    asm["_fflush"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real__fflush.apply(null, arguments);
    };

    var real__free = asm["_free"];

    asm["_free"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real__free.apply(null, arguments);
    };

    var real__llvm_bswap_i32 = asm["_llvm_bswap_i32"];

    asm["_llvm_bswap_i32"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real__llvm_bswap_i32.apply(null, arguments);
    };

    var real__malloc = asm["_malloc"];

    asm["_malloc"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real__malloc.apply(null, arguments);
    };

    var real__pbkdf2SHA512Hash = asm["_pbkdf2SHA512Hash"];

    asm["_pbkdf2SHA512Hash"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real__pbkdf2SHA512Hash.apply(null, arguments);
    };

    var real__sbrk = asm["_sbrk"];

    asm["_sbrk"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real__sbrk.apply(null, arguments);
    };

    var real_establishStackSpace = asm["establishStackSpace"];

    asm["establishStackSpace"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real_establishStackSpace.apply(null, arguments);
    };

    var real_getTempRet0 = asm["getTempRet0"];

    asm["getTempRet0"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real_getTempRet0.apply(null, arguments);
    };

    var real_setTempRet0 = asm["setTempRet0"];

    asm["setTempRet0"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real_setTempRet0.apply(null, arguments);
    };

    var real_setThrew = asm["setThrew"];

    asm["setThrew"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real_setThrew.apply(null, arguments);
    };

    var real_stackAlloc = asm["stackAlloc"];

    asm["stackAlloc"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real_stackAlloc.apply(null, arguments);
    };

    var real_stackRestore = asm["stackRestore"];

    asm["stackRestore"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real_stackRestore.apply(null, arguments);
    };

    var real_stackSave = asm["stackSave"];

    asm["stackSave"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return real_stackSave.apply(null, arguments);
    };

    Module["asm"] = asm;

    var _AES_Finish = Module["_AES_Finish"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_AES_Finish"].apply(null, arguments);
    };

    var _AES_Init = Module["_AES_Init"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_AES_Init"].apply(null, arguments);
    };

    var _AES_Push = Module["_AES_Push"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_AES_Push"].apply(null, arguments);
    };

    var __GLOBAL__sub_I_bind_cpp = Module["__GLOBAL__sub_I_bind_cpp"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["__GLOBAL__sub_I_bind_cpp"].apply(null, arguments);
    };

    var __GLOBAL__sub_I_worker_cpp = Module["__GLOBAL__sub_I_worker_cpp"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["__GLOBAL__sub_I_worker_cpp"].apply(null, arguments);
    };

    var ___emscripten_environ_constructor = Module["___emscripten_environ_constructor"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["___emscripten_environ_constructor"].apply(null, arguments);
    };

    var ___errno_location = Module["___errno_location"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["___errno_location"].apply(null, arguments);
    };

    var ___getTypeName = Module["___getTypeName"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["___getTypeName"].apply(null, arguments);
    };

    var __get_daylight = Module["__get_daylight"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["__get_daylight"].apply(null, arguments);
    };

    var __get_timezone = Module["__get_timezone"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["__get_timezone"].apply(null, arguments);
    };

    var __get_tzname = Module["__get_tzname"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["__get_tzname"].apply(null, arguments);
    };

    var _emscripten_replace_memory = Module["_emscripten_replace_memory"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_emscripten_replace_memory"].apply(null, arguments);
    };

    var _fflush = Module["_fflush"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_fflush"].apply(null, arguments);
    };

    var _free = Module["_free"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_free"].apply(null, arguments);
    };

    var _llvm_bswap_i32 = Module["_llvm_bswap_i32"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_llvm_bswap_i32"].apply(null, arguments);
    };

    var _malloc = Module["_malloc"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_malloc"].apply(null, arguments);
    };

    var _memcpy = Module["_memcpy"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_memcpy"].apply(null, arguments);
    };

    var _memset = Module["_memset"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_memset"].apply(null, arguments);
    };

    var _pbkdf2SHA512Hash = Module["_pbkdf2SHA512Hash"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_pbkdf2SHA512Hash"].apply(null, arguments);
    };

    var _sbrk = Module["_sbrk"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["_sbrk"].apply(null, arguments);
    };

    var establishStackSpace = Module["establishStackSpace"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["establishStackSpace"].apply(null, arguments);
    };

    var getTempRet0 = Module["getTempRet0"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["getTempRet0"].apply(null, arguments);
    };

    var runPostSets = Module["runPostSets"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["runPostSets"].apply(null, arguments);
    };

    var setTempRet0 = Module["setTempRet0"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["setTempRet0"].apply(null, arguments);
    };

    var setThrew = Module["setThrew"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["setThrew"].apply(null, arguments);
    };

    var stackAlloc = Module["stackAlloc"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["stackAlloc"].apply(null, arguments);
    };

    var _stackRestore = Module["stackRestore"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["stackRestore"].apply(null, arguments);
    };

    var _stackSave = Module["stackSave"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["stackSave"].apply(null, arguments);
    };

    var dynCall_i = Module["dynCall_i"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_i"].apply(null, arguments);
    };

    var dynCall_ii = Module["dynCall_ii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_ii"].apply(null, arguments);
    };

    var dynCall_iii = Module["dynCall_iii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_iii"].apply(null, arguments);
    };

    var dynCall_iiii = Module["dynCall_iiii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_iiii"].apply(null, arguments);
    };

    var dynCall_iiiii = Module["dynCall_iiiii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_iiiii"].apply(null, arguments);
    };

    var dynCall_iiiiii = Module["dynCall_iiiiii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_iiiiii"].apply(null, arguments);
    };

    var dynCall_iiiiiii = Module["dynCall_iiiiiii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_iiiiiii"].apply(null, arguments);
    };

    var dynCall_v = Module["dynCall_v"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_v"].apply(null, arguments);
    };

    var dynCall_vi = Module["dynCall_vi"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_vi"].apply(null, arguments);
    };

    var dynCall_vii = Module["dynCall_vii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_vii"].apply(null, arguments);
    };

    var dynCall_viii = Module["dynCall_viii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_viii"].apply(null, arguments);
    };

    var dynCall_viiii = Module["dynCall_viiii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_viiii"].apply(null, arguments);
    };

    var dynCall_viiiii = Module["dynCall_viiiii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_viiiii"].apply(null, arguments);
    };

    var dynCall_viiiiii = Module["dynCall_viiiiii"] = function () {
      assert(runtimeInitialized, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
      assert(!runtimeExited, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
      return Module["asm"]["dynCall_viiiiii"].apply(null, arguments);
    };

    Module["asm"] = asm;
    if (!Module["intArrayFromString"]) Module["intArrayFromString"] = function () {
      abort("'intArrayFromString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["intArrayToString"]) Module["intArrayToString"] = function () {
      abort("'intArrayToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    Module["ccall"] = ccall;
    Module["cwrap"] = cwrap;
    Module["setValue"] = setValue;
    Module["getValue"] = getValue;
    if (!Module["allocate"]) Module["allocate"] = function () {
      abort("'allocate' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    Module["getMemory"] = getMemory;
    if (!Module["Pointer_stringify"]) Module["Pointer_stringify"] = function () {
      abort("'Pointer_stringify' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["AsciiToString"]) Module["AsciiToString"] = function () {
      abort("'AsciiToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["stringToAscii"]) Module["stringToAscii"] = function () {
      abort("'stringToAscii' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["UTF8ArrayToString"]) Module["UTF8ArrayToString"] = function () {
      abort("'UTF8ArrayToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["UTF8ToString"]) Module["UTF8ToString"] = function () {
      abort("'UTF8ToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["stringToUTF8Array"]) Module["stringToUTF8Array"] = function () {
      abort("'stringToUTF8Array' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["stringToUTF8"]) Module["stringToUTF8"] = function () {
      abort("'stringToUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["lengthBytesUTF8"]) Module["lengthBytesUTF8"] = function () {
      abort("'lengthBytesUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["UTF16ToString"]) Module["UTF16ToString"] = function () {
      abort("'UTF16ToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["stringToUTF16"]) Module["stringToUTF16"] = function () {
      abort("'stringToUTF16' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["lengthBytesUTF16"]) Module["lengthBytesUTF16"] = function () {
      abort("'lengthBytesUTF16' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["UTF32ToString"]) Module["UTF32ToString"] = function () {
      abort("'UTF32ToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["stringToUTF32"]) Module["stringToUTF32"] = function () {
      abort("'stringToUTF32' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["lengthBytesUTF32"]) Module["lengthBytesUTF32"] = function () {
      abort("'lengthBytesUTF32' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["allocateUTF8"]) Module["allocateUTF8"] = function () {
      abort("'allocateUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["stackTrace"]) Module["stackTrace"] = function () {
      abort("'stackTrace' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["addOnPreRun"]) Module["addOnPreRun"] = function () {
      abort("'addOnPreRun' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["addOnInit"]) Module["addOnInit"] = function () {
      abort("'addOnInit' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["addOnPreMain"]) Module["addOnPreMain"] = function () {
      abort("'addOnPreMain' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["addOnExit"]) Module["addOnExit"] = function () {
      abort("'addOnExit' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["addOnPostRun"]) Module["addOnPostRun"] = function () {
      abort("'addOnPostRun' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["writeStringToMemory"]) Module["writeStringToMemory"] = function () {
      abort("'writeStringToMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["writeArrayToMemory"]) Module["writeArrayToMemory"] = function () {
      abort("'writeArrayToMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["writeAsciiToMemory"]) Module["writeAsciiToMemory"] = function () {
      abort("'writeAsciiToMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    Module["addRunDependency"] = addRunDependency;
    Module["removeRunDependency"] = removeRunDependency;
    if (!Module["ENV"]) Module["ENV"] = function () {
      abort("'ENV' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["FS"]) Module["FS"] = function () {
      abort("'FS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    Module["FS_createFolder"] = FS.createFolder;
    Module["FS_createPath"] = FS.createPath;
    Module["FS_createDataFile"] = FS.createDataFile;
    Module["FS_createPreloadedFile"] = FS.createPreloadedFile;
    Module["FS_createLazyFile"] = FS.createLazyFile;
    Module["FS_createLink"] = FS.createLink;
    Module["FS_createDevice"] = FS.createDevice;
    Module["FS_unlink"] = FS.unlink;
    if (!Module["GL"]) Module["GL"] = function () {
      abort("'GL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["staticAlloc"]) Module["staticAlloc"] = function () {
      abort("'staticAlloc' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["dynamicAlloc"]) Module["dynamicAlloc"] = function () {
      abort("'dynamicAlloc' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["warnOnce"]) Module["warnOnce"] = function () {
      abort("'warnOnce' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["loadDynamicLibrary"]) Module["loadDynamicLibrary"] = function () {
      abort("'loadDynamicLibrary' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["loadWebAssemblyModule"]) Module["loadWebAssemblyModule"] = function () {
      abort("'loadWebAssemblyModule' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["getLEB"]) Module["getLEB"] = function () {
      abort("'getLEB' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["getFunctionTables"]) Module["getFunctionTables"] = function () {
      abort("'getFunctionTables' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["alignFunctionTables"]) Module["alignFunctionTables"] = function () {
      abort("'alignFunctionTables' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["registerFunctions"]) Module["registerFunctions"] = function () {
      abort("'registerFunctions' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["addFunction"]) Module["addFunction"] = function () {
      abort("'addFunction' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["removeFunction"]) Module["removeFunction"] = function () {
      abort("'removeFunction' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["getFuncWrapper"]) Module["getFuncWrapper"] = function () {
      abort("'getFuncWrapper' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["prettyPrint"]) Module["prettyPrint"] = function () {
      abort("'prettyPrint' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["makeBigInt"]) Module["makeBigInt"] = function () {
      abort("'makeBigInt' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["dynCall"]) Module["dynCall"] = function () {
      abort("'dynCall' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["getCompilerSetting"]) Module["getCompilerSetting"] = function () {
      abort("'getCompilerSetting' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["stackSave"]) Module["stackSave"] = function () {
      abort("'stackSave' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["stackRestore"]) Module["stackRestore"] = function () {
      abort("'stackRestore' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["stackAlloc"]) Module["stackAlloc"] = function () {
      abort("'stackAlloc' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["establishStackSpace"]) Module["establishStackSpace"] = function () {
      abort("'establishStackSpace' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["print"]) Module["print"] = function () {
      abort("'print' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["printErr"]) Module["printErr"] = function () {
      abort("'printErr' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
    };
    if (!Module["ALLOC_NORMAL"]) Object.defineProperty(Module, "ALLOC_NORMAL", {
      get: function get() {
        abort("'ALLOC_NORMAL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
      }
    });
    if (!Module["ALLOC_STACK"]) Object.defineProperty(Module, "ALLOC_STACK", {
      get: function get() {
        abort("'ALLOC_STACK' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
      }
    });
    if (!Module["ALLOC_STATIC"]) Object.defineProperty(Module, "ALLOC_STATIC", {
      get: function get() {
        abort("'ALLOC_STATIC' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
      }
    });
    if (!Module["ALLOC_DYNAMIC"]) Object.defineProperty(Module, "ALLOC_DYNAMIC", {
      get: function get() {
        abort("'ALLOC_DYNAMIC' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
      }
    });
    if (!Module["ALLOC_NONE"]) Object.defineProperty(Module, "ALLOC_NONE", {
      get: function get() {
        abort("'ALLOC_NONE' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
      }
    });

    Module["then"] = function (func) {
      if (Module["calledRun"]) {
        func(Module);
      } else {
        var old = Module["onRuntimeInitialized"];

        Module["onRuntimeInitialized"] = function () {
          if (old) old();
          func(Module);
        };
      }

      return Module;
    };

    function ExitStatus(status) {
      this.name = "ExitStatus";
      this.message = "Program terminated with exit(" + status + ")";
      this.status = status;
    }

    ExitStatus.prototype = new Error();
    ExitStatus.prototype.constructor = ExitStatus;
    var initialStackTop;

    dependenciesFulfilled = function runCaller() {
      if (!Module["calledRun"]) run();
      if (!Module["calledRun"]) dependenciesFulfilled = runCaller;
    };

    function run(args) {
      args = args || Module["arguments"];

      if (runDependencies > 0) {
        return;
      }

      writeStackCookie();
      preRun();
      if (runDependencies > 0) return;
      if (Module["calledRun"]) return;

      function doRun() {
        if (Module["calledRun"]) return;
        Module["calledRun"] = true;
        if (ABORT) return;
        ensureInitRuntime();
        preMain();
        if (Module["onRuntimeInitialized"]) Module["onRuntimeInitialized"]();
        assert(!Module["_main"], 'compiled without a main, but one is present. if you added it from JS, use Module["onRuntimeInitialized"]');
        postRun();
      }

      if (Module["setStatus"]) {
        Module["setStatus"]("Running...");
        setTimeout(function () {
          setTimeout(function () {
            Module["setStatus"]("");
          }, 1);
          doRun();
        }, 1);
      } else {
        doRun();
      }

      checkStackCookie();
    }

    Module["run"] = run;

    function checkUnflushedContent() {
      var print = out;
      var printErr = err;
      var has = false;

      out = err = function err(x) {
        has = true;
      };

      try {
        var flush = Module["_fflush"];
        if (flush) flush(0);
        var hasFS = true;

        if (hasFS) {
          ["stdout", "stderr"].forEach(function (name) {
            var info = FS.analyzePath("/dev/" + name);
            if (!info) return;
            var stream = info.object;
            var rdev = stream.rdev;
            var tty = TTY.ttys[rdev];

            if (tty && tty.output && tty.output.length) {
              has = true;
            }
          });
        }
      } catch (e) {}

      out = print;
      err = printErr;

      if (has) {
        warnOnce("stdio streams had content in them that was not flushed. you should set NO_EXIT_RUNTIME to 0 (see the FAQ), or make sure to emit a newline when you printf etc.");
      }
    }

    var abortDecorators = [];

    function abort(what) {
      if (Module["onAbort"]) {
        Module["onAbort"](what);
      }

      if (what !== undefined) {
        out(what);
        err(what);
        what = JSON.stringify(what);
      } else {
        what = "";
      }

      ABORT = true;
      EXITSTATUS = 1;
      var extra = "";
      var output = "abort(" + what + ") at " + stackTrace() + extra;

      if (abortDecorators) {
        abortDecorators.forEach(function (decorator) {
          output = decorator(output, what);
        });
      }

      throw output;
    }

    Module["abort"] = abort;

    if (Module["preInit"]) {
      if (typeof Module["preInit"] == "function") Module["preInit"] = [Module["preInit"]];

      while (Module["preInit"].length > 0) {
        Module["preInit"].pop()();
      }
    }

    Module["noExitRuntime"] = true;
    run();
    return Module;
  };
}();

if (typeof exports === 'object' && typeof module === 'object') module.exports = Module;else if (typeof define === 'function' && define['amd']) define([], function () {
  return Module;
});else if (typeof exports === 'object') exports["Module"] = Module;
/*** EXPORTS FROM exports-loader ***/