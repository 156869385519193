export const fileConstants = {
  DOWNLOAD_IMAGE_REQUEST: 'IMAGE_DOWNLOAD_REQUEST',
  DOWNLOAD_IMAGE_SUCCESS: 'IMAGE_DOWNLOAD_SUCCESS',
  DOWNLOAD_IMAGE_FAILURE: 'IMAGE_DOWNLOAD_FAILURE',

  DOWNLOAD_REQUEST: 'FILE_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'FILE_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'FILE_DOWNLOAD_FAILURE',

  GET_FILESTATS_REQUEST: 'GET_FILESTATS_REQUEST',
  GET_FILESTATS_SUCCESS: 'GET_FILESTATS_SUCCESS',
  GET_FILESTATS_FAILURE: 'GET_FILESTATS_FAILURE',

  DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',  
  
  DELETE_FILES_REQUEST: 'DELETE_FILES_REQUEST',
  DELETE_FILES_SUCCESS: 'DELETE_FILES_SUCCESS',
  DELETE_FILES_FAILURE: 'DELETE_FILES_FAILURE',

  DELETE_FILES_REQUEST: 'DELETE_FILES_REQUEST',
  DELETE_FILES_SUCCESS: 'DELETE_FILES_SUCCESS',
  DELETE_FILES_FAILURE: 'DELETE_FILES_FAILURE',

  UPDATE_FILE_REQUEST: 'UPDATE_FILE_REQUEST',
  UPDATE_FILE_SUCCESS: 'UPDATE_FILE_SUCCESS',
  UPDATE_FILE_FAILURE: 'UPDATE_FILE_FAILURE',

  UPDATE_FILES_SUCCESS: 'UPDATE_FILES_SUCCESS',

  RENAME_FOLDER_REQUEST: 'RENAME_FOLDER_REQUEST',
  RENAME_FOLDER_SUCCESS: 'RENAME_FOLDER_SUCCESS',
  RENAME_FOLDER_FAILURE: 'RENAME_FOLDER_FAILURE',

  CHECK_DOCUMENT_EXISTS_REQUEST: 'CHECK_DOCUMENT_EXISTS_REQUEST',
  CHECK_DOCUMENT_EXISTS_SUCCESS: 'CHECK_DOCUMENT_EXISTS_SUCCESS',
  CHECK_DOCUMENT_EXISTS_FAILURE: 'CHECK_DOCUMENT_EXISTS_FAILURE',

  //SET_FILE_ERROR_REQUEST: 'SET_FILE_ERROR_REQUEST',

  //UPDATE_STATS_FILE_REQUEST: 'UPDATE_STATS_FILE_REQUEST',

  //COMPLETE_UPLOAD_FILE_REQUEST: 'COMPLETE_UPLOAD_FILE_REQUEST',
  COMPLETE_DOWNLOAD_FILE_REQUEST: 'COMPLETE_DOWNLOAD_FILE_REQUEST',

  WORKER_FILE_UPDATE_REQUEST: 'WORKER_FILE_UPDATE_REQUEST',
  WORKER_FILE_UPDATE_SUCCESS: 'WORKER_FILE_UPDATE_SUCCESS',
  WORKER_FILE_UPDATE_FAILURE: 'WORKER_FILE_UPDATE_FAILURE',
};
