export const marketingReducerConstants = {
    POPULATE_MESSAGES: 'POPULATE_MESSAGES',
    SHOW_MARKETING: 'SHOW_MARKETING',
    SET_LOADING: 'SET_LOADING'
}

var initialState = {
    loading: false,
    marketingMessages: [],
    showMarketing: false,
}

export function marketingMessages(state = initialState, action) {
    switch (action.type) {
        case marketingReducerConstants.SET_LOADING:
            return { ...state, loading: action.payload || false };
        case marketingReducerConstants.SHOW_MARKETING:
            return { ...state, showMarketing: action.payload };
        case marketingReducerConstants.POPULATE_MESSAGES:
            return { ...state, marketingMessages: action.payload};
        default:
            return state;
    }
}