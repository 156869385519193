export const bulkUploadConstants = {
    ADD_BOARDS: 'ADD_BOARDS',
    UPDATE_BOARD: 'UPDATE_BOARD',
    REMOVE_BOARDS: 'REMOVE_BOARDS',
    CLEAR_BOARDS: 'CLEAR_BOARDS',

    ADD_USERS: 'ADD_USERS',
    UPDATE_USER: 'UPDATE_USER',
    REMOVE_USERS: 'REMOVE_USERS',
    CLEAR_USERS: 'CLEAR_USERS',

    ADD_BINDERS: 'ADD_BINDERS',
    ADD_BINDER: 'ADD_BINDER',
    UPDATE_BINDER: 'UPDATE_BINDER',
    UPDATE_SAVED_BINDER_ID: 'UPDATE_SAVED_BINDER_ID',
    UPDATE_BINDER_ITEM: 'UPDATE_BINDER_ITEM',
    REMOVE_BINDER_ITEM: 'REMOVE_BINDER_ITEM',
    REMOVE_BINDERS: 'REMOVE_BINDERS',
    CLEAR_BINDERS: 'CLEAR_BINDERS',

    CLEAR_STATE: 'CLEAR_STATE',
};