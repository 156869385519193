
//item : {id: '', el: <Popper>/<Popover>/<Dialog>}

import { popoverConstants } from "../constants/admin";

var init = { 
    popovers: [],
    toasts: [],
}
export function popovers(state = init, action) {
    switch (action.type) {
        case popoverConstants.ADD_TOAST:
            return {...state, toasts: [...(state.toasts.filter((p) => p.id !== action.payload.id)), action.payload]};
        case popoverConstants.REMOVE_TOAST:
            return {...state, toasts: state.toasts.filter((p) => p.id !== action.payload)};
        case popoverConstants.ADD_POPOVER:
            return {...state, popovers: [...(state.popovers.filter((p) => p.id !== action.payload.id)), action.payload]};
        case popoverConstants.REMOVE_POPOVER:
            return {...state, popovers: state.popovers.filter((p) => p.id !== action.payload)};
            
        case popoverConstants.CLEAR_ALL:
            return {
                popovers: [],
                toasts: []
            };
        default:
            return state;
    }
}