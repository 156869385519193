export const alertConstants = {
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',

    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',

    SUCCESS_NOTIFICATION: 'ALERT_SUCCESS_NOTIFICATION',
    CLEAR_BYID_NOTIFICATION: 'CLEAR_BYID_NOTIFICATION',

    ALERT_DANGER: 'alert-danger',
    ALERT_SUCCESS: 'alert-success'
};
